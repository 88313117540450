<script>
  import { QuickshareAccessLink } from "apps/quickshare/components";
  import { LinkType } from "apps/quickshare/constants/index.js";

  import { t } from "stores/i18n";
  import { get } from "svelte/store";

  import {
    TextArea,
    TextInput,
    Dropdown,
    Boundary,
    showSelected,
  } from "components";
  import {
    QuickshareLinkEditFormPassword,
    QuickshareLinkEmailField,
    QuickshareLinkTwoWayField,
    QuickshareLinkUnlimDownloadsField,
  } from "./components";

  import styles from "styleguide/QuickshareLinkEditForm.json";

  export let isTwoColumns = false;
  export let isRegenerateLink = false;
  export let link;
  export let onChange = () => {};

  export let isSubmittedExternal = false;
  export let onChangeValidators = () => {};

  let { personal_inbox, link_type } = link || {};

  $: expireDates = {
    1: $t("quickshare_link_edit.1_days"),
    3: $t("quickshare_link_edit.3_days"),
    7: $t("quickshare_link_edit.7_days"),
    14: $t("quickshare_link_edit.14_days"),
    30: $t("quickshare_link_edit.30_days"),
  };

  $: titleIsOptional = !link_type === LinkType.SHARE;

  $: initialLinkExpire = $t(
    `quickshare_link_edit.${link.expires_in || 7}_days`,
  );

  $: isNotRequestLink = link_type !== LinkType.REQUEST;

  $: validationErrors = getValidation();

  function handleTwoWayToggle(value) {
    link.link_type = value ? LinkType.TWO_WAY : LinkType.SHARE;
    onPropertyUpdate();
  }

  function handleChangeField(key, value) {
    link[key] = value;
    onPropertyUpdate();
  }

  function onPropertyUpdate() {
    onChange(link);
    validationErrors = getValidation();
  }

  function toggleResetPassword(value) {
    link.reset_password = value;
  }

  function getValidation() {
    const errors = {};

    if (!link.title) {
      errors.title = get(t)("quickshare_link_edit.enter_title");
    }

    onChangeValidators(errors);
    return errors;
  }
</script>

<Boundary>
  <div data-component="QuickshareLinkEditForm">
    <div class={styles[isTwoColumns ? "row" : "column"]}>
      <div class={styles.content}>
        {#if isRegenerateLink}
          <QuickshareAccessLink
            bind:link
            canRegenerateLink={!personal_inbox}
            canCopyInput={false}
          />
        {/if}

        {#if !personal_inbox}
          <TextInput
            name="title"
            label={$t("quickshare_link_edit.title")}
            bind:value={link.title}
            optional={titleIsOptional}
            onChange={onPropertyUpdate}
            errors={isSubmittedExternal && validationErrors?.title
              ? [validationErrors.title]
              : []}
          />
        {/if}
        <TextArea
          name="message"
          label={$t("quickshare_link_edit.note")}
          optional={true}
          bind:value={link.message}
          withCounter={true}
          maxLength={150}
          onChange={onPropertyUpdate}
        />
      </div>

      <div class={styles.content}>
        {#if isNotRequestLink || personal_inbox}
          <QuickshareLinkEditFormPassword
            bind:value={link.password}
            onChange={(value) => handleChangeField("password", value)}
            isInitPassword={link.has_password && !link.reset_password}
            onToggleReset={toggleResetPassword}
          />
        {/if}

        {#if !personal_inbox}
          <Dropdown
            label={$t("quickshare_link_edit.expires_after")}
            items={expireDates}
            bind:value={link.expires_in}
            initial={initialLinkExpire}
            click={(value) => handleChangeField("expires_in", value)}
            onChange={onPropertyUpdate}
            showSelected={true}
          />
        {/if}

        <div>
          {#if isNotRequestLink && !personal_inbox}
            <QuickshareLinkUnlimDownloadsField
              key="download_limit"
              value={!link.download_limit}
              {handleChangeField}
            />

            <QuickshareLinkTwoWayField
              linkType={link.link_type}
              {handleTwoWayToggle}
            />
          {/if}

          <QuickshareLinkEmailField
            value={link.email_notification}
            key="email_notification"
            {handleChangeField}
          />
        </div>
      </div>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .row {
    display: grid;
    grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    grid-gap: 24px;
  }

  .column {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: fit-content;
    width: 100%;
  }
</style>
