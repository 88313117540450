<script>
  import { beforeUpdate } from "svelte";
  import styles from "styleguide/Tabs.json";

  export let tabs;
  export let selectedTabIdInitial;
  export let onChange = () => {};
  export let isControlsVisible = true;

  let contentContainer;
  $: selectedTabId = selectedTabIdInitial;

  $: transformWidth = 0;
  $: selectedTabIdIdx = 0;

  beforeUpdate(() => {
    if (!contentContainer) return;

    calculateTabs();
  });

  function handleChange(tabId) {
    selectedTabId = tabId;
    calculateTabs();

    onChange(tabId);
  }

  function calculateTabs() {
    const { width } = contentContainer.getBoundingClientRect();
    selectedTabIdIdx = tabs.findIndex((tab) => tab.id == selectedTabId);

    transformWidth = (width / tabs.length) * selectedTabIdIdx;
  }
</script>

<div data-component="Tabs">
  {#if isControlsVisible}
    <div class={styles.container}>
      <div class={styles.tabs} bind:this={contentContainer}>
        <div
          class={styles.slider}
          style={`transform: translateX(${transformWidth}px); width: calc(100% / ${tabs.length})`}
        ></div>
        {#each tabs as tab, index}
          <div
            class={`${styles.tab} ${index === selectedTabIdIdx ? styles.active : ""}`}
            on:click={() => handleChange(tab.id)}
          >
            {tab.label}
          </div>
        {/each}
      </div>
    </div>
  {/if}

  <svelte:component
    this={tabs[selectedTabIdIdx].component}
    {...tabs[selectedTabIdIdx].props}
  />
</div>

<style lang="scss">
  $vertical-padding: 2px;

  .container {
    padding: $vertical-padding 3px;
    background: rgba(236, 239, 243, 0.6);
    border-radius: 8px;
    margin-bottom: 24px;
    height: 40px;
    overflow: hidden;
  }

  .tabs {
    overflow: visible;
    position: relative;
    display: flex;
    align-items: center;
    border-radius: 8px;
    height: 100%;
  }

  .slider {
    position: absolute;
    top: $vertical-padding;
    left: 0;
    height: calc(100% - ($vertical-padding * 2));
    background-color: var(--white);
    border-radius: 7px;
    border: 0.5px solid rgba(0, 0, 0, 0.04);
    box-shadow:
      0px 3px 1px 0px rgba(0, 0, 0, 0.04),
      0px 3px 8px 0px rgba(0, 0, 0, 0.12);
    transition: transform 0.3s;
    overflow: visible;
  }

  .tab {
    width: calc(100%);
    color: var(--blue-200);
    text-align: center;
    font-weight: 500;
    cursor: pointer;
    z-index: 2;

    &.active {
      color: var(--primary-080);
    }
  }
</style>
