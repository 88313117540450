<script>
  import styles from "styleguide/QuickshareGroupSkeleton.json";

  export let repeatCount = 1;

  import { Skeleton } from "components";
</script>

<div data-component="QuickshareGroupSkeleton" class={styles.container}>
  {#each { length: repeatCount } as _}
    <div class={styles.block}>
      <div class={styles.title}>
        <Skeleton loading={true} />
      </div>
      <div class={styles.actions}>
        <Skeleton loading={true} />
      </div>
      <div class={styles.content}>
        <Skeleton loading={true} />
      </div>
    </div>
  {/each}
</div>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .block {
    position: relative;
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    background: var(--white);
    width: 100%;
    height: 265px;
    overflow: hidden;
  }

  .title {
    position: relative;
    width: 100%;
    height: 90px;
  }

  .actions {
    position: relative;
    border-bottom: 1px solid var(--primary-050);
    width: 100%;
    height: 50px;
  }

  .content {
    position: relative;
    width: 100%;
    height: 125px;
  }
</style>
