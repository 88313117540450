<script>
  import { onMount } from "svelte";

  import { QuickshareLinkEditForm } from "apps/quickshare/components";

  import { QuickshareLinkCreateStatus } from "apps/quickshare/components/QuickshareLinkCreateStatus";
  import { quickshareService } from "apps/quickshare/services";

  import {
    sidebarActiveLinkType,
    isSidebarScrollable,
  } from "apps/quickshare/stores";
  import { t } from "stores/i18n";

  import { copyToClickboard } from "lib/helpers.js";

  import { Boundary, Button } from "components";

  import styles from "styleguide/QuickshareUniqueLinkCreate.json";

  export let link;

  let isSubmitted = false;
  $: validationErrors = {};

  onMount(() => {
    isSidebarScrollable.set(true);
  });

  function onChangeValidators(errors) {
    validationErrors = { ...errors };
  }

  function onSaveLink() {
    isSubmitted = true;

    if (Object.keys(validationErrors).length) return;

    const params = { link };

    quickshareService.updateLinkDraft({
      params,
      onSuccess: () => {
        handleCopy(link.permalink);
        quickshareService.resetListFilters();
        quickshareService.getLinksList();
        sidebarActiveLinkType.set(null);
        window.scrollTo(0, 0);
      },
    });
  }

  function handleCopy(permalink) {
    const linkHref = window.location.origin + "/quickshare/" + permalink;
    const message = $t("quickshare_send_files.created_and_copied");
    copyToClickboard(linkHref, null, message);
  }

  function onClose() {
    sidebarActiveLinkType.set(null);
  }
</script>

<Boundary>
  <div
    data-component="QuickshareUniqueLinkCreate"
    class={`${styles.container} ${$isSidebarScrollable ? styles.scrollable : ""}`}
  >
    <div class={styles.scroll}>
      <div class={styles.info}>
        <div>
          <Button style={"primary-text"} click={onClose}>
            {$t("quickshare_unique_link.cancel")}
          </Button>
        </div>

        <QuickshareLinkEditForm
          {link}
          {onChangeValidators}
          isSubmittedExternal={isSubmitted}
        />
      </div>
    </div>

    <QuickshareLinkCreateStatus
      title={$t("quickshare_unique_link.success_title")}
      info={$t("quickshare_unique_link.success_info")}
      onAction={onSaveLink}
    />
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    position: relative;

    &.scrollable {
      max-height: calc(100dvh - 260px);
    }
  }

  .scroll {
    display: flex;
    margin: -20px -20px 0;
    padding: 20px 20px 0;
    overflow: auto;
  }

  .info {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 20px;
  }
</style>
