<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M4.96806 1.9542C2.07389 3.18534 0.725463 6.52668 1.95539 9.4207C3.18426 12.3138 6.52652 13.6626 9.42076 12.4337C9.87105 12.2423 10.2839 11.9998 10.6555 11.7158L15.2198 16.2809L15.3039 16.3536C15.5975 16.5714 16.0141 16.5473 16.2804 16.281C16.5733 15.9882 16.5734 15.5133 16.2805 15.2204L11.7159 10.6549C12.9192 9.08003 13.2646 6.92112 12.4338 4.9678C11.234 2.14469 8.02367 0.791738 5.18091 1.8687L4.96806 1.9542ZM10.1949 10.1245C11.341 8.95013 11.7354 7.15828 11.0534 5.5547C10.1475 3.42312 7.68592 2.42946 5.55486 3.33466L5.36422 3.42133C3.36462 4.39034 2.45742 6.76695 3.33595 8.83413C4.24097 10.9648 6.70261 11.9582 8.83429 11.0531C9.32886 10.8429 9.76217 10.5489 10.1247 10.1947C10.1356 10.1823 10.147 10.1701 10.1589 10.1583C10.1706 10.1465 10.1826 10.1353 10.1949 10.1245Z"
    {fill}
  />
</svg>
