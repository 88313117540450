<script>
  import { t } from "stores/i18n";

  import { Boundary, Button, Checkbox, Popover } from "components";

  export let value;
  export let key;
  export let handleChangeField;
</script>

<Boundary>
  <div data-component="QuickshareLinkUnlimDownloadsField">
    <Checkbox
      label={$t("quickshare_unlim_download.checkbox_label")}
      toggled={value}
      toggle={(val) => {
        handleChangeField(key, !val);
      }}
    >
      <Popover position="top" showCloseButton={false}>
        <Button icon={"info"} style={"small"} />
        <div slot="content">
          {$t("quickshare_unlim_download.description")}
        </div>
      </Popover>
    </Checkbox>
  </div>
</Boundary>
