<script>
  import { userInfo } from "apps/quickshare/stores";
  import { t } from "stores/i18n";

  import { Toast, ProgressBar, Boundary } from "components";

  import styles from "styleguide/QuickshareSidebarStorageInfo.json";

  const storage = $userInfo?.setting.storage;
  const currentSize = getRoundSize(storage?.usedInMb / 1000);
  const availableSize = getRoundSize(storage?.limitInMb / 1000);
  const isWarning = currentSize / availableSize > 0.8;

  function getProgress() {
    return (currentSize * 100) / availableSize;
  }

  function getRoundSize(number) {
    return Math.round(number * 100) / 100;
  }
</script>

<Boundary>
  <div data-component="QuickshareSidebarStorageInfo" styles={styles.wrapper}>
    {#if isWarning}
      <Toast
        close={false}
        title={$t("quickshare_storage_info.title")}
        message={$t("quickshare_storage_info.message")}
        type={"warning"}
      />
    {/if}

    <div class={styles.progress}>
      <ProgressBar
        style={"small info"}
        startPercent={getProgress()}
        label={$t("quickshare_storage_info.progress_info", {
          currentSize,
          availableSize,
        })}
      />
    </div>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    padding: 20px;
  }

  .progress {
    width: 150px;
  }
</style>
