<script>
  import SearchIcon from "components/icons/SearchIcon.svelte";

  import { Boundary, Button, TextInput } from "components";

  import styles from "styleguide/QuickshareSearchBarField.json";

  export let value;
  export let placeholder;
  export let onEnter;

  function handleClean() {
    value = "";
    onEnter?.();
  }

  function handleEnter() {
    onEnter?.();
  }
</script>

<Boundary>
  <div data-component="QuickshareSearchBarField" class={styles.wrapper}>
    <SearchIcon width={18} fill="var(--blue-100)" />

    <form on:submit|preventDefault={handleEnter} class={styles.form}>
      <TextInput bind:value {placeholder} style={"small no-border"} />
    </form>

    {#if value?.length}
      <Button
        click={handleClean}
        icon="grey_close"
        style={"small icon icon-only hover grey"}
      />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--white);
    padding: 6px 6px 6px 16px;
    border-radius: var(--border-radius);
  }

  .form {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
  }

  .search-button {
    display: inline-block;
    padding: 2px 8px;

    img {
      display: flex;
    }
  }

  .clean-button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-060);
    border-radius: 4px;
    padding: 2px;
    width: 20px;
    height: 20px;

    &:hover {
      background: var(--light-grey);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }
</style>
