export const getIsLinkExpired = (expiredDate) => {
  if (!expiredDate) {
    return false;
  }

  const currentDate = new Date();
  const givenDate = new Date(expiredDate);

  return givenDate < currentDate;
};
