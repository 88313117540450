<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  {fill}
  viewBox="0 0 18 18"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    d="M7.5,13 C7.244,13 6.988,12.902 6.793,12.707 L3.793,9.707 C3.402,9.316 3.402,8.684 3.793,8.293 C4.184,7.902 4.816,7.902 5.207,8.293 L7.5,10.586 L12.793,5.293 C13.184,4.902 13.816,4.902 14.207,5.293 C14.598,5.684 14.598,6.316 14.207,6.707 L8.207,12.707 C8.012,12.902 7.756,13 7.5,13"
  />
</svg>
