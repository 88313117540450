import { quickshareFrontendApi } from "apps/quickshareFrontend/apis";
import { link, linkPassword } from "apps/quickshareFrontend/stores";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

class QuickshareFrontendService {
  constructor() {}

  checkLink({ onSuccess, onError, payload } = {}) {
    const { permalink } = get(link);

    let params = {
      link_id: permalink,
      password: payload,
    };

    quickshareFrontendApi.checkLink({
      params: params,
      success: (res) => {
        linkPassword.set(payload);
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  getFiles({ onSuccess, onError } = {}) {
    const { emailVerified, sessionId, permalink } = get(link);

    let params = {
      link_id: permalink,
      // Add session id to request for the verification complete step
      ...(emailVerified && sessionId
        ? {
            sessionId,
          }
        : {
            password: get(linkPassword),
          }),
    };

    quickshareFrontendApi.getFiles({
      params: params,
      success: (res) => {
        link.update((prev) => ({
          ...prev,
          files: res.data.items,
        }));

        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  downloadFiles({ onSuccess, onError, params } = {}) {
    const { permalink, emailVerified, sessionId } = get(link);
    const { excludeSuspicious } = params;

    quickshareFrontendApi.downloadFiles({
      params: {
        excludeSuspicious,
        linkId: permalink,
        ...(emailVerified && sessionId
          ? {
              sessionId,
            }
          : {
              password: get(linkPassword),
            }),
      },
      success: (res) => {
        toasts.send({
          message: get(t)("quickshare_frontend_service.download_completed"),
          type: "success",
        });

        link.update((prev) => ({
          ...prev,
          isDownloaded: true,
        }));

        onSuccess?.(res);
      },
      error: (err) => {
        if (err.code === "download_limit_reached") {
          toasts.send({
            message: get(t)(
              "quickshare_frontend_service.download_limit_reached",
            ),
            type: "error",
          });
          link.update((prev) => ({
            ...prev,
            isDownloaded: true,
          }));
        } else {
          toasts.send({
            message: get(t)("errors.unknown"),
            type: "error",
          });
        }

        onError?.(err);
      },
    });
  }

  downloadSingleFile({ params, onSuccess, onError } = {}) {
    const { fileId } = params || {};
    const { permalink, emailVerified, sessionId } = get(link);

    quickshareFrontendApi.downloadSingleFile({
      params: {
        fileId,
        linkId: permalink,
        ...(emailVerified && sessionId
          ? {
              sessionId,
            }
          : {
              password: get(linkPassword),
            }),
      },
      success: (res) => {
        if (res?.code === "success") {
          window.open(res.data.item.url, "_blank");
        }
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  confirmFilesAttachment({ onSuccess, onError, params } = {}) {
    const { email } = params || {};
    const { permalink, sessionId } = get(link);

    quickshareFrontendApi.confirmFilesAttachment({
      params: {
        email,
        link_id: permalink,
        session_id: sessionId,
        password: get(linkPassword),
      },
      success: (res) => {
        toasts.send({
          message: get(t)("quickshare_frontend_service.email_sent"),
          type: "success",
        });
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!QuickshareFrontendService.instance) {
      QuickshareFrontendService.instance = new QuickshareFrontendService();
    }
    return QuickshareFrontendService.instance;
  }
}

export const quickshareFrontendService =
  QuickshareFrontendService.getInstance();
