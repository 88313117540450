import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";

import { quickshareApi } from "apps/quickshare/apis";

import { uploadingFiles } from "apps/quickshare/stores";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

class QuickshareUploadService {
  constructor() {
    this.onFirstFileAdded = () => {};
    this.linkPermalink = "";
    this.prevFilesCount = 0;
  }

  setLinkPermalink(permalink) {
    this.linkPermalink = permalink;
  }

  resetFiles() {
    uploadingFiles.set([]);
    this.removeAllUppyFiles();
    this.linkPermalink = "";
  }

  uploadFilesToCache(files) {
    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: "file input",
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (error) {
        if (error.isRestriction) {
          toasts.send({
            message: error.message,
            type: "error",
          });
        } else {
          toasts.send({
            message: get(t)("errors.unknown"),
            type: "error",
          });
        }
      }
    });
  }

  setupUppy(link, callback) {
    this.onFirstFileAdded = callback;
    this.linkPermalink = link.permalink;

    this.uppy = this.initializeUppy();

    this.setAws();
    this.initializeUppyEvents();
  }

  setAws() {
    this.uppy.use(AwsS3, {
      limit: 5,
      timeout: 30 * 1000,
      companionUrl: "/minio",
    });
  }

  initializeUppy() {
    return new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 2000 * 1024 * 1024, // 2000 MB
        maxNumberOfFiles: 500,
        minNumberOfFiles: 1,
      },
    });
  }

  initializeUppyEvents() {
    this.uppy.on("file-added", (file) => {
      if (!this.prevFilesCount) {
        this.onFirstFileAdded(file.name);
      }
      this.prevFilesCount = this.uppy.getFiles();
      this.setUploadingFiles();
    });

    this.uppy.on("upload-progress", () => {
      this.setUploadingFiles();
    });

    this.uppy.on("upload-success", () => {
      this.setUploadingFiles();
    });

    this.uppy.on("complete", () => {
      this.setUploadingFiles();

      // Interval is used because here could be a bug
      // if link creation request was not completed and
      // this.linkPermalink is still empty.
      // It's impossible to track both:
      // upload event fire and link creation request completion.
      const interval = setInterval(() => {
        if (this.linkPermalink) {
          void this.addLinkFiles();
          clearInterval(interval);
        }
      }, 500);
    });
  }

  removeFile(cacheId) {
    this.uppy.removeFile(cacheId);
    this.setUploadingFiles();
  }

  setUploadingFiles() {
    const files = this.uppy.getFiles().map((file) => {
      const storeFile = get(uploadingFiles).find(
        (storeFile) => storeFile.name === file.name,
      );

      const {
        id,
        name,
        meta,
        size,
        type,
        progress: { uploadStarted, uploadComplete, percentage },
      } = file;

      const progress = {
        uploadStarted,
        uploadComplete,
        percentage,
      };

      return {
        progress,
        name,
        size,
        type,
        cacheId: id,
        cacheKey: meta.key,
        permalink: storeFile?.permalink ?? "",
        stored: storeFile?.stored ?? false,
        virus: storeFile?.virus ?? false,
        virus_report: storeFile?.virus_report ?? {},
      };
    });
    uploadingFiles.set(files);
  }

  removeAllFiles({ params, onSuccess, onError } = {}) {
    this.removeAllUppyFiles();
    this.removeAllLinkFiles({ params, onSuccess, onError });
  }

  removeAllUppyFiles() {
    this.uppy.getFiles().forEach((file) => {
      this.uppy.removeFile(file.id);
    });
    this.setUploadingFiles();
    this.prevFilesCount = 0;
  }

  removeAllLinkFiles({ params, onSuccess, onError }) {
    quickshareApi.deleteFiles({
      params,
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  async addLinkFiles() {
    const files = get(uploadingFiles).filter((file) => !file.permalink);

    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const params = {
          id: this.linkPermalink,
          file,
        };
        this.addLinkFile({
          params,
          onSuccess: (res) => resolve(res),
          onError: (err) => reject(err),
        });
      });
    });

    const result = await Promise.allSettled(promises);

    const errorFiles = files.filter((_, i) => result[i].status === "rejected");
    errorFiles.forEach((file) => this.uppy.removeFile(file.cacheId));

    const uploadingFilesData = get(uploadingFiles).filter(
      (file) =>
        !errorFiles.some((errorFile) => errorFile.cacheId === file.cacheId),
    );
    uploadingFiles.set(uploadingFilesData);

    const statusCheckingFilesData = get(uploadingFiles).filter((item) =>
      files.find((file) => file.cacheId === item.cacheId),
    );
    this.checkFilesStatus(statusCheckingFilesData);
  }

  addLinkFile({ params, onSuccess, onError } = {}) {
    const { id, file } = params || {};

    const file_meta = {
      id: file.cacheKey.match(/^cache\/(.+)/)[1], // remove the Shrine storage prefix
      storage: "pbc_cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    };

    quickshareApi.addLinkFile({
      params: { id, file_meta },
      success: (res) => {
        uploadingFiles.update((prev) =>
          prev.map((item) =>
            item.name === file.name
              ? {
                  ...item,
                  permalink: res.item.permalink,
                  stored: res.item.stored,
                  virus: res.item.virus,
                  virus_report: res.item.virus_report,
                }
              : item,
          ),
        );
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  checkFilesStatus(uploadingFilesData) {
    let time = 500;

    const requestAction = () => {
      const processingFiles = get(uploadingFiles).filter((file) =>
        uploadingFilesData.some(
          (uploadingFile) =>
            uploadingFile.permalink === file.permalink && !file.stored,
        ),
      );

      if (processingFiles.length) {
        const params = {
          id: this.linkPermalink,
          ids: processingFiles.map((file) => file.permalink),
        };
        this.getLinkFilesStatus({ params });
        time *= 2;
        setTimeout(requestAction, time);
      }
    };

    setTimeout(requestAction, time);
  }

  getLinkFilesStatus({ params, onSuccess, onError }) {
    quickshareApi.getLinkFilesStatus({
      params,
      success: (res) => {
        const resData = res.data?.item;

        uploadingFiles.update((prev) =>
          prev.map((prevFile) => {
            const fileData = resData?.find(
              (item) => item.permalink === prevFile.permalink,
            );

            return fileData
              ? {
                  ...prevFile,
                  stored: fileData.stored,
                  virus: fileData.virus,
                  virus_report: fileData.virus_report,
                }
              : prevFile;
          }),
        );
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!QuickshareUploadService.instance) {
      QuickshareUploadService.instance = new QuickshareUploadService();
    }
    return QuickshareUploadService.instance;
  }
}

export const quickshareUploadService = QuickshareUploadService.getInstance();
