import { t } from "stores/i18n";
import { get } from "svelte/store";

import { ListFilterNameOptions } from "../../../constants";

export const getFilterPeriods = (period) => [
  {
    value: 0,
    name: ListFilterNameOptions.TIME_PERIOD,
    label: get(t)("quickshare_filter_periods.period_today"),
    checked: period === 0,
  },
  {
    value: 7,
    name: ListFilterNameOptions.TIME_PERIOD,
    label: get(t)("quickshare_filter_periods.period_7"),
    checked: period === 7,
  },
  {
    value: 14,
    name: ListFilterNameOptions.TIME_PERIOD,
    label: get(t)("quickshare_filter_periods.period_14"),
    checked: period === 14,
  },
  {
    value: 30,
    name: ListFilterNameOptions.TIME_PERIOD,
    label: get(t)("quickshare_filter_periods.period_30"),
    checked: period === 30,
  },
  {
    value: null,
    name: ListFilterNameOptions.TIME_PERIOD,
    label: get(t)("quickshare_filter_periods.period_all"),
    checked: period === null,
  },
];
