<script>
  import { onMount } from "svelte";

  import {
    QuickshareSearchBar,
    QuickshareGroups,
    QuickshareGroupSkeleton,
  } from "apps/quickshare/components";

  import { quickshareService } from "apps/quickshare/services";
  import {
    quickshareLinks,
    quickshareLinksFilters,
    isLinksListLoading,
  } from "apps/quickshare/stores";

  import { Boundary, Pagination } from "components";

  import styles from "styleguide/QuickshareContent.json";

  $: isCollapsed = false;

  let page = 1;

  onMount(async () => {
    getListData();
  });

  function toggleExpand() {
    isCollapsed = !isCollapsed;
  }

  function handlePageChange(newPage) {
    page = newPage;
    getListData();
  }

  function handleFilter() {
    page = 1;
    getListData();
  }

  function getListData() {
    const params = {
      page,
    };

    quickshareService.getLinksList({
      params,
    });
  }
</script>

<Boundary>
  <div data-component="QuickshareContent" class={styles.container}>
    <QuickshareSearchBar
      filters={{ ...$quickshareLinksFilters }}
      {isCollapsed}
      onToggleExpand={toggleExpand}
      onFilterEnter={handleFilter}
    />

    {#if $quickshareLinks?.items}
      <div class={styles.paginationContainer}>
        <Pagination
          currentPage={$quickshareLinks.page}
          pagesNumber={$quickshareLinks.pages_num}
          click={handlePageChange}
        />
      </div>
    {/if}

    {#if $isLinksListLoading}
      <QuickshareGroupSkeleton repeatCount={10} />
    {:else}
      <QuickshareGroups {isCollapsed} />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .paginationContainer {
    display: flex;
    justify-content: flex-end;
  }
</style>
