<script>
  import { link } from "apps/quickshareFrontend/stores";
  import { t } from "stores/i18n";

  import { Boundary } from "components";

  import styles from "styleguide/QuickshareLandingStepsTitle.json";

  const { isTwoWay, isRequest, title, note } = $link;
</script>

<Boundary>
  <div data-component="QuicksharelandingStepsTitle">
    <div class={styles.container}>
      <div class={styles.title}>
        {isRequest
          ? title
          : $t("quickshare_frontend_landing_step_title.send_files")}
      </div>
      {#if isTwoWay && !isRequest}
        <div class={styles.desc}>
          {$t("quickshare_frontend_landing_step_title.is_two_way")}
        </div>
      {:else if isRequest && note}
        <div class={styles.desc}>
          {note}
        </div>
      {/if}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--primary-500);
    padding: 16px 24px;
  }

  .title {
    @include typography-caption-semibold;

    line-height: 24px;
  }

  .desc {
    @include typography-body-regular;
  }
</style>
