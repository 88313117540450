<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_2564_10065)">
    <path
      opacity="0.981"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.26762 -0.0175781C4.79887 -0.0175781 7.33012 -0.0175781 9.86137 -0.0175781C10.7602 0.758127 11.6449 1.555 12.5157 2.37305C12.5508 2.44336 12.586 2.51367 12.6211 2.58398C12.6782 3.37909 12.69 4.17596 12.6563 4.97461C12.4961 5.50895 12.1387 5.73746 11.584 5.66016C11.3027 5.58974 11.1093 5.41983 11.0039 5.15039C10.9585 4.5199 10.935 3.88709 10.9336 3.25195C10.2949 2.68359 9.6563 2.11524 9.01762 1.54688C7.09573 1.52344 5.17388 1.52344 3.25199 1.54688C2.56717 1.61066 2.16288 1.9798 2.0391 2.6543C2.01566 5.95898 2.01566 9.26367 2.0391 12.5684C2.14625 13.1325 2.48023 13.4782 3.04105 13.6055C4.2717 13.6348 5.50217 13.6289 6.73246 13.5879C7.70625 11.9519 8.67305 10.3112 9.63285 8.66602C10.4346 7.57069 11.4834 7.2367 12.7793 7.66406C13.2776 7.85767 13.6702 8.17994 13.9571 8.63086C15.0498 10.4999 16.1513 12.3632 17.2618 14.2207C17.9277 15.5009 17.7343 16.6318 16.6817 17.6133C16.4823 17.7607 16.2714 17.8838 16.0489 17.9824C13.2247 17.9824 10.4004 17.9824 7.57621 17.9824C6.45715 17.4682 5.9181 16.5893 5.95902 15.3457C4.93942 15.3516 3.91989 15.3457 2.90043 15.3281C1.38161 15.0632 0.520287 14.1667 0.316444 12.6387C0.310584 10.9629 0.304725 9.28712 0.298865 7.61133C0.293661 5.84096 0.311239 4.07141 0.3516 2.30273C0.57481 1.1833 1.21348 0.409866 2.26762 -0.0175781ZM11.6895 9.19336C12.011 9.18127 12.2746 9.29844 12.4805 9.54492C13.6261 11.4727 14.7628 13.4063 15.8907 15.3457C15.9883 15.7327 15.877 16.0432 15.5567 16.2773C15.4293 16.3645 15.2887 16.4114 15.1348 16.418C12.92 16.4414 10.7051 16.4414 8.49027 16.418C7.91265 16.3325 7.64898 15.9985 7.69926 15.416C7.73649 15.3064 7.78335 15.2009 7.83988 15.0996C8.9203 13.3041 9.9867 11.4994 11.0391 9.68555C11.1845 9.42289 11.4014 9.25882 11.6895 9.19336Z"
      {fill}
    />
    <path
      opacity="0.972"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.62304 3.5686C5.91381 3.5519 7.20288 3.56948 8.49023 3.62134C8.97897 3.85636 9.14892 4.23724 9 4.76391C8.87495 5.02882 8.66988 5.19873 8.38476 5.27368C7.07062 5.30796 5.75813 5.29625 4.44726 5.23852C3.91099 4.91843 3.78794 4.47897 4.07812 3.92016C4.22595 3.7441 4.40761 3.62693 4.62304 3.5686Z"
      {fill}
    />
    <path
      opacity="0.972"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.58793 6.80298C5.85363 6.79711 7.11925 6.80298 8.38481 6.82056C8.9555 7.01853 9.1606 7.41108 9.00004 7.9983C8.86859 8.25877 8.66352 8.42871 8.38481 8.50806C7.11918 8.53151 5.85356 8.53151 4.58793 8.50806C4.09043 8.33942 3.87366 7.99369 3.93754 7.47095C4.04361 7.13641 4.26042 6.91376 4.58793 6.80298Z"
      {fill}
    />
    <path
      opacity="0.968"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.72849 9.86158C5.33799 9.85571 5.94736 9.86158 6.55661 9.87916C7.03717 9.96715 7.30671 10.2543 7.36521 10.7405C7.33269 11.1537 7.12175 11.4291 6.7324 11.5667C6.00582 11.5901 5.27928 11.5901 4.55271 11.5667C4.02002 11.3626 3.82666 10.9818 3.97263 10.4241C4.12335 10.0945 4.37531 9.90693 4.72849 9.86158Z"
      {fill}
    />
    <path
      opacity="0.959"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.7246 10.5645C12.4408 10.6348 12.7572 11.0333 12.6738 11.7598C12.7602 12.445 12.4672 12.8317 11.7949 12.9199C11.3728 12.8994 11.0975 12.6885 10.9688 12.2871C10.9453 11.9355 10.9453 11.584 10.9688 11.2324C11.0853 10.852 11.3373 10.6293 11.7246 10.5645Z"
      {fill}
    />
    <path
      opacity="0.95"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6191 13.6934C12.4016 13.6963 12.7355 14.0831 12.621 14.8535C12.3587 15.3683 11.9544 15.5265 11.4082 15.3281C10.9328 15.0148 10.8215 14.5988 11.0742 14.0801C11.2132 13.8879 11.3949 13.759 11.6191 13.6934Z"
      {fill}
    />
  </g>
  <defs>
    <clipPath id="clip0_2564_10065">
      <rect width="18" height="18" fill="white" />
    </clipPath>
  </defs>
</svg>
