<script>
  import { quickshareService } from "apps/quickshare/services";
  import Icons from "components/icons/Icons.svelte";
  import { DownloadFilesVirusModal } from "components/modals";
  import { t } from "stores/i18n";

  import { fileIcon } from "lib/helpers";

  import { Button, PulseDot, Tooltip } from "components";

  import styles from "styleguide/QuickshareGroupFileItem.json";

  export let file;
  export let sessionDate;
  export let sessionPermalink;
  export let isInternal;
  export let linkPermalink;

  $: isShowVirusModal = "";

  function handleDownloadFile(fileId, isVirus) {
    if (isVirus) {
      isShowVirusModal = true;
    } else {
      submitDownloadFile(fileId);
    }
  }

  function submitDownloadFile(fileId) {
    isShowVirusModal = false;

    const params = {
      linkPermalink,
      filePermalink: fileId,
    };

    quickshareService.downloadSingleFile({
      params,
      onSuccess: () => updateStatus(fileId),
    });
  }

  function updateStatus(filePermalink) {
    const params = {
      permalink: linkPermalink,
      date: sessionDate,
      sessionPermalink,
      ...(filePermalink && { filePermalink }),
      ...(!filePermalink && { isAllSessionDownload: true }),
    };

    quickshareService.updateFilesDownloadStatus(params);
  }
</script>

<div class={styles.wrapper} data-component="QuickshareGroupFileItem">
  {#if !isInternal && !file.downloaded}
    <div class={styles.pulse}>
      <PulseDot isActive={true} />
    </div>
  {/if}

  <div class={styles.info}>
    <div class={styles.icon}>
      {#if file.virus}
        <Tooltip
          message={$t("quickshare_virus_tooltips.detected")}
          showArrow={true}
        >
          <Icons
            icon="errorFilled"
            width="22"
            height="22"
            fill="var(--red-100)"
          />
        </Tooltip>
      {:else}
        <img src={fileIcon(file.name || file.filename || "")} alt="" />
      {/if}
    </div>
    <div class={styles.content}>
      <span class={styles.name}>
        {file.name}
      </span>
      <div class={styles.size}>({file.size} MB)</div>
    </div>
  </div>

  <DownloadFilesVirusModal
    isShowModal={isShowVirusModal}
    onHide={() => (isShowVirusModal = false)}
    isAllInfected={true}
    isSingleFile={true}
    onSubmit={() => submitDownloadFile(file.permalink)}
  />

  <div class={styles.actions}>
    {#if file.virus}
      <Tooltip
        message={$t("quickshare_virus_tooltips.report")}
        showArrow={true}
      >
        <Button
          style={"primary-tonal icon small"}
          click={() => window.open(file.virus_report.url, "_blank")}
        >
          <Icons
            icon="virusReport"
            fill="var(--blue-100)"
            width={18}
            height={18}
          />
        </Button>
      </Tooltip>
    {/if}

    <Tooltip
      message={$t("quickshare_group_file_item.download")}
      showArrow={true}
    >
      <Button
        style={"primary-text icon small"}
        click={() => handleDownloadFile(file.permalink, file.virus)}
      >
        <Icons icon="download" width="18" height="18" fill="var(--blue-100)" />
      </Button>
    </Tooltip>
  </div>
</div>

<style lang="scss">
  .wrapper {
    position: relative;
    width: calc(50% - 8px);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    border-radius: var(--border-radius);
    padding: 6px;
    border: 1px solid var(--primary-050);
    cursor: pointer;
  }

  .info {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .content {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    gap: 8px;
  }

  .name {
    @include typography-body-medium;

    color: var(--blue-text);
    display: inline-block;
    text-overflow: ellipsis;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
  }

  .size {
    @include typography-footnote-regular;

    color: var(--primary-200);
  }

  .actions {
    display: flex;
    gap: 4px;
  }

  .pulse {
    position: absolute;
    right: 0;
    top: 0;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
  }
</style>
