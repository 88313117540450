<script>
  import { Modal } from "components/modals";
  import { t } from "stores/i18n";

  import { Button } from "components";

  import styles from "styleguide/UploadingFilesVirusModal.json";

  export let isShowModal = false;
  export let onToggle = () => {};
  export let onSubmit = () => {};
</script>

<div data-component="UploadingFilesVirusModal">
  <Modal
    shown={isShowModal}
    onHide={onToggle}
    title={$t("quickshare_uploading_files_virus_modal.virus_detected")}
  >
    <div>{$t("quickshare_uploading_files_virus_modal.virus_modal_desc")}</div>

    <svelte:fragment slot="bottom">
      <div class={styles.footer}>
        <Button click={onToggle} style={"primary-text"}>
          {$t("quickshare_uploading_files_virus_modal.cancel")}
        </Button>
        <Button click={onSubmit} style={"primary"}>
          {$t("quickshare_uploading_files_virus_modal.upload_files")}
        </Button>
      </div>
    </svelte:fragment>
  </Modal>
</div>

<style lang="scss">
  .footer {
    display: flex;
    gap: 16px;
  }
</style>
