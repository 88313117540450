import Api from "apis/api";
import { buildFormData } from "lib/helpers";

class QuickshareApi {
  constructor() {
    this.endpoint = "/quickshare";
  }

  getLinksList(options) {
    const { success, error, params } = options || {};

    const formData = new FormData();
    if (params) {
      buildFormData(formData, params);
    }

    Api.get(`${this.endpoint}/links`, {
      params: formData,
      success,
      error,
    });
  }

  getPersonalInbox(options) {
    const { success, error } = options || {};

    Api.get(`${this.endpoint}/links/personal_inbox`, {
      success,
      error,
    });
  }

  createLink(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.post(`${this.endpoint}/links`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error) {
          error();
        } else {
          console.error("Error", response);
        }
      },
    });
  }

  addLinkFile(options) {
    const { params, success, error } = options;
    const { id } = params;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}/links/${id}/files`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (err) => {
        if (error) {
          error(err);
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  getLinkFilesStatus(options) {
    const { params, success, error } = options || {};
    const { id, ids } = params;

    const formData = new FormData();

    for (let id of ids) {
      formData.append("ids[]", id);
    }

    Api.get(`${this.endpoint}/links/${id}/files/status`, {
      params: formData,
      success,
      error,
    });
  }

  updateLink(options) {
    const { params, success, error } = options || {};

    const {
      title,
      permalink,
      link_type,
      message,
      password,
      reset_password,
      expires_in,
      download_limit,
      email_notification,
      quickshare_files = [],
    } = params;

    const payload = {
      ...(title && { title }),
      ...(link_type && { link_type }),
      ...(expires_in && { expires_in }),
      reset_password: !!reset_password,
      download_limit: !!download_limit,
      password: password || "",
      permalink,
      message,
      email_notification,
      quickshare_files,
    };

    const formData = new FormData();
    buildFormData(formData, payload);

    Api.patch(`${this.endpoint}/links/${params.permalink}`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (response) => {
        if (error && error instanceof Function) {
          error();
        }

        console.error("Error", response);
      },
    });
  }

  deleteLink(options) {
    const { params, success, error } = options || {};

    Api.delete(`${this.endpoint}/links/${params.permalink}`, {
      success,
      error,
    });
  }

  downloadFilesForSession(options) {
    const { params, success, error } = options || {};
    const { excludeSuspicious } = params || {};

    const data = {
      exclude_suspicious: !!excludeSuspicious,
    };

    Api.downloadBatch(
      `${this.endpoint}/links/${params.permalink}/sessions/${params.sessionId}/download`,
      {
        params: data,
        success,
        error,
      },
    );
  }

  downloadFiles(options) {
    const { params, success, error } = options || {};
    const { excludeSuspicious } = params || {};

    const data = {
      exclude_suspicious: !!excludeSuspicious,
    };

    Api.downloadBatch(
      `${this.endpoint}/links/${params.permalink}/files/download`,
      {
        params: data,
        success,
        error,
      },
    );
  }

  downloadSingleFile(options) {
    const { params, success, error } = options || {};
    const formData = new FormData();

    buildFormData(formData, params);
    Api.get(
      `${this.endpoint}/links/${params.link_id}/files/${params.id}/download_item`,
      {
        success,
        error,
      },
    );
  }

  deleteFiles(options) {
    const { params, success, error } = options || {};

    Api.delete(`${this.endpoint}/links/${params.permalink}/files`, {
      success,
      error,
    });
  }

  deleteSingleFile(options) {
    const { params, success, error } = options || {};

    Api.delete(
      `${this.endpoint}/links/${params.permalink}/files/${params.id}`,
      {
        success,
        error,
      },
    );
  }

  regeneratePermalink(options) {
    const { params, success, error } = options || {};

    Api.patch(
      `${this.endpoint}/links/${params.permalink}/regenarate_peramalink`,
      {
        success,
        error,
      },
    );
  }

  static instance;

  static getInstance() {
    if (!QuickshareApi.instance) {
      QuickshareApi.instance = new QuickshareApi();
    }
    return QuickshareApi.instance;
  }
}

export const quickshareApi = QuickshareApi.getInstance();
