<script>
  import { onDestroy } from "svelte";
  import clsx from "clsx";
  import { createPopperActions } from "svelte-popperjs";
  import { IconButton } from "components";
  import styles from "styleguide/Popover.json";

  export let placement = "auto";
  export let strategy = "fixed";
  export let showCloseButton = true;
  export let open = false;
  export let showArrow = true;
  export let style = "";
  export let offsetPosition = [0, 8];
  export let isMaxWidth = false;
  export let preventOverflowPadding = 0;
  // allow to prevent auto-flipping of the popper placement
  // e.g. in case of space lack for "top" it will be converted to "bottom" by default
  export let isFlip = true;

  let popoverElement = null;

  const [popperRef, popperContent] = createPopperActions({
    placement: placement,
    strategy: strategy,
  });
  const extraOpts = {
    modifiers: [
      { name: "offset", options: { offset: offsetPosition } },
      ...(!isFlip
        ? [{ name: "flip", options: { flipVariations: false } }]
        : []),
      ...(preventOverflowPadding
        ? [
            {
              name: "preventOverflow",
              options: {
                padding: preventOverflowPadding,
              },
            },
          ]
        : []),
    ],
  };

  function getStyles() {
    return style
      .split(" ")
      .map((x) => styles[x])
      .join(" ");
  }

  function toggleOnOpen() {
    open = !open;
  }

  function handleOnClose() {
    open = false;
  }

  function handleOutsideClick(event) {
    if (!popoverElement.contains(event.target)) {
      handleOnClose();
    }
  }

  $: if (open && popoverElement) {
    window.addEventListener("click", handleOutsideClick);
  }

  onDestroy(() => {
    window.removeEventListener("click", handleOutsideClick);
  });
</script>

<div
  data-component="Popover"
  use:popperRef
  bind:this={popoverElement}
  class={clsx([styles.wrapper, getStyles()])}
>
  <div
    on:click={toggleOnOpen}
    on:keydown={toggleOnOpen}
    role="button"
    tabindex="0"
  >
    <slot />
  </div>
  {#if open && $$slots.content}
    <div
      use:popperContent={extraOpts}
      class={`${styles.popover} ${isMaxWidth ? styles["max-width"] : ""}`}
    >
      {#if showArrow}
        <div class={styles.arrow} data-popper-arrow />
      {/if}
      {#if showCloseButton}
        <div class={styles["popover-close"]}>
          <IconButton icon="x" click={handleOnClose} />
        </div>
      {/if}
      <slot name="content" />
    </div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    position: relative;
    cursor: pointer;

    [data-popper-placement^="top"] {
      .arrow {
        bottom: -4px;

        &:before {
          border-bottom-width: 1px;
          border-right-width: 1px;
          top: 1px;
        }
      }
    }

    [data-popper-placement^="bottom"] {
      .arrow {
        top: -4px;

        &:before {
          border-top-width: 1px;
          border-left-width: 1px;
          top: -1px;
        }
      }
    }

    [data-popper-placement^="left"] {
      .arrow {
        right: -4px;

        &:before {
          left: 1px;
          border-top-width: 1px;
          border-right-width: 1px;
        }
      }
    }

    [data-popper-placement^="right"] {
      .arrow {
        left: -4px;

        &:before {
          border-bottom-width: 1px;
          border-left-width: 1px;
          left: -1px;
        }
      }
    }
  }

  .arrow {
    position: absolute;
    width: 8px;
    height: 8px;
    background: inherit;
    visibility: hidden;

    &:before {
      position: absolute;
      width: 8px;
      height: 8px;
      background: inherit;
      visibility: visible;
      content: "";
      transform: rotate(45deg);
      border-color: var(--primary-050);
      border-style: solid;
      border-width: 0;
    }
  }

  .popover {
    width: max-content;
    max-width: 325px;
    padding: 24px;
    background: #ffffff;
    z-index: var(--z-index-popover);
    border: 1px solid var(--primary-050);
    border-radius: var(--border-radius);
    box-shadow:
      0 8px 24px -4px rgba(24, 39, 75, 0.08),
      0px 6px 12px -6px rgba(24, 39, 75, 0.12);

    &.max-width {
      max-width: unset;
    }

    .popover-close {
      position: absolute;
      top: 16px;
      right: 8px;
    }

    .no-padding & {
      padding: 0;
      border: none;
    }
  }
</style>
