<script>
  import styles from "styleguide/PulseDot.json";

  export let isActive = false;
</script>

<div
  data-component="PulseDot"
  class={`${styles.circle} ${isActive ? styles["circle--active"] : ""}`}
></div>

<style lang="scss">
  .circle {
    position: relative;
    width: 10px;
    height: 10px;
    border-radius: 45px;
    border: 1px solid var(--blue-050);
    background: var(--white);

    &.circle--active {
      border: none;
      background: none;
    }

    &.circle--active:before {
      content: "";
      position: relative;
      display: block;
      width: 220%;
      height: 220%;
      box-sizing: border-box;
      margin-left: -60%;
      margin-top: -60%;
      border-radius: 45px;
      background: var(--blue-025);
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }

    &.circle--active:after {
      content: "";
      position: absolute;
      left: 0;
      top: calc(50% + 1px);
      display: block;
      width: 100%;
      height: 100%;
      background: var(--blue-100);
      border-radius: 15px;
    }
  }

  @keyframes pulse-ring {
    0% {
      transform: scale(0.33);
    }
  }

  @keyframes pulse-dot {
    0% {
      transform: scale(0.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.8);
    }
  }
</style>
