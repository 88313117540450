export const linkInitValue = {
  title: "",
  note: "",
  id: "",
  hasPassword: false,
  owner: null,
  expiredDate: "",
  downloadLimit: true,
  linkType: null,
  files: [],
  isDownloaded: false,
  isRequest: false,
  isShare: false,
  isTwoWay: false,
  privacyLink: "",
  imprintLink: "",
  emailVerified: false,
  sessionId: "",
};
