<script>
  export let width = 71;
  export let height = 64;
</script>

<svg
  {width}
  {height}
  viewBox="0 0 71 64"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="Illustration" clip-path="url(#clip0_128_6720)">
    <path
      id="Vector"
      d="M36.2886 0.139968L33.983 0.738323L5.57799 8.1102L3.27243 8.70856C2.15333 9.00035 1.19494 9.72696 0.607521 10.729C0.0200984 11.731 -0.148385 12.9267 0.139034 14.0535L10.1365 53.069C10.4264 54.1952 11.1484 55.1597 12.1441 55.7509C13.1398 56.3421 14.3278 56.5117 15.4475 56.2224L15.4535 56.2209L48.4578 47.6554L48.4637 47.6538C49.5828 47.362 50.5412 46.6354 51.1286 45.6334C51.716 44.6313 51.8845 43.4357 51.5971 42.3088L41.5997 3.29339C41.3097 2.16715 40.5877 1.20264 39.5921 0.611457C38.5964 0.0202787 37.4084 -0.149285 36.2886 0.139968Z"
      fill="#F2F2F2"
    />
    <path
      id="Vector_2"
      d="M36.6017 1.36276L33.8709 2.07147L6.31621 9.22266L3.58546 9.93136C2.78853 10.1391 2.10603 10.6566 1.68771 11.3702C1.26939 12.0837 1.14941 12.9352 1.35409 13.7376L11.3515 52.7531C11.558 53.5551 12.0722 54.242 12.7812 54.6629C13.4903 55.0839 14.3363 55.2047 15.1337 54.9987L15.1396 54.9972L48.1439 46.4317L48.1499 46.4301C48.9468 46.2223 49.6293 45.7049 50.0476 44.9913C50.4659 44.2777 50.5859 43.4263 50.3812 42.6238L40.3838 3.60839C40.1773 2.80637 39.6632 2.11952 38.9541 1.69853C38.2451 1.27754 37.3991 1.15678 36.6017 1.36276Z"
      fill="white"
    />
    <path
      id="Vector_3"
      d="M34.5398 13.997L17.8437 18.3301C17.6572 18.3785 17.4593 18.3503 17.2934 18.2518C17.1276 18.1534 17.0074 17.9926 16.9593 17.8049C16.9112 17.6173 16.9392 17.4181 17.037 17.2511C17.1349 17.0842 17.2946 16.9632 17.4811 16.9148L34.1771 12.5818C34.3635 12.5336 34.5613 12.5618 34.7271 12.6603C34.8928 12.7588 35.0129 12.9195 35.0609 13.1071C35.109 13.2946 35.0811 13.4937 34.9834 13.6606C34.8857 13.8274 34.7261 13.9484 34.5398 13.997Z"
      fill="#F2F2F2"
    />
    <path
      id="Vector_4"
      d="M38.0521 15.6325L18.4557 20.7183C18.2692 20.7667 18.0712 20.7385 17.9054 20.64C17.7395 20.5415 17.6193 20.3808 17.5712 20.1931C17.5231 20.0055 17.5511 19.8062 17.6489 19.6393C17.7468 19.4724 17.9065 19.3514 18.093 19.303L37.6894 14.2172C37.8759 14.1688 38.0739 14.197 38.2397 14.2955C38.4056 14.3939 38.5258 14.5547 38.5739 14.7424C38.622 14.93 38.594 15.1292 38.4961 15.2962C38.3983 15.4631 38.2386 15.5841 38.0521 15.6325Z"
      fill="#F2F2F2"
    />
    <path
      id="Vector_5"
      d="M37.2822 24.6995L20.5861 29.0326C20.4938 29.0566 20.3977 29.062 20.3033 29.0486C20.2089 29.0352 20.118 29.0032 20.0359 28.9544C19.9537 28.9056 19.882 28.8411 19.8246 28.7644C19.7673 28.6877 19.7255 28.6004 19.7017 28.5075C19.6779 28.4146 19.6725 28.3178 19.6858 28.2228C19.6992 28.1278 19.731 28.0363 19.7794 27.9537C19.8279 27.871 19.892 27.7988 19.9682 27.7411C20.0444 27.6834 20.1312 27.6414 20.2235 27.6174L36.9196 23.2843C37.0119 23.2604 37.108 23.2549 37.2024 23.2684C37.2969 23.2818 37.3877 23.3138 37.4699 23.3625C37.552 23.4113 37.6238 23.4759 37.6811 23.5525C37.7384 23.6292 37.7802 23.7165 37.804 23.8094C37.8278 23.9024 37.8332 23.9991 37.8199 24.0941C37.8065 24.1891 37.7747 24.2806 37.7263 24.3632C37.6778 24.4459 37.6137 24.5181 37.5375 24.5758C37.4613 24.6335 37.3745 24.6756 37.2822 24.6995Z"
      fill="#F2F2F2"
    />
    <path
      id="Vector_6"
      d="M40.7945 26.335L21.1981 31.4208C21.1058 31.4448 21.0096 31.4502 20.9152 31.4368C20.8208 31.4233 20.7299 31.3913 20.6478 31.3426C20.5657 31.2938 20.4939 31.2292 20.4366 31.1526C20.3792 31.0759 20.3375 30.9886 20.3137 30.8957C20.2898 30.8027 20.2845 30.706 20.2978 30.611C20.3111 30.516 20.3429 30.4245 20.3914 30.3419C20.4398 30.2592 20.504 30.187 20.5802 30.1293C20.6564 30.0716 20.7431 30.0296 20.8354 30.0056L40.4319 24.9198C40.6183 24.8714 40.8163 24.8995 40.9821 24.998C41.148 25.0965 41.2682 25.2572 41.3163 25.4449C41.3644 25.6326 41.3364 25.8318 41.2386 25.9987C41.1407 26.1656 40.981 26.2866 40.7945 26.335Z"
      fill="#F2F2F2"
    />
    <path
      id="Vector_7"
      d="M40.0246 35.4021L23.3286 39.7352C23.1422 39.7834 22.9443 39.7552 22.7786 39.6567C22.6128 39.5582 22.4927 39.3975 22.4446 39.2099C22.3966 39.0223 22.4245 38.8232 22.5222 38.6563C22.62 38.4894 22.7796 38.3684 22.9659 38.3199L39.662 33.9869C39.8485 33.9385 40.0464 33.9666 40.2123 34.0651C40.3782 34.1636 40.4984 34.3243 40.5464 34.512C40.5945 34.6997 40.5666 34.8989 40.4687 35.0658C40.3709 35.2327 40.2111 35.3537 40.0246 35.4021Z"
      fill="#F2F2F2"
    />
    <path
      id="Vector_8"
      d="M43.537 37.0375L23.9405 42.1233C23.8482 42.1474 23.752 42.1529 23.6575 42.1395C23.563 42.1262 23.4721 42.0942 23.3899 42.0455C23.3077 41.9967 23.2359 41.9321 23.1785 41.8554C23.1211 41.7787 23.0793 41.6914 23.0555 41.5984C23.0317 41.5054 23.0263 41.4086 23.0396 41.3135C23.053 41.2185 23.0848 41.127 23.1334 41.0443C23.1819 40.9616 23.2461 40.8894 23.3224 40.8317C23.3987 40.774 23.4855 40.732 23.5779 40.7081L43.1743 35.6223C43.3608 35.5739 43.5587 35.6021 43.7246 35.7006C43.8905 35.799 44.0107 35.9598 44.0588 36.1475C44.1068 36.3351 44.0789 36.5343 43.981 36.7013C43.8832 36.8682 43.7234 36.9892 43.537 37.0375Z"
      fill="#F2F2F2"
    />
    <path
      id="Vector_9"
      d="M14.9934 22.773L9.0848 24.3064C8.99539 24.3295 8.90052 24.316 8.82102 24.2688C8.74151 24.2216 8.68386 24.1446 8.66071 24.0546L7.3044 18.7616C7.28145 18.6716 7.2949 18.5761 7.34181 18.4961C7.38872 18.4161 7.46524 18.3581 7.55461 18.3348L13.4632 16.8014C13.5526 16.7783 13.6475 16.7918 13.727 16.839C13.8065 16.8862 13.8641 16.9632 13.8873 17.0532L15.2436 22.3462C15.2665 22.4362 15.2531 22.5317 15.2062 22.6117C15.1593 22.6917 15.0827 22.7497 14.9934 22.773Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_10"
      d="M17.7359 33.4755L11.8274 35.009C11.7379 35.0321 11.6431 35.0185 11.5636 34.9713C11.4841 34.9241 11.4264 34.8471 11.4033 34.7572L10.047 29.4641C10.024 29.3742 10.0375 29.2787 10.0844 29.1987C10.1313 29.1187 10.2078 29.0606 10.2972 29.0373L16.2057 27.5039C16.2951 27.4808 16.39 27.4943 16.4695 27.5416C16.549 27.5888 16.6067 27.6658 16.6298 27.7557L17.9861 33.0488C18.0091 33.1387 17.9956 33.2342 17.9487 33.3142C17.9018 33.3942 17.8253 33.4522 17.7359 33.4755Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_11"
      d="M20.4784 44.1781L14.5698 45.7115C14.4804 45.7346 14.3855 45.7211 14.306 45.6739C14.2265 45.6267 14.1688 45.5497 14.1457 45.4597L12.7894 40.1667C12.7664 40.0767 12.7799 39.9812 12.8268 39.9012C12.8737 39.8212 12.9502 39.7632 13.0396 39.7399L18.9482 38.2065C19.0376 38.1834 19.1324 38.1969 19.2119 38.2441C19.2915 38.2913 19.3491 38.3683 19.3723 38.4583L20.7286 43.7513C20.7515 43.8413 20.7381 43.9368 20.6912 44.0168C20.6443 44.0968 20.5677 44.1548 20.4784 44.1781Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_12"
      d="M59.3153 10.0294H25.219C24.063 10.0308 22.9546 10.4935 22.1372 11.3162C21.3198 12.1388 20.8599 13.2542 20.8586 14.4177V54.7095C20.8599 55.873 21.3198 56.9884 22.1372 57.811C22.9546 58.6337 24.063 59.0965 25.219 59.0978H59.3153C60.4714 59.0965 61.5797 58.6337 62.3971 57.811C63.2146 56.9884 63.6744 55.873 63.6757 54.7095V14.4177C63.6744 13.2542 63.2146 12.1388 62.3971 11.3162C61.5797 10.4935 60.4714 10.0308 59.3153 10.0294Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_13"
      d="M59.3154 11.2927H25.219C24.3958 11.2937 23.6065 11.6232 23.0244 12.209C22.4423 12.7949 22.1148 13.5892 22.1139 14.4177V54.7095C22.1148 55.538 22.4423 56.3323 23.0244 56.9182C23.6065 57.504 24.3958 57.8335 25.219 57.8345H59.3154C60.1386 57.8335 60.9279 57.504 61.51 56.9182C62.0921 56.3323 62.4196 55.538 62.4205 54.7095V14.4177C62.4196 13.5892 62.0921 12.7949 61.51 12.209C60.9279 11.6232 60.1386 11.2937 59.3154 11.2927Z"
      fill="white"
    />
    <path
      id="Vector_14"
      d="M62.8974 64C67.2934 64 70.8571 60.4136 70.8571 55.9895C70.8571 51.5654 67.2934 47.979 62.8974 47.979C58.5014 47.979 54.9377 51.5654 54.9377 55.9895C54.9377 60.4136 58.5014 64 62.8974 64Z"
      fill="#4684E6"
    />
    <path
      id="Vector_15"
      d="M66.7042 54.9446H63.9356V52.1583C63.9356 51.8812 63.8262 51.6155 63.6315 51.4195C63.4368 51.2236 63.1728 51.1135 62.8974 51.1135C62.622 51.1135 62.358 51.2236 62.1633 51.4195C61.9686 51.6155 61.8592 51.8812 61.8592 52.1583V54.9446H59.0906C58.8152 54.9446 58.5512 55.0547 58.3565 55.2506C58.1618 55.4466 58.0524 55.7123 58.0524 55.9895C58.0524 56.2666 58.1618 56.5323 58.3565 56.7283C58.5512 56.9242 58.8152 57.0343 59.0906 57.0343H61.8592V59.8206C61.8592 60.0977 61.9686 60.3635 62.1633 60.5594C62.358 60.7554 62.622 60.8654 62.8974 60.8654C63.1728 60.8654 63.4368 60.7554 63.6315 60.5594C63.8262 60.3635 63.9356 60.0977 63.9356 59.8206V57.0343H66.7042C66.9796 57.0343 67.2436 56.9242 67.4383 56.7283C67.633 56.5323 67.7424 56.2666 67.7424 55.9895C67.7424 55.7123 67.633 55.4466 67.4383 55.2506C67.2436 55.0547 66.9796 54.9446 66.7042 54.9446Z"
      fill="white"
    />
    <path
      id="Vector_16"
      d="M54.1838 33.433H36.9415C36.8461 33.4331 36.7516 33.4143 36.6634 33.3776C36.5752 33.3409 36.4951 33.2871 36.4276 33.2193C36.3601 33.1514 36.3065 33.0708 36.27 32.9821C36.2334 32.8934 36.2146 32.7983 36.2146 32.7023C36.2146 32.6062 36.2334 32.5111 36.27 32.4224C36.3065 32.3337 36.3601 32.2531 36.4276 32.1852C36.4951 32.1174 36.5752 32.0636 36.6634 32.0269C36.7516 31.9902 36.8461 31.9714 36.9415 31.9715H54.1838C54.3763 31.9717 54.5607 32.0488 54.6967 32.1858C54.8327 32.3228 54.9091 32.5086 54.9091 32.7023C54.9091 32.8959 54.8327 33.0817 54.6967 33.2187C54.5607 33.3557 54.3763 33.4328 54.1838 33.433Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_17"
      d="M57.1791 35.8993H36.9415C36.8461 35.8994 36.7516 35.8806 36.6634 35.8439C36.5752 35.8073 36.4951 35.7534 36.4276 35.6856C36.3601 35.6177 36.3065 35.5371 36.27 35.4484C36.2334 35.3597 36.2146 35.2646 36.2146 35.1686C36.2146 35.0725 36.2334 34.9774 36.27 34.8887C36.3065 34.8 36.3601 34.7194 36.4276 34.6515C36.4951 34.5837 36.5752 34.5299 36.6634 34.4932C36.7516 34.4565 36.8461 34.4377 36.9415 34.4378H57.1791C57.3716 34.4378 57.5563 34.5148 57.6925 34.6518C57.8287 34.7889 57.9052 34.9748 57.9052 35.1686C57.9052 35.3624 57.8287 35.5482 57.6925 35.6853C57.5563 35.8223 57.3716 35.8993 57.1791 35.8993Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_18"
      d="M54.1838 44.4857H36.9415C36.8461 44.4858 36.7516 44.467 36.6634 44.4303C36.5752 44.3936 36.4951 44.3398 36.4276 44.2719C36.3601 44.2041 36.3065 44.1235 36.27 44.0348C36.2334 43.9461 36.2146 43.851 36.2146 43.7549C36.2146 43.6589 36.2334 43.5638 36.27 43.4751C36.3065 43.3864 36.3601 43.3058 36.4276 43.2379C36.4951 43.17 36.5752 43.1162 36.6634 43.0796C36.7516 43.0429 36.8461 43.0241 36.9415 43.0242H54.1838C54.3764 43.0242 54.5611 43.1012 54.6973 43.2382C54.8334 43.3752 54.9099 43.5611 54.9099 43.7549C54.9099 43.9487 54.8334 44.1346 54.6973 44.2717C54.5611 44.4087 54.3764 44.4857 54.1838 44.4857Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_19"
      d="M57.1791 46.952H36.9415C36.8461 46.9521 36.7516 46.9333 36.6634 46.8966C36.5752 46.8599 36.4951 46.8061 36.4276 46.7382C36.3601 46.6704 36.3065 46.5898 36.27 46.5011C36.2334 46.4124 36.2146 46.3173 36.2146 46.2212C36.2146 46.1252 36.2334 46.0301 36.27 45.9414C36.3065 45.8527 36.3601 45.7721 36.4276 45.7042C36.4951 45.6364 36.5752 45.5825 36.6634 45.5459C36.7516 45.5092 36.8461 45.4904 36.9415 45.4905H57.1791C57.2745 45.4904 57.369 45.5092 57.4572 45.5459C57.5454 45.5825 57.6255 45.6364 57.693 45.7042C57.7605 45.7721 57.8141 45.8527 57.8506 45.9414C57.8872 46.0301 57.906 46.1252 57.906 46.2212C57.906 46.3173 57.8872 46.4124 57.8506 46.5011C57.8141 46.5898 57.7605 46.6704 57.693 46.7382C57.6255 46.8061 57.5454 46.8599 57.4572 46.8966C57.369 46.9333 57.2745 46.9521 57.1791 46.952Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_20"
      d="M33.0792 37.0189H26.9773C26.885 37.0188 26.7965 36.9819 26.7312 36.9162C26.666 36.8505 26.6293 36.7614 26.6292 36.6685V31.2023C26.6293 31.1094 26.666 31.0203 26.7312 30.9546C26.7965 30.8889 26.885 30.852 26.9773 30.8519H33.0792C33.1715 30.852 33.26 30.8889 33.3253 30.9546C33.3906 31.0203 33.4273 31.1094 33.4274 31.2023V36.6685C33.4273 36.7614 33.3906 36.8505 33.3253 36.9162C33.26 36.9819 33.1715 37.0188 33.0792 37.0189Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_21"
      d="M33.0792 48.0716H26.9773C26.885 48.0715 26.7965 48.0345 26.7312 47.9688C26.666 47.9032 26.6293 47.8141 26.6292 47.7212V42.255C26.6293 42.1621 26.666 42.073 26.7312 42.0073C26.7965 41.9416 26.885 41.9047 26.9773 41.9046H33.0792C33.1715 41.9047 33.26 41.9416 33.3253 42.0073C33.3906 42.073 33.4273 42.1621 33.4274 42.255V47.7212C33.4273 47.8141 33.3906 47.9032 33.3253 47.9688C33.26 48.0345 33.1715 48.0715 33.0792 48.0716Z"
      fill="#E6E6E6"
    />
    <path
      id="Vector_22"
      d="M54.203 21.1292H41.491C41.2984 21.1292 41.1137 21.0522 40.9776 20.9151C40.8414 20.7781 40.7649 20.5922 40.7649 20.3984C40.7649 20.2046 40.8414 20.0187 40.9776 19.8817C41.1137 19.7447 41.2984 19.6677 41.491 19.6677H54.203C54.3956 19.6677 54.5803 19.7447 54.7165 19.8817C54.8527 20.0187 54.9292 20.2046 54.9292 20.3984C54.9292 20.5922 54.8527 20.7781 54.7165 20.9151C54.5803 21.0522 54.3956 21.1292 54.203 21.1292Z"
      fill="#CCCCCC"
    />
    <path
      id="Vector_23"
      d="M57.1983 23.5955H41.491C41.3957 23.5955 41.3012 23.5766 41.2131 23.5399C41.125 23.5031 41.045 23.4493 40.9776 23.3815C40.9101 23.3136 40.8567 23.233 40.8202 23.1444C40.7837 23.0557 40.7649 22.9607 40.7649 22.8647C40.7649 22.7688 40.7837 22.6737 40.8202 22.5851C40.8567 22.4964 40.9101 22.4159 40.9776 22.348C41.045 22.2801 41.125 22.2263 41.2131 22.1896C41.3012 22.1529 41.3957 22.134 41.491 22.134H57.1983C57.3909 22.134 57.5756 22.211 57.7117 22.348C57.8479 22.485 57.9244 22.6709 57.9244 22.8647C57.9244 23.0585 57.8479 23.2444 57.7117 23.3815C57.5756 23.5185 57.3909 23.5955 57.1983 23.5955Z"
      fill="#CCCCCC"
    />
    <path
      id="Vector_24"
      d="M38.6776 26.5843H26.9582C26.8658 26.5842 26.7773 26.5473 26.7121 26.4816C26.6468 26.4159 26.6101 26.3268 26.61 26.2339V17.0292C26.6101 16.9363 26.6468 16.8472 26.7121 16.7815C26.7773 16.7159 26.8658 16.6789 26.9582 16.6788H38.6776C38.7699 16.6789 38.8584 16.7159 38.9236 16.7815C38.9889 16.8472 39.0256 16.9363 39.0257 17.0292V26.2339C39.0256 26.3268 38.9889 26.4159 38.9236 26.4816C38.8584 26.5473 38.7699 26.5842 38.6776 26.5843Z"
      fill="#4684E6"
    />
  </g>
  <defs>
    <clipPath id="clip0_128_6720">
      <rect width="70.8571" height="64" fill="white" />
    </clipPath>
  </defs>
</svg>
