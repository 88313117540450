<script>
  import { quickshareService } from "apps/quickshare/services";

  import { DownloadFilesVirusModal } from "components/modals";
  import { t } from "stores/i18n";

  import { Button, Toggler } from "components";

  import { QuickshareGroupFileItem } from "./components";

  import styles from "styleguide/QuickshareGroupFiles.json";

  export let files;
  export let sessionDate;
  export let sessionPermalink;
  export let isInternal;
  export let linkPermalink;

  $: isVirus = files.some((file) => file.virus);
  $: isAllInfected = files.every((file) => file.virus);
  $: isShowVirusModal = "";

  function handleDownloadFiles() {
    if (isVirus) {
      isShowVirusModal = true;
    } else {
      submitDownloadFiles();
    }
  }

  function submitDownloadFiles(includeInfected = true) {
    isShowVirusModal = false;

    const params = {
      sessionId: sessionPermalink,
      permalink: linkPermalink,
      excludeSuspicious: !includeInfected,
    };

    quickshareService.downloadSessionFiles({
      params,
      onSuccess: () => updateStatus(),
    });
  }

  function updateStatus(filePermalink) {
    const params = {
      permalink: linkPermalink,
      date: sessionDate,
      sessionPermalink,
      ...(filePermalink && { filePermalink }),
      ...(!filePermalink && { isAllSessionDownload: true }),
    };

    quickshareService.updateFilesDownloadStatus(params);
  }
</script>

{#if files?.length}
  <div data-component="QuickshareGroupFiles">
    <Toggler
      showText={$t("quickshare_group_files.show", {
        postProcess: "interval",
        count: files.length,
      })}
      hideText={$t("quickshare_group_files.hide", {
        postProcess: "interval",
        count: files.length,
      })}
    >
      <div slot="content" class={styles.content}>
        <div class={styles.list}>
          {#each files as file}
            <QuickshareGroupFileItem
              {file}
              {sessionDate}
              {sessionPermalink}
              {isInternal}
              {linkPermalink}
            />
          {/each}
        </div>

        <Button style={"primary-tonal"} click={handleDownloadFiles}>
          {$t("quickshare_group_files.download_all")}
        </Button>
      </div>
    </Toggler>

    <DownloadFilesVirusModal
      isShowModal={isShowVirusModal}
      onHide={() => (isShowVirusModal = false)}
      {isAllInfected}
      onSubmit={submitDownloadFiles}
    />
  </div>
{/if}

<style lang="scss">
  .list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    width: 100%;
  }

  .file-info {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .content {
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
</style>
