<script>
  import ExpansionIcon from "components/icons/ExpansionIcon.svelte";
  import { Button } from "components";
  import styles from "styleguide/Toggler.json";

  export let hideText = "Hide";
  export let showText = "Show";

  $: collapsed = true;
  $: toggleButtonText = collapsed ? showText : hideText;

  function handleOnToggle() {
    collapsed = !collapsed;
  }
</script>

<div data-component="Toggler">
  <Button click={handleOnToggle} style="primary-text small">
    <span class={styles.toggler}>
      {toggleButtonText}
      <ExpansionIcon
        width={18}
        height={18}
        direction={collapsed ? "asc" : "desc"}
      />
    </span>
  </Button>

  {#if !collapsed}
    <div class={styles.content}>
      <slot name="content" />
    </div>
  {/if}
</div>

<style lang="scss">
  .toggler {
    display: inline-flex;
    align-items: center;
    gap: 4px;
  }

  .content {
    margin-top: 8px;
  }
</style>
