import ApiIcon from "components/icons/ApiIcon.svelte";
import ArrowLeftIcon from "components/icons/ArrowLeftIcon.svelte";
import BuildingIcon from "components/icons/BuildingIcon.svelte";
import CalendarIcon from "components/icons/CalendarIcon.svelte";
import ChartIcon from "components/icons/ChartIcon.svelte";
import ChatBubbleIcon from "components/icons/ChatBubbleIcon.svelte";
import CheckmarkFilledIcon from "components/icons/CheckmarkFilledIcon.svelte";
import CheckmarkIcon from "components/icons/CheckmarkIcon.svelte";
import CheckmarkMediumDoubleIcon from "components/icons/CheckmarkMediumDoubleIcon.svelte";
import CircleIcon from "components/icons/CircleIcon.svelte";
import ClipboardIcon from "components/icons/ClipboardIcon.svelte";
import ClockIcon from "components/icons/ClockIcon.svelte";
import CloseIcon from "components/icons/CloseIcon.svelte";
import ColorPaletteIcon from "components/icons/ColorPaletteIcon.svelte";
import CompliancePackageIcon from "components/icons/CompliancePackageIcon.svelte";
import ContactBookIcon from "components/icons/ContactBookIcon.svelte";
import CopyIcon from "components/icons/CopyIcon.svelte";
import CustomDevelopmentIcon from "components/icons/CustomDevelopmentIcon.svelte";
import CustomPasswordRuleIcon from "components/icons/CustomPasswordRuleIcon.svelte";
import CustomPBCTemplatesIcon from "components/icons/CustomPBCTemplatesIcon.svelte";
import DefaultSettingsIcon from "components/icons/DefaultSettingsIcon.svelte";
import DownloadIcon from "components/icons/DownloadIcon.svelte";
import EmailSignatureIcon from "components/icons/EmailSignatureIcon.svelte";
import EmployeeBadgeIcon from "components/icons/EmployeeBadgeIcon.svelte";
import ErrorFilledIcon from "components/icons/ErrorFilledIcon.svelte";
import ExclamationCircleIcon from "components/icons/ExclamationCircleIcon.svelte";
import ExpansionIcon from "components/icons/ExpansionIcon.svelte";
import ExportOutlineIcon from "components/icons/ExportOutlineIcon.svelte";
import ExtendedUserRolesIcon from "components/icons/ExtendedUserRolesIcon.svelte";
import EyeCrossedOutIcon from "components/icons/EyeCrossedOutIcon.svelte";
import EyeIcon from "components/icons/EyeIcon.svelte";
import ImportIcon from "components/icons/ImportIcon.svelte";
import InboxIcon from "components/icons/InboxIcon.svelte";
import InfoFilledIcon from "components/icons/InfoFilledIcon.svelte";
import InfoIcon from "components/icons/InfoIcon.svelte";
import InvoiceIcon from "components/icons/InvoiceIcon.svelte";
import IpAllowListIcon from "components/icons/IpAllowListIcon.svelte";
import LockIcon from "components/icons/LockIcon.svelte";
import LockWithStarsIcon from "components/icons/LockWithStarsIcon.svelte";
import MailIcon from "components/icons/MailIcon.svelte";
import MuchIcon from "components/icons/MuchIcon.svelte";
import OfficeIcon from "components/icons/OfficeIcon.svelte";
import PaperplaneIcon from "components/icons/PaperplaneIcon.svelte";
import PersonalIcon from "components/icons/PersonalIcon.svelte";
import PersonalizationIcon from "components/icons/PersonalizationIcon.svelte";
import PlayIcon from "components/icons/PlayIcon.svelte";
import ProjectAccessRulesIcon from "components/icons/ProjectAccessRulesIcon.svelte";
import RotateIcon from "components/icons/RotateIcon.svelte";
import RushIcon from "components/icons/RushIcon.svelte";
import SearchIcon from "components/icons/SearchIcon.svelte";
import SecurityIcon from "components/icons/SecurityIcon.svelte";
import SecurityQuestionnairesIcon from "components/icons/SecurityQuestionnairesIcon.svelte";
import ShieldIcon from "components/icons/ShieldIcon.svelte";
import SingleSignOnIcon from "components/icons/SingleSignOnIcon.svelte";
import SortArrowIcon from "components/icons/SortArrowIcon.svelte";
import SpinnerIcon from "components/icons/SpinnerIcon.svelte";
import StatisticIcon from "components/icons/StatisticIcon.svelte";
import SynchronizationIcon from "components/icons/SynchronizationIcon.svelte";
import TrashIcon from "components/icons/TrashIcon.svelte";
import TriangleRight from "components/icons/TriangleRight.svelte";
import TwoFactorAuthIcon from "components/icons/TwoFactorAuthIcon.svelte";
import TwoWayShareIcon from "components/icons/TwoWayShareIcon.svelte";
import UploadFilesIcon from "components/icons/UploadFilesIcon.svelte";
import UserIcon from "components/icons/UserIcon.svelte";
import UserLockIcon from "components/icons/UserLockIcon.svelte";
import UserMultiIcon from "components/icons/UserMultiIcon.svelte";
import VirusReportIcon from "components/icons/VirusReportIcon.svelte";
import WebIcon from "components/icons/WebIcon.svelte";
import WorkGroupIcon from "components/icons/WorkGroupIcon.svelte";

export const iconByKey = {
  // settings icons
  employeeBadge: EmployeeBadgeIcon,
  personalization: PersonalizationIcon,
  security: SecurityIcon,
  //feature icons
  api: ApiIcon,
  building: BuildingIcon,
  chart: ChartIcon,
  clipboard: ClipboardIcon,
  colorPalette: ColorPaletteIcon,
  compliancePackage: CompliancePackageIcon,
  defaultSettings: DefaultSettingsIcon,
  invoice: InvoiceIcon,
  personal: PersonalIcon,
  singleSignOn: SingleSignOnIcon,
  synchronization: SynchronizationIcon,
  userLock: UserLockIcon,
  userMulti: UserMultiIcon,
  //more from upsell modal
  customDevelopment: CustomDevelopmentIcon,
  customPasswordRule: CustomPasswordRuleIcon,
  customPBCTemplates: CustomPBCTemplatesIcon,
  emailSignature: EmailSignatureIcon,
  extendedUserRoles: ExtendedUserRolesIcon,
  ipAllowList: IpAllowListIcon,
  projectAccessRules: ProjectAccessRulesIcon,
  securityQuestionnaires: SecurityQuestionnairesIcon,
  twoFactorAuth: TwoFactorAuthIcon,
  //shared icon
  arrowLeft: ArrowLeftIcon,
  calendar: CalendarIcon,
  chatBubble: ChatBubbleIcon,
  checkmark: CheckmarkIcon,
  checkmarkFilled: CheckmarkFilledIcon,
  checkmarkMediumDouble: CheckmarkMediumDoubleIcon,
  circle: CircleIcon,
  clock: ClockIcon,
  close: CloseIcon,
  contactBook: ContactBookIcon,
  copy: CopyIcon,
  download: DownloadIcon,
  errorFilled: ErrorFilledIcon,
  exportOutline: ExportOutlineIcon,
  import: ImportIcon,
  inbox: InboxIcon,
  info: InfoIcon,
  infoFilled: InfoFilledIcon,
  mail: MailIcon,
  much: MuchIcon,
  office: OfficeIcon,
  paperplane: PaperplaneIcon,
  play: PlayIcon,
  rotate: RotateIcon,
  rush: RushIcon,
  sortArrow: SortArrowIcon,
  spinner: SpinnerIcon,
  statistic: StatisticIcon,
  trash: TrashIcon,
  triangleRight: TriangleRight,
  user: UserIcon,
  web: WebIcon,
  workGroup: WorkGroupIcon,
  expansion: ExpansionIcon,
  twoWayShare: TwoWayShareIcon,
  lock: LockIcon,
  shield: ShieldIcon,
  lockWithStars: LockWithStarsIcon,
  virusReport: VirusReportIcon,
  search: SearchIcon,
  uploadFiles: UploadFilesIcon,
  exclamationCircle: ExclamationCircleIcon,
  eye: EyeIcon,
  eyeCrossedOut: EyeCrossedOutIcon,
};
