<script>
  import Icons from "components/icons/Icons.svelte";
  import { t } from "stores/i18n.js";
  import { activeControl } from "stores/pbc.js";
  import { user } from "stores/user";

  import { Checkbox, Tooltip } from "components";
  import styles from "styleguide/UserSelectorItem.json";

  export let entity;
  export let onToggle;
  export let style = "";

  let entityItemTitle;
  let usersIconTooltip;

  $: calculatedStyles = function () {
    let s = style.split(" ");
    return s.map((x) => styles[x]).join(" ");
  };

  function onCheckboxToggle(checked) {
    onToggle?.(checked, entity);
  }

  function generateUsersTooltipTitle() {
    const maxItemsToShow = 10;
    const userCount = entity.client_users?.length || 0;
    const displayedUsers =
      entity.client_users?.slice(0, maxItemsToShow).map((user) => user.email) ||
      [];
    const remainingUsers = Math.max(userCount - maxItemsToShow, 0);

    usersIconTooltip = `
      <div>
        <span>${userCount} ${$t("user_selector_item.members")}</span>
        ${userCount > 0 ? `<div style="height: 1px; width: 100%; margin: 8px 0; background-color: #d3d9e3;"></div>` : ""}
        <ul>
          ${displayedUsers.map((email) => `<li>${email}</li>`).join("")}
        </ul>
        ${remainingUsers > 0 ? `<span>+ ${remainingUsers} ${$t("user_selector_item.more")}</span>` : ""}
      </div>
    `;
  }

  $: {
    entityItemTitle = !entity.client_users ? entity.email : entity.name;
    generateUsersTooltipTitle();
  }

  function disableUserCheckbox(userPermalink) {
    if (!$activeControl) return;
    if (userPermalink === $user.permalink) {
      return true;
    }

    const teams = $activeControl.teams || [];
    return teams.some((team) => team.client_user_ids.includes(userPermalink));
  }
</script>

<div
  data-component="UserSelectorItem"
  class="{styles.item} {calculatedStyles()}"
>
  <Checkbox
    disabled={disableUserCheckbox(entity.permalink)}
    toggled={entity.selected}
    toggle={onCheckboxToggle}
    width="100%"
  >
    <div class={styles["checkbox-slot"]}>
      <div class={styles["entity-item"]}>
        <Tooltip
          showArrow
          overflowingShow={true}
          nowrap={false}
          maxWidth="100%"
          contentWidth="100%"
        >
          <div class={styles.ellipsis}>
            {entityItemTitle}
          </div>

          <div slot="tooltip" class={styles.ellipsis}>
            {entityItemTitle}
          </div>
        </Tooltip>
      </div>

      {#if entity.client_users}
        <Tooltip showArrow message={usersIconTooltip}>
          <div class={styles["entity-icon"]}>
            <Icons
              icon="userMulti"
              width="16"
              height="16"
              fill={"var(--blue-200)"}
            />
          </div>
        </Tooltip>
      {/if}
    </div>
  </Checkbox>
</div>

<style scoped lang="scss">
  .item {
    cursor: pointer;
    padding: 0 24px;
    border-bottom: 1px solid var(--primary-050);
    user-select: none;
  }

  .item:last-child {
    border-bottom: none;
  }

  .item.embedded {
    padding: 0 14px;
  }

  .checkbox-slot {
    display: grid;
    grid-template-columns: minmax(0, 1fr) max-content;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 14px;
    height: 54px;
    width: 100%;
  }

  .entity-item {
    display: flex;
    overflow: hidden;
    gap: 10px;
  }
  .entity-icon {
    display: flex;
  }

  .ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
</style>
