<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  {width}
  {height}
  {fill}
  viewBox="0 0 18 18"
>
  <g fill="none" fill-rule="evenodd">
    <g fill="currentColor">
      <g>
        <g>
          <g>
            <path
              d="M14.246.75c1.035 0 1.875.84 1.875 1.875v9c0 1.035-.84 1.875-1.875 1.875H12.38v1.875c0 .986-.762 1.795-1.728 1.87l-.147.005h-6.75c-.986 0-1.794-.762-1.87-1.728l-.005-.147v-9c0-.986.762-1.794 1.729-1.87l.146-.005H5.62l.001-1.875c0-.986.762-1.794 1.729-1.87L7.496.75zM5.62 6H3.754c-.18 0-.332.129-.367.3l-.008.075v9c0 .181.129.332.3.367l.075.008h6.75c.181 0 .333-.129.368-.3l.007-.075V13.5H7.496c-.986 0-1.794-.762-1.87-1.728l-.005-.147V6zm8.626-3.75h-6.75c-.207 0-.375.168-.375.375v9c0 .181.129.332.3.367l.075.008h6.75c.207 0 .375-.168.375-.375v-9c0-.207-.168-.375-.375-.375z"
              transform="translate(-336.000000, -1104.000000) translate(324.000000, 1034.000000) translate(0.000000, 64.000000) translate(12.000000, 6.000000)"
            />
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
