<script>
  import { onMount } from "svelte";

  import {
    QuickshareSidebar,
    QuickshareContent,
  } from "apps/quickshare/components";
  import { quickshareUserService } from "apps/quickshare/services";
  import { App } from "components/shared";

  import { breadcrumb } from "stores/breadcrumb.js";
  import { updatePageHistory } from "stores/page_history.js";

  import { userPath } from "lib/helpers.js";

  import styles from "styleguide/Quickshare.json";

  export let appConfig;
  export let initialState;

  onMount(() => {
    quickshareUserService.setUserInfo(initialState);

    breadcrumb.set([{ translation: "quickshare" }]);
    updatePageHistory([
      {
        path: userPath + "/quickshare",
        titles: [{ translation: "quickshare" }, { translation: "overview" }],
      },
    ]);
  });
</script>

<App {...appConfig}>
  <div class={styles.wrapper}>
    <div class={styles.main}>
      <div class={styles.content}>
        <QuickshareContent />
      </div>

      <div class={styles.sidebar}>
        <QuickshareSidebar />
      </div>
    </div>
  </div>
</App>

<style lang="scss">
  .wrapper {
    padding: 40px 0;
  }

  .main {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 440px;
    gap: 32px;
    padding: 0 var(--page-gutter) 40px var(--page-gutter);
  }

  .content {
    flex: 1;
  }

  .sidebar {
    flex: 0 0 440px;
  }
</style>
