<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  {width}
  {height}
  viewBox="0 0 56 56"
  {fill}
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M41.4305 15.1264C41.0905 8.00488 35.2052 2.33148 27.9998 2.33148C20.576 2.33148 14.5535 8.35393 14.5535 15.7777V22.3328H12.4451C8.7018 22.3328 5.66602 25.3686 5.66602 29.1119V46.8888C5.66602 50.6321 8.70171 53.6648 12.4451 53.6648H43.5562C47.2996 53.6648 50.3322 50.6321 50.3322 46.8888V29.1119C50.3322 25.3685 47.2995 22.3328 43.5562 22.3328H41.446V15.7777L41.4305 15.1264ZM36.7793 22.3328V15.7777C36.7793 10.9313 32.8462 6.99815 27.9998 6.99815C23.3328 6.99815 19.5128 10.6453 19.2362 15.2431L19.2202 15.7777V22.3328H36.7793ZM16.8869 26.9995H12.4451C11.2791 26.9995 10.3327 27.9459 10.3327 29.1119V46.8888C10.3327 48.0537 11.278 48.9981 12.4451 48.9981H43.5562C44.7222 48.9981 45.6656 48.0548 45.6656 46.8888V29.1119C45.6656 27.9448 44.7211 26.9995 43.5562 26.9995H39.1126H16.8869Z"
  />
</svg>
