<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  {width}
  {height}
  {fill}
  viewBox="0 0 18 18"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M12.7432 1.39823C12.6935 1.03215 12.3797 0.75 12 0.75C11.5858 0.75 11.25 1.08579 11.25 1.5V2.25H6.75V1.5L6.74315 1.39823C6.69349 1.03215 6.3797 0.75 6 0.75C5.58579 0.75 5.25 1.08579 5.25 1.5V2.25H3.75C2.50752 2.25 1.5 3.25705 1.5 4.5V7.5V15C1.5 16.2422 2.50779 17.25 3.75 17.25H14.25C15.4922 17.25 16.5 16.2422 16.5 15V7.5V4.5C16.5 3.25705 15.4925 2.25 14.25 2.25H12.75V1.5L12.7432 1.39823ZM15 6.75V4.5C15 4.08564 14.6642 3.75 14.25 3.75H12.75V4.5C12.75 4.91421 12.4142 5.25 12 5.25C11.6203 5.25 11.3065 4.96785 11.2568 4.60177L11.25 4.5V3.75H6.75V4.5C6.75 4.91421 6.41421 5.25 6 5.25C5.6203 5.25 5.30651 4.96785 5.25685 4.60177L5.25 4.5V3.75H3.75C3.33579 3.75 3 4.08564 3 4.5V6.75H15ZM3 8.25H15V15C15 15.4138 14.6638 15.75 14.25 15.75H3.75C3.33621 15.75 3 15.4138 3 15V8.25Z"
    fill="currentColor"
  />
</svg>
