import { writable } from "svelte/store";

// FILES UPLOAD
// data model for uploadingFiles should be:
//
// interface File {
//   // Taken from uppy cache files
//   cacheId: string,
//   cacheKey: string,
//   name: string,
//   size: string,
//   type: string,
//   progress: {
//     uploadStarted: boolean,
//     uploadComplete: boolean,
//     percentage: number,
//   },
//   // Taken from backend response after adding a file to the quickshare link
//   permalink: string,
//   stored: boolean,
//   virus: boolean,
//   virus_report: {},
// }

export const uploadingFiles = writable([]);
