<script>
  import { onMount } from "svelte";

  import { QuickshareLinkEditForm } from "apps/quickshare/components";

  import {
    linkInitValue,
    LinkType,
    SidebarLinkTypeOptions,
  } from "apps/quickshare/constants";

  import {
    quickshareService,
    quickshareUploadService,
  } from "apps/quickshare/services";

  import {
    uploadingFiles,
    sidebarActiveLinkType,
    isSidebarScrollable,
  } from "apps/quickshare/stores";
  import { UploadingFilesVirusModal } from "components/modals";
  import { t } from "stores/i18n";
  import { copyToClickboard } from "lib/helpers";

  import { Boundary, Button, DropzoneLight } from "components";
  import { QuickshareFilesList } from "./components";
  import { getCompleteUploadProgress } from "./helpers";
  import { QuickshareLinkCreateStatus } from "../QuickshareLinkCreateStatus";

  import styles from "styleguide/QuickshareSendFiles.json";

  let isSudmitted = false;
  $: validationErrors = {};
  $: completeUploadProgress = getCompleteUploadProgress($uploadingFiles);

  $: isShowVirusModal = false;

  const linkInit = { ...linkInitValue, link_type: LinkType.SHARE };
  $: link = { ...linkInit };

  $: isActionDisabled =
    $uploadingFiles.filter(
      (file) => !file.progress.uploadComplete || !file.stored,
    ).length > 0;

  $: if (!$uploadingFiles.length) {
    sidebarActiveLinkType.set(null);
  }

  function onChangeValidators(errors) {
    validationErrors = { ...errors };
  }

  function onSaveLink() {
    isSudmitted = true;

    if (Object.keys(validationErrors).length) return;

    const isVirus = $uploadingFiles.some((item) => item.stored && item.virus);

    if (isVirus) {
      isShowVirusModal = true;
      return;
    }

    onSubmit();
  }

  function onSubmit() {
    isShowVirusModal = false;
    const params = { link };

    quickshareService.updateLinkDraft({
      params,
      onSuccess: (item) => {
        handleCopy(item.permalink);
        quickshareService.resetListFilters();
        quickshareService.getLinksList();
        onResetLink();
        window.scrollTo(0, 0);
      },
    });
  }

  function handleCopy(permalink) {
    const linkHref = window.location.origin + "/quickshare/" + permalink;
    const message = $t("quickshare_send_files.created_and_copied");
    copyToClickboard(linkHref, null, message);
  }

  function handleAddFiles(files) {
    sidebarActiveLinkType.set(SidebarLinkTypeOptions.SHARE);
    isSidebarScrollable.set(true);

    quickshareUploadService.uploadFilesToCache([...files]);

    if (!link.permalink) {
      setupLink();
    }
  }

  function setupLink() {
    const params = { link };

    quickshareService.createLink({
      params,
      onSuccess: (res) => {
        link.permalink = res.item.permalink;
        quickshareUploadService.setLinkPermalink(link.permalink);
      },
    });
  }

  function onResetLink() {
    sidebarActiveLinkType.set(null);
    link = { ...linkInit };
    quickshareUploadService.resetFiles();
  }

  onMount(() => {
    quickshareUploadService.setupUppy({ ...linkInit }, onFirstFileAdded);
  });

  function onFirstFileAdded(title) {
    link.title = title;
  }

  $: statusTitle =
    completeUploadProgress < 100
      ? $t("quickshare_send_files.uploading")
      : $t("quickshare_send_files.upload_success");

  $: statusInfo =
    completeUploadProgress < 100
      ? $t("quickshare_send_files.uploading_2")
      : $t("quickshare_send_files.upload_info");
</script>

<Boundary>
  <div
    data-component="QuickshareSendFiles"
    class={`${styles.container} ${$isSidebarScrollable ? styles.scrollable : ""}`}
  >
    <div class={styles.scroll}>
      {#if !!$uploadingFiles.length}
        <div>
          <Button style={"primary-text"} click={onResetLink}>
            {$t("quickshare_send_files.cancel")}
          </Button>
        </div>
      {/if}

      <DropzoneLight onAdd={handleAddFiles} />

      {#if !!$uploadingFiles.length}
        <div class={styles.info}>
          <div class={styles.item}>
            <QuickshareFilesList
              permalink={link.permalink}
              files={$uploadingFiles}
            />
          </div>

          <QuickshareLinkEditForm
            {link}
            {onChangeValidators}
            isSubmittedExternal={isSudmitted}
          />
        </div>
      {/if}
    </div>

    {#if !!$uploadingFiles.length}
      <QuickshareLinkCreateStatus
        title={statusTitle}
        info={statusInfo}
        {isActionDisabled}
        loadingPercentage={completeUploadProgress}
        onAction={onSaveLink}
      />
    {/if}

    <UploadingFilesVirusModal
      isShowModal={isShowVirusModal}
      onToggle={() => (isShowVirusModal = !isShowVirusModal)}
      {onSubmit}
    />
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    position: relative;

    &.scrollable {
      max-height: calc(100dvh - 260px);
    }
  }

  .scroll {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: -20px -20px 0;
    padding: 20px 20px 0;
    overflow: auto;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-bottom: 20px;
  }

  .item {
    border-bottom: 1px solid var(--primary-050);
  }
</style>
