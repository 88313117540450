<script>
  import UploadFilesIcon from "components/icons/UploadFilesIcon.svelte";
  import { t } from "stores/i18n";
  import { toasts } from "stores/toasts.js";

  import { isFileTypeAcceptable } from "lib/helpers.js";

  import styles from "styleguide/DropzoneLight.json";

  export let onAdd = () => {};
  export let disabled = false;

  $: isDragging = false;
  let fileInputRef;

  function handleDrag(e) {
    e.preventDefault();
    e.stopPropagation();

    if (e.type === "dragenter" || e.type === "dragover") {
      isDragging = true;
    } else if (e.type === "dragleave") {
      isDragging = false;
    }
  }

  function handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    isDragging = false;
    let files = e.dataTransfer?.files;

    if (!files) return;

    const filesList = getCheckedFiles(files);

    if (filesList.length > 0) {
      onAdd(filesList);
    }
  }

  function getCheckedFiles(files) {
    let _files = Array.from(files);
    let acceptableFiles = [];

    for (let index = 0; index < _files.length; index++) {
      const element = _files[index];

      if (!isFileTypeAcceptable(element.name)) {
        toasts.send({
          message: `${$t("errors.no_acceptable_file_type")}: ${element.name}`,
          type: "error",
        });
        continue;
      }

      acceptableFiles.push(element);
    }

    return acceptableFiles;
  }

  function handleChange(e) {
    let files = e.target?.files;

    if (!files) {
      return;
    }

    const filesList = getCheckedFiles(files);

    if (filesList.length) {
      onAdd(filesList);
    }

    if (fileInputRef) fileInputRef.value = "";
  }
</script>

<div
  data-component="DropzoneLight"
  on:dragenter={handleDrag}
  class={`${styles.wrapper} ${disabled ? styles.disabled : ""}`}
  role="button"
  tabindex="-1"
>
  <input
    id="file-input"
    bind:this={fileInputRef}
    class={styles.input}
    type="file"
    multiple
    on:change={handleChange}
  />

  <label
    for="file-input"
    class={`${styles.dropzone} ${isDragging ? styles.dragging : ""}`}
  >
    <div class={styles.content}>
      <slot>
        <UploadFilesIcon />

        <span class={styles.title}>
          <span>{$t("dropzone_light.dragdrop")}</span>
          <button
            class={styles["upload-btn"]}
            type="button"
            on:click={() => fileInputRef.click()}
          >
            {$t("dropzone_light.choose_file")}
          </button>
          <span>{$t("dropzone_light.to_upload")}</span>
        </span>

        <span class={styles.subtitle} data-type="text">
          {$t("dropzone_light.max_size")}
        </span>
      </slot>
    </div>
  </label>

  {#if isDragging}
    <div
      role="button"
      tabindex="-1"
      class={styles["drag-wrapper"]}
      on:dragenter={handleDrag}
      on:dragleave={handleDrag}
      on:dragover={handleDrag}
      on:drop={handleDrop}
    />
  {/if}
</div>

<style lang="scss">
  .wrapper {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;

    &.disabled {
      pointer-events: none;
      background: var(--primary-010);

      .title {
        color: var(--primary-300);
      }

      .upload-btn {
        color: var(--primary-300);
      }

      .subtitle {
        color: var(--primary-200);
      }
    }
  }

  .dropzone {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: center;
    height: 100%;

    &.dragging:after {
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      box-shadow: inset 0 0 10px 0 var(--blue-100);
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    gap: 4px;
    border: dashed 2px var(--primary-050);
    border-radius: var(--border-radius);
    padding: 20px 24px;
    cursor: pointer;
  }

  .title {
    @include typography-body-medium;

    color: var(--primary-500);
    text-align: center;
  }

  .upload-btn {
    all: unset;
    color: var(--blue-200);
  }

  .subtitle {
    @include typography-footnote-regular;

    color: var(--primary-300);
  }

  .input {
    display: none;
  }

  .drag-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
</style>
