<script>
  import clsx from "clsx";

  export let style = "";
  export let duration;
  export let startPercent = 3;
  export let label = "";

  import styles from "styleguide/ProgressBar.json";

  import { onMount } from "svelte";

  let interval;
  let percent = startPercent;

  function renderLine() {
    if (percent >= 100) {
      clearInterval(interval);
    } else {
      percent++;
    }
  }

  onMount(async () => {
    if (duration) {
      interval = setInterval(renderLine, duration / (100 - startPercent));
    }
  });
</script>

<div
  data-component="ProgressBar"
  class={clsx(
    styles.wrapper,
    style.split(" ").map((x) => styles[x]),
  )}
>
  <div class={styles.progress}>
    <div class={styles.value} style={`width: ${percent}%;`}></div>
  </div>

  {#if label}
    <div class={styles.label}>{label}</div>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .progress {
    width: 100%;
    height: 16px;
    border-radius: var(--border-radius);
    background-color: var(--primary-050);
  }

  .value {
    height: 16px;
    border-radius: var(--border-radius);
    background-color: var(--green);
    transition: width 1s;
  }

  .label {
    font-size: 14px;
    line-height: 20px;
    color: var(--primary-300);
  }

  // SIZE
  .small {
    .progress {
      height: 8px;
    }

    .value {
      height: 8px;
    }

    .label {
      font-size: 12px;
      line-height: 18px;
    }
  }

  // COLOR
  .info {
    .value {
      background-color: var(--primary-300);
    }
  }
</style>
