<script>
  import { t } from "stores/i18n.js";

  import { Box, Button } from "components";
  import styles from "styleguide/Accordion.json";

  export let isCollapsed = false;
  export let showCollapseButton = true;
  export let isDivider = true;

  export let collapseText = "accordion.collapse";
  export let expandText = "accordion.expand";

  function handleToggle() {
    isCollapsed = !isCollapsed;
  }
</script>

<div data-component="Accordion">
  <Box>
    <div class={styles.header}>
      <div class={styles.title}>
        <slot name="title" />
      </div>

      {#if showCollapseButton}
        <Button style={"primary-text small"} click={handleToggle}>
          {isCollapsed ? $t(expandText) : $t(collapseText)}
        </Button>
      {/if}
    </div>

    {#if !isCollapsed}
      <div class={isDivider ? styles.content : ""}>
        <slot name="content" />
      </div>
    {/if}
  </Box>
</div>

<style lang="scss">
  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    padding: 16px 16px 16px 24px;
  }

  .title {
    width: 100%;
  }

  .content {
    border-top: 1px solid var(--primary-050);
  }
</style>
