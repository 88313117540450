import { LinkType } from "./linkType.js";

export const linkInitValue = {
  title: "",
  password: "",
  message: "",
  permalink: null,
  expires_in: 7,
  email_verification: false,
  email_notification: false,
  personal_inbox: false,
  download_limit: true,
  has_password: false,
  reset_password: false,
  link_type: LinkType.SHARE,
};
