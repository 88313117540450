import { getLinkSessionsQuickshareFiles } from "apps/quickshare/helpers";
import { t } from "stores/i18n";
import { get } from "svelte/store";

import {
  QuickshareGroupOverview,
  QuickshareGroupReceivedFiles,
  QuickshareGroupLinkSettings,
} from "../components";

export const getSidebarTabs = (link) => [
  {
    name: get(t)("quickshare_sidebar_tabs.overview"),
    additionalInfo: "",
    component: QuickshareGroupOverview,
  },
  {
    name: get(t)("quickshare_sidebar_tabs.files"),
    additionalInfo: (
      getLinkSessionsQuickshareFiles(link)?.length || 0
    ).toString(),
    component: QuickshareGroupReceivedFiles,
  },
  {
    name: get(t)("quickshare_sidebar_tabs.link_settings"),
    additionalInfo: "",
    component: QuickshareGroupLinkSettings,
  },
];
