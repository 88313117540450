<script>
  import { getSidebarTabs } from "apps/quickshare/components/QuickshareGroups/helpers";
  import { Smalltabs, LegacyButton as Button } from "components";

  import styles from "styleguide/QuickshareGroupTabs.json";

  export let link;

  $: activeTab = 0;
  $: tabs = getSidebarTabs(link);

  function handleSwitchTab(tab) {
    activeTab = tab;
  }
</script>

<div data-component="QuickshareGroupTabs">
  <Smalltabs>
    {#each tabs as tab, idx}
      <Button
        style={"smalltab"}
        active={idx === activeTab}
        click={() => handleSwitchTab(idx)}
      >
        <span class={styles.text}>
          <span>{tab.name}</span>
          {#if tab.additionalInfo}
            <span class={styles.count}>({tab.additionalInfo})</span>
          {/if}
        </span>
      </Button>
    {/each}
  </Smalltabs>

  <div class={styles["tab-content"]}>
    <svelte:component this={tabs[activeTab].component} {link} />
  </div>
</div>

<style lang="scss">
  .text {
    @include typography-label-medium;

    padding: 0 10px;
  }

  .count {
    @include typography-label-regular;
    color: var(--primary-300);
  }
</style>
