<script>
  import { getContext } from "svelte";
  import { account } from "stores/account.js";
  import { changeLanguage } from "stores/i18n.js";
  import { t } from "stores/i18n.js";

  import { Button, Options } from "components";

  export let isSmall = false;
  let { language } = getContext("config");

  function onChangeLanguage(newLanguage) {
    changeLanguage(newLanguage);
    language = newLanguage;
  }
</script>

<Options
  style={`left main-nav ${isSmall ? "nowrap" : ""}`}
  title={language.toUpperCase()}
  arrow={false}
>
  {#each $account.languages as language}
    <Button
      style="blue-text option"
      click={onChangeLanguage.bind(this, language)}
      >{$t(`languages.${language}`)}</Button
    >
  {/each}
</Options>
