import { t } from "stores/i18n";
import { get } from "svelte/store";

import { LinkType, ListFilterNameOptions } from "../../../constants";

export const getFilterTypes = (type) => [
  {
    value: LinkType.SHARE,
    name: ListFilterNameOptions.LINK_TYPE,
    label: get(t)("quickshare_filter_types.sent"),
    checked: type === LinkType.SHARE,
  },
  {
    value: LinkType.REQUEST,
    name: ListFilterNameOptions.LINK_TYPE,
    label: get(t)("quickshare_filter_types.received"),
    checked: type === LinkType.REQUEST,
  },
  {
    value: LinkType.TWO_WAY,
    name: ListFilterNameOptions.LINK_TYPE,
    label: get(t)("quickshare_filter_types.two_way"),
    checked: type === LinkType.TWO_WAY,
  },
  {
    value: null,
    name: ListFilterNameOptions.LINK_TYPE,
    label: get(t)("quickshare_filter_types.all"),
    checked: !type,
  },
];
