<script>
  import { iconByKey } from "components/icons";
  export let icon;
  export let fill;
  export let width;
  export let height;
  export let stroke;
  export let strokeWidth;
</script>

<div
  data-component={`Icons-${icon}`}
  style="width:{width}px; height:{height}px;"
>
  <svelte:component
    this={iconByKey[icon]}
    {width}
    {height}
    {fill}
    {stroke}
    stroke-width={strokeWidth}
  />
</div>
