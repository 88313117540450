<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    d="M10.5,0.5 C11.74,0.5 12.75,1.51 12.75,2.75 L12.75,2.75 L12.75,3.5 L15.75,3.5 C16.164,3.5 16.5,3.836 16.5,4.25 C16.5,4.664 16.164,5 15.75,5 L15.75,5 L15,5 L15,14.75 C15,15.99 13.99,17 12.75,17 L12.75,17 L5.25,17 C4.01,17 3,15.99 3,14.75 L3,14.75 L3,5 L2.25,5 C1.836,5 1.5,4.664 1.5,4.25 C1.5,3.836 1.836,3.5 2.25,3.5 L2.25,3.5 L5.25,3.5 L5.25,2.75 C5.25,1.51 6.26,0.5 7.5,0.5 L7.5,0.5 Z M13.5,5 L4.5,5 L4.5,14.75 C4.5,15.163 4.837,15.5 5.25,15.5 L5.25,15.5 L12.75,15.5 C13.163,15.5 13.5,15.163 13.5,14.75 L13.5,14.75 L13.5,5 Z M7.5,7.25 C7.914,7.25 8.25,7.586 8.25,8 L8.25,8 L8.25,12.5 C8.25,12.914 7.914,13.25 7.5,13.25 C7.086,13.25 6.75,12.914 6.75,12.5 L6.75,12.5 L6.75,8 C6.75,7.586 7.086,7.25 7.5,7.25 Z M10.5,7.25 C10.914,7.25 11.25,7.586 11.25,8 L11.25,8 L11.25,12.5 C11.25,12.914 10.914,13.25 10.5,13.25 C10.086,13.25 9.75,12.914 9.75,12.5 L9.75,12.5 L9.75,8 C9.75,7.586 10.086,7.25 10.5,7.25 Z M10.5,2 L7.5,2 C7.087,2 6.75,2.337 6.75,2.75 L6.75,2.75 L6.75,3.5 L11.25,3.5 L11.25,2.75 C11.25,2.337 10.913,2 10.5,2 L10.5,2 Z"
  />
</svg>
