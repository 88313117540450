<script>
  import styles from "styleguide/Link.json";

  export let href;
  export let label = "";
  export let target = "";
</script>

<a class={styles.link} {href} {target}>
  {#if label}
    {label}
  {:else}
    <slot />
  {/if}
</a>

<style lang="scss">
  .link {
    text-decoration: none;
    color: var(--blue-200);
    cursor: pointer;
  }
</style>
