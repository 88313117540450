<script>
  import { link } from "apps/quickshareFrontend/stores";
  import { t } from "stores/i18n";

  import { Boundary } from "components";

  import styles from "styleguide/QuickshareLandingFooter.json";

  const { privacyLink, imprintLink } = $link;
</script>

<Boundary>
  <div data-component="QuickshareLandingFooter" class={styles.footer}>
    <a href={privacyLink} class={styles.link}>
      {$t("quickshare_frontend_footer.privacy")}
    </a>
    <a href={imprintLink} class={styles.link}>
      {$t("quickshare_frontend_footer.imprint")}
    </a>
  </div>
</Boundary>

<style lang="scss">
  .footer {
    @include typography-footnote-regular;

    display: flex;
    justify-content: center;
    gap: 24px;

    padding: 25px 35px;
    background: var(--primary-010);
  }

  .link {
    text-decoration: none;
    color: var(--primary-200);
  }
</style>
