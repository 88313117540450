<script>
  import {
    QuickshareLandingHeader,
    QuickshareLandingFooter,
  } from "apps/quickshareFrontend/components";

  import { getIsLinkExpired } from "apps/quickshareFrontend/helpers";

  import { link } from "apps/quickshareFrontend/stores";
  import { t } from "stores/i18n";

  import { Boundary } from "components";

  import styles from "styleguide/QuickshareLandingWrapper.json";

  const { expiredDate } = $link;

  $: isLinkExpired = getIsLinkExpired(expiredDate);
</script>

<Boundary>
  <div data-component="QuickshareLandingWrapper" class={styles.wrapper}>
    <QuickshareLandingHeader />
    {#if !isLinkExpired}
      <div class={styles["content"]}>
        <slot />
      </div>
      <QuickshareLandingFooter />
    {:else}
      <div>
        {$t("quickshare_frontend_landing_wrapper.link_expired")}
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }

  .content {
    flex: 1;
    background: var(--primary-010);
  }
</style>
