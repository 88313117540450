<script>
  import { getLinkSessionsQuickshareFiles } from "apps/quickshare/helpers";
  import { quickshareService } from "apps/quickshare/services";

  import { DownloadFilesVirusModal } from "components/modals";
  import { t } from "stores/i18n";

  import { Boundary, Button } from "components";

  import { QuickshareGroupFilesTable } from "./components";

  import styles from "styleguide/QuickshareGroupReceivedFiles.json";

  export let link;

  $: files = getLinkSessionsQuickshareFiles(link);
  $: isVirus = files.some((file) => file.virus);
  $: isAllInfected = files.every((file) => file.virus);
  let isShowVirusModal = false;
  let isActionsDisabled = false;
  let isDownloading = false;

  // maybe it is easier to add an additional endpoint for just getting the files. This seems to complex for what it is.

  function handleDownloadFiles() {
    if (isVirus) {
      isShowVirusModal = true;
    } else {
      submitDownloadFiles();
    }
  }

  function submitDownloadFiles(includeInfected = true) {
    isDownloading = true;
    isShowVirusModal = false;

    const { permalink } = link;
    const params = {
      permalink,
      excludeSuspicious: !includeInfected,
    };

    quickshareService.downloadFiles({
      params,
      onSuccess: () => {
        const updateData = { permalink, isAllDownload: true };
        quickshareService.updateFilesDownloadStatus(updateData);
        isDownloading = false;
      },
      onError: () => (isDownloading = false),
    });
  }

  function handleRemoveFiles() {
    isActionsDisabled = true;
    const { permalink } = link;
    const params = { permalink };

    quickshareService.deleteFiles({
      params,
      onSuccess: () => (isActionsDisabled = false),
      onError: () => (isActionsDisabled = false),
    });
  }
</script>

<Boundary>
  <div data-component="QuickshareGroupReceivedFiles">
    {#if files?.length}
      <div class={styles["content"]}>
        <QuickshareGroupFilesTable
          rows={files}
          linkPermalink={link.permalink}
        />
      </div>

      <div class={styles["footer"]}>
        <Button
          style={"primary-tonal"}
          click={handleDownloadFiles}
          disabled={isActionsDisabled || isDownloading}
        >
          {isDownloading
            ? $t("quickshare_received_files.downloading")
            : $t("quickshare_received_files.download_files")}
        </Button>
        <Button
          style={"error-text"}
          click={handleRemoveFiles}
          disabled={isActionsDisabled}
        >
          {$t("quickshare_received_files.remove_files")}
        </Button>
      </div>
      <DownloadFilesVirusModal
        isShowModal={isShowVirusModal}
        onHide={() => (isShowVirusModal = false)}
        {isAllInfected}
        onSubmit={submitDownloadFiles}
      />
    {:else}
      <div class={styles.empty}>
        {$t("quickshare_received_files.no_files")}
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .content {
    max-height: 275px;
    overflow: auto;
  }

  .footer {
    display: flex;
    gap: 8px;
    border-top: 1px solid var(--primary-050);
    padding: 12px 24px;
  }

  .empty {
    @include typography-body-regular;

    display: flex;
    justify-content: center;
    font-style: italic;
    padding: 24px;
    color: var(--primary-300);
    text-align: center;
  }
</style>
