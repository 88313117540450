<script>
  export const directions = ["none", "", "desc", "", "asc"];
  export let direction = "none";
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";

  $: rotation = directions.indexOf(direction) * 90;
</script>

<svg
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  style={`transform: rotate(${rotation}deg)`}
  xmlns="http://www.w3.org/2000/svg"
>
  <g
    id="DESIGN:-Forms"
    stroke="none"
    stroke-width="1"
    fill="none"
    fill-rule="evenodd"
  >
    <g
      id="Forms"
      transform="translate(-671.000000, -858.000000)"
      fill="currentColor"
    >
      <g id="Group-10" transform="translate(439.000000, 783.000000)">
        <g id="input/Form/item" transform="translate(0.000000, 38.000000)">
          <g
            id="actions/dropdown/closed"
            transform="translate(113.000000, 27.000000)"
          >
            <g id="Group" transform="translate(12.000000, 8.000000)">
              <g
                id="icon/controls/chevron/stroke-1,5.down"
                transform="translate(107.000000, 2.000000)"
              >
                <path
                  d="M8.99975,11.99975 C8.80775,11.99975 8.61575,11.92675 8.46975,11.77975 L4.46975,7.77975 C4.17675,7.48675 4.17675,7.01275 4.46975,6.71975 C4.76275,6.42675 5.23675,6.42675 5.52975,6.71975 L8.99975,10.18875 L12.46975,6.71975 C12.76275,6.42675 13.23675,6.42675 13.52975,6.71975 C13.82275,7.01275 13.82275,7.48675 13.52975,7.77975 L9.52975,11.77975 C9.38375,11.92675 9.19175,11.99975 8.99975,11.99975"
                  id="icon-color"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </g>
</svg>
