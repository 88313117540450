export const getLinkSessionsQuickshareFiles = (link) => {
  if (!link || !Array.isArray(link.quickshare_sessions)) return [];

  return link.quickshare_sessions.flatMap((session) =>
    session.item.flatMap((item) =>
      item.quickshare_files.map((file) => ({
        ...file,
        internal: item.internal,
      })),
    ),
  );
};
