<script>
  import { t } from "stores/i18n";

  import { Boundary } from "components";
  import { QuickshareLandingStep } from "../QuickshareLandingStep";

  export let isActiveState = false;
  export let verificationEmail = "";
</script>

<Boundary>
  <div data-component="QuickshareLandingStepWaiting">
    <QuickshareLandingStep
      step={3}
      title={$t("quickshare_frontend_landing_step_waiting.waiting")}
      {isActiveState}
      desc={`
      <div>${$t("quickshare_frontend_landing_step_waiting.desc_1")} <b>${verificationEmail}</b></div>
      <div>${$t("quickshare_frontend_landing_step_waiting.desc_2")}</div>
      <div>${$t("quickshare_frontend_landing_step_waiting.wrong_email")}</div>
    `}
    />
  </div>
</Boundary>
