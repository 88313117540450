<script>
  import { Accordion, Boundary } from "components";

  import { QuickshareGroupTabs, QuickshareGroupTitle } from "./components";

  export let isCollapsed;
  export let quickshareLink;
</script>

<Boundary>
  <div data-component="QuickshareGroup">
    <Accordion {isCollapsed} isDivider={false}>
      <QuickshareGroupTitle link={quickshareLink} slot="title" />
      <QuickshareGroupTabs link={quickshareLink} slot="content" />
    </Accordion>
  </div>
</Boundary>
