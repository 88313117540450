<script>
  import { quickshareFrontendUploadService } from "apps/quickshareFrontend/services";

  import { isVerificationEmailSent } from "apps/quickshareFrontend/stores";
  import { t } from "stores/i18n.js";

  import { Boundary, Button } from "components";

  import { QuickshareLandingUploadFilesItem } from "../QuickshareLandingUploadFilesItem";

  import styles from "styleguide/QuickshareLandingUploadFiles.json";

  export let files;

  $: isRemoveAllDisabled =
    $isVerificationEmailSent || files.some((file) => !file.permalink);

  function handleRemoveAll() {
    quickshareFrontendUploadService.removeAllFiles();
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingUploadFiles">
    <div class={styles.head}>
      <div class={styles.title}>
        {$t("quickshare_frontend_landing_files_list.uploaded_files", {
          postProcess: "interval",
          count: files?.length,
        })}
      </div>

      {#if files.length}
        <Button
          click={handleRemoveAll}
          style={"error-text small"}
          disabled={isRemoveAllDisabled}
        >
          {$t("quickshare_frontend_landing_files_list.delete_all_files")}
        </Button>
      {/if}
    </div>

    <div class={styles.list}>
      {#each files as file (file.name)}
        <QuickshareLandingUploadFilesItem {file} />
      {/each}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .list {
    border-top: 1px solid var(--primary-050);
    max-height: 210px;
    overflow-y: auto;
    color: var(--primary-500);
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .title {
    @include typography-body-medium;
  }
</style>
