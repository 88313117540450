import { t } from "stores/i18n";
import { get } from "svelte/store";

export const getTableHeaders = () => {
  return [
    {
      label: get(t)("quickshare_group_table.file"),
      propName: "name",
      width: 35,
    },
    {
      label: get(t)("quickshare_group_table.upload_date"),
      propName: "created_at",
      width: 20,
    },
    {
      label: get(t)("quickshare_group_table.uploaded_by"),
      propName: "owner",
      width: 25,
    },
    {
      label: get(t)("quickshare_group_table.status"),
      propName: "downloaded",
      width: 20,
    },
  ];
};
