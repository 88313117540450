<script>
  import { currentLanguage, t } from "stores/i18n";
  import { dateTimeFormat } from "lib/helpers.js";

  import { Boundary, PulseDot } from "components";

  import { QuickshareGroupFiles } from "./components";

  import styles from "styleguide/QuickshareGroupOverview.json";

  export let link;

  const getIsActiveItem = (item) => {
    if (item.internal) return false;

    return item.quickshare_files.some((file) => !file.downloaded);
  };
</script>

<Boundary>
  <div data-component="QuickshareGroupOverview">
    <div class={styles.list}>
      {#each link.quickshare_sessions as quickshareSession, session_idx}
        <div class={styles.item}>
          <div class={styles["item-date"]}>
            {quickshareSession.date}
          </div>
          <div class={styles.activity}>
            {#each quickshareSession.item as activity, activity_idx}
              <div class={styles.log}>
                <div class={styles.time}>
                  {#if activity.log != null}
                    {dateTimeFormat(
                      activity?.log?.created_at,
                      $currentLanguage,
                    ).split(", ")[1]}
                  {/if}
                </div>

                <div class={styles.dot}>
                  <PulseDot isActive={getIsActiveItem(activity)} />
                </div>

                <div class={styles["log-content"]}>
                  <div class={styles["log-title"]}>
                    {activity?.log?.user}
                  </div>
                  <div class={styles["log-subtitle"]}>
                    {$t("quickshare_overview.log_" + activity?.log?.action)}
                  </div>

                  <QuickshareGroupFiles
                    files={activity?.quickshare_files}
                    sessionDate={quickshareSession.date}
                    sessionPermalink={activity?.permalink}
                    isInternal={activity?.internal}
                    linkPermalink={link.permalink}
                  />
                </div>
              </div>
            {/each}
          </div>
        </div>
      {/each}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .list {
    overflow: auto;
    padding: 16px 24px 24px;
  }

  .item:not(:first-of-type) {
    padding-top: 16px;
  }

  .item-date {
    @include typography-body-medium;

    margin-bottom: 16px;
  }

  .log {
    display: flex;
    align-items: stretch;

    &:not(:last-of-type) {
      padding-bottom: 16px;
    }

    &:last-of-type {
      .dot:before {
        display: none;
      }
    }
  }

  .time {
    @include typography-footnote-regular;

    margin-right: 10px;
    color: var(--primary-300);
    width: 40px;
    text-align: right;
    padding-top: 2px;
  }

  .dot {
    position: relative;
    width: 22px;
    padding: 6px;

    &:before {
      content: "";
      position: absolute;
      top: 6px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      height: calc(100% + 16px);
      border-right: 1px solid var(--blue-050);
    }
  }

  .log-content {
    flex: 1;
    margin-left: 16px;
  }

  .log-title {
    @include typography-body-semibold;
  }

  .log-subtitle {
    @include typography-body-regular;

    color: var(--primary-500);
  }
</style>
