<script>
  import { quickshareLinks } from "apps/quickshare/stores";
  import { t } from "stores/i18n";

  import { Boundary, Toast } from "components";
  import { QuickshareGroup } from "./components";

  import styles from "styleguide/QuickshareGroups.json";

  export let isCollapsed;
</script>

<Boundary>
  <div data-component="QuickshareGroups" class={styles.groups}>
    {#if $quickshareLinks && !$quickshareLinks?.items?.length}
      <Toast type="info" title={$t("quickshare_links_list.empty_list")} />
    {:else}
      {#each $quickshareLinks?.items || [] as quickshareLink (quickshareLink.digest)}
        <QuickshareGroup {quickshareLink} {isCollapsed}></QuickshareGroup>
      {/each}
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .groups {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
</style>
