<script>
  import { t } from "stores/i18n";

  import { Boundary, Button } from "components";

  import { QuickshareLandingStep } from "../QuickshareLandingStep";

  export let isActiveState = false;

  function redirectToInit() {
    const currentUrl = window.location.href;

    //redirect to the quickshare link initial url
    window.location.href = currentUrl.substring(0, currentUrl.lastIndexOf("/"));
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingStepCompleted">
    <QuickshareLandingStep
      step={4}
      title={$t("quickshare_frontend_step_completed.completed")}
      {isActiveState}
      desc={$t("quickshare_frontend_step_completed.desc")}
    >
      <div slot="content">
        <Button style={"primary"} click={redirectToInit}>
          {$t("quickshare_frontend_step_completed.send_more_files")}
        </Button>
      </div>
    </QuickshareLandingStep>
  </div>
</Boundary>
