import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";

import { quickshareFrontendApi } from "apps/quickshareFrontend/apis";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

import { uploadingFiles, link, linkPassword } from "../stores";

class QuickshareFrontendUploadService {
  constructor() {}

  uploadFilesToCache(files) {
    files.forEach((file) => {
      try {
        this.uppy.addFile({
          source: "file input",
          name: file.name,
          type: file.type,
          data: file,
        });
      } catch (error) {
        console.error(error);
        if (error.isRestriction) {
          toasts.send({
            message: error.message,
            type: "error",
          });
        } else {
          toasts.send({
            message: get(t)("errors.unknown"),
            type: "error",
          });
        }
      }
    });
  }

  setupUppy() {
    this.uppy = this.initializeUppy();

    this.setAws();
    this.initializeUppyEvents();
  }

  closeUppy() {
    this.uppy.reset();
    this.uppy.close();
  }

  setAws() {
    this.uppy.use(AwsS3, {
      limit: 5,
      timeout: 30 * 1000,
      companionUrl: "/minio",
    });
  }

  initializeUppy() {
    return new Uppy({
      debug: true,
      autoProceed: true,
      restrictions: {
        maxFileSize: 2000 * 1024 * 1024, // 2000 MB
        maxNumberOfFiles: 500,
        minNumberOfFiles: 1,
      },
    });
  }

  initializeUppyEvents() {
    this.uppy.on("file-added", () => {
      this.setUploadingFiles();
    });

    this.uppy.on("upload-progress", () => {
      this.setUploadingFiles();
    });

    this.uppy.on("upload-success", () => {
      this.setUploadingFiles();
    });

    this.uppy.on("complete", () => {
      this.setUploadingFiles();
      void this.addLinkFiles();
    });
  }

  setUploadingFiles() {
    const files = this.uppy.getFiles().map((file) => {
      const storeFile = get(uploadingFiles).find(
        (storeFile) => storeFile.name === file.name,
      );

      const {
        id,
        name,
        meta,
        size,
        type,
        progress: { uploadStarted, uploadComplete, percentage },
      } = file;

      const progress = {
        uploadStarted,
        uploadComplete,
        percentage,
      };

      return {
        progress,
        name,
        size,
        type,
        cacheId: id,
        cacheKey: meta.key,
        permalink: storeFile?.permalink ?? "",
        stored: storeFile?.stored ?? false,
        virus: storeFile?.virus ?? false,
        virus_report: storeFile?.virus_report ?? {},
      };
    });
    uploadingFiles.set(files);
  }

  removeFile({ params, onSuccess, onError } = {}) {
    const { cacheId, id } = params;

    if (!id) {
      this.uppy.removeFile(cacheId);
      return;
    }

    const { permalink, sessionId } = get(link);

    const reqParams = {
      ids: [id],
      permalink,
      sessionId,
    };

    this.removeSingleLinkFile({
      params: reqParams,
      onSuccess: () => {
        this.uppy.removeFile(cacheId);
        this.setUploadingFiles();
        onSuccess?.();
      },
      onError,
    });
  }

  removeAllFiles({ onSuccess, onError } = {}) {
    const { permalink, sessionId } = get(link);
    const ids = get(uploadingFiles).map((file) => file.permalink);

    const params = {
      permalink,
      ids,
      sessionId,
    };

    this.removeAllLinkFiles({ params, onSuccess, onError });
    this.removeAllUppyFiles();
  }

  removeAllUppyFiles() {
    this.uppy.getFiles().forEach((file) => {
      this.uppy.removeFile(file.id);
    });
    this.setUploadingFiles();
  }

  removeAllLinkFiles({ params, onSuccess, onError }) {
    quickshareFrontendApi.deleteFiles({
      params,
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  removeSingleLinkFile({ params, onSuccess, onError }) {
    quickshareFrontendApi.deleteFiles({
      params,
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  async addLinkFiles() {
    const files = get(uploadingFiles).filter((file) => !file.permalink);

    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const params = {
          id: get(link).permalink,
          file,
        };
        this.addLinkFile({
          params,
          onSuccess: (res) => resolve(res),
          onError: (err) => reject(err),
        });
      });
    });

    const result = await Promise.allSettled(promises);

    const errorFiles = files.filter((_, i) => result[i].status === "rejected");
    errorFiles.forEach((file) => this.uppy.removeFile(file.cacheId));

    const uploadingFilesData = get(uploadingFiles).filter(
      (file) =>
        !errorFiles.some((errorFile) => errorFile.cacheId === file.cacheId),
    );
    uploadingFiles.set(uploadingFilesData);

    const statusCheckingFilesData = get(uploadingFiles).filter((item) =>
      files.find((file) => file.cacheId === item.cacheId),
    );
    this.checkFilesStatus(statusCheckingFilesData);
  }

  addLinkFile({ params, onSuccess, onError } = {}) {
    const { id, file } = params || {};

    const { sessionId } = get(link);

    const file_meta = {
      id: file.cacheKey.match(/^cache\/(.+)/)[1], // remove the Shrine storage prefix
      storage: "pbc_cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      },
    };

    const password = get(linkPassword);

    const payload = {
      id,
      file_meta,
      session_id: sessionId,
      ...(password && { password }),
    };

    quickshareFrontendApi.addLinkFile({
      params: payload,
      success: (res) => {
        uploadingFiles.update((prev) =>
          prev.map((item) =>
            item.name === file.name
              ? {
                  ...item,
                  permalink: res.item.permalink,
                  stored: res.item.stored,
                  virus: res.item.virus,
                  virus_report: res.item.virus_report,
                }
              : item,
          ),
        );
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  checkFilesStatus(uploadingFilesData) {
    let time = 500;

    const requestAction = () => {
      const processingFiles = get(uploadingFiles).filter((file) =>
        uploadingFilesData.some(
          (uploadingFile) =>
            uploadingFile.permalink === file.permalink && !file.stored,
        ),
      );

      if (processingFiles.length) {
        const params = {
          id: get(link).permalink,
          ids: processingFiles.map((file) => file.permalink),
        };
        this.getLinkFilesStatus({ params });
        time *= 2;
        setTimeout(requestAction, time);
      }
    };

    setTimeout(requestAction, time);
  }

  getLinkFilesStatus({ params, onSuccess, onError }) {
    const password = get(linkPassword);
    const payload = {
      ...params,
      ...(password && { password }),
    };

    quickshareFrontendApi.getLinkFilesStatus({
      params: payload,
      success: (res) => {
        const resData = res.data?.items;

        uploadingFiles.update((prev) =>
          prev.map((prevFile) => {
            const fileData = resData?.find(
              (item) => item.permalink === prevFile.permalink,
            );

            return fileData
              ? {
                  ...prevFile,
                  stored: fileData.stored,
                  virus: fileData.virus,
                  virus_report: fileData.virus_report,
                }
              : prevFile;
          }),
        );
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  static instance;

  static getInstance() {
    if (!QuickshareFrontendUploadService.instance) {
      QuickshareFrontendUploadService.instance =
        new QuickshareFrontendUploadService();
    }
    return QuickshareFrontendUploadService.instance;
  }
}

export const quickshareFrontendUploadService =
  QuickshareFrontendUploadService.getInstance();
