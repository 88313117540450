<script>
  import clsx from "clsx";

  import Icons from "components/icons/Icons.svelte";
  import { InputWrapper } from "components";

  import styles from "styleguide/PasswordInput.json";

  export let label = "";
  export let name;
  export let value = "";
  export let placeholder;
  export let disabled = false;
  export let optional;
  export let footnotes;
  export let errors = [];
  export let invalid = false;
  export let onInput = () => {};
  export let onEnter = () => {};
  export let onChange = () => {};
  export let onFocus = () => {};
  export let onBlur = () => {};
  export let onMousedown = () => {};
  export let isShowPassword = false;
  export let isToggleValueDisabled = false;
  export let isMockedPlaceholder = false;

  let inputRef;

  $: inputRef, setType(isShowPassword);

  $: icon = isShowPassword ? "eyeCrossedOut" : "eye";
  $: _invalid = invalid || errors.length > 0;
  $: isEyeIcon = !!value && !isMockedPlaceholder && !isToggleValueDisabled;

  function handleToggle() {
    isShowPassword = !isShowPassword;
    setType();
  }

  function setType(isShow) {
    if (inputRef) {
      inputRef.type = isShow ? "text" : "password";
    }
  }

  function handleKeyDown(event) {
    if (event.key === "Enter") {
      onEnter(event);
    }
  }
</script>

<div data-component="PasswordInput" class={styles.wrapper}>
  <InputWrapper {label} {optional} {footnotes} {errors}>
    <span class={styles.container}>
      {#if isMockedPlaceholder}
        <input value="......." type="password" disabled={true} />
      {:else}
        <input
          class={clsx(
            { [styles["invalid"]]: _invalid },
            { [styles["with-icon"]]: isEyeIcon },
          )}
          bind:this={inputRef}
          on:input={onInput}
          on:change={onChange}
          on:focus={onFocus}
          on:blur={onBlur}
          on:mousedown={onMousedown}
          on:keydown={handleKeyDown}
          bind:value
          {name}
          {disabled}
          {placeholder}
          autocomplete="off"
        />
      {/if}

      {#if isEyeIcon}
        <button class={styles.eye} on:click={handleToggle}>
          <Icons
            {icon}
            width="18"
            height="18"
            fill={_invalid ? "var(--red-100)" : "var(--blue-100)"}
          />
        </button>
      {/if}
    </span>
  </InputWrapper>
</div>

<style lang="scss">
  .wrapper {
    text-align: left;
  }

  .container {
    display: flex;
    position: relative;
    flex: 1;
  }

  input {
    width: 100%;
    text-align: left;
    background: var(--white);
    color: var(--primary-500);
    border-radius: var(--border-radius);
    border: solid 1px var(--primary-050);
    outline: none;
    padding: 0px 10px;
    font-size: 14px;
    height: 40px;
    line-height: 1.5;

    &.with-icon {
      padding: 0px 40px 0 10px;
    }

    &:disabled {
      background: var(--primary-010);
    }

    &.invalid {
      border: solid 1px var(--red-200);
    }

    &:hover,
    &:focus,
    &:active {
      &:not(:disabled):not(.invalid) {
        border: solid 1px var(--blue);
      }
    }

    &::placeholder {
      color: var(--primary-060);
    }

    &:focus::placeholder {
      color: var(--primary-040);
    }
  }

  .eye {
    all: unset;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translateY(-50%);
    padding: 9px 8px;
    cursor: pointer;
  }
</style>
