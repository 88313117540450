<script>
  import { quickshareFrontendService } from "apps/quickshareFrontend/services";
  import { link } from "apps/quickshareFrontend/stores";
  import Icons from "components/icons/Icons.svelte";

  import { DownloadFilesVirusModal } from "components/modals";
  import { t } from "stores/i18n.js";

  import { fileIcon, humanFileSizeFromMB } from "lib/helpers";

  import { Boundary, Button, Tooltip } from "components";

  import styles from "styleguide/QuickshareLandingFilesList.json";

  const { downloadLimit } = $link;

  $: files = $link.files;
  $: virusModalFileId = "";

  function handleDownloadFile(fileId, isVirus) {
    if (isVirus) {
      virusModalFileId = fileId;
    } else {
      submitDownloadFile(fileId);
    }
  }

  function submitDownloadFile(fileId) {
    virusModalFileId = "";
    const params = { fileId };
    quickshareFrontendService.downloadSingleFile({ params });
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingFilesList" class={styles.container}>
    {#each files as item}
      <div class={styles.item}>
        <div class={styles.icon}>
          {#if item.virus}
            <Tooltip
              message={$t("quickshare_virus_tooltips.detected")}
              showArrow={true}
            >
              <Icons
                icon="errorFilled"
                width="22"
                height="22"
                fill="var(--red-100)"
              />
            </Tooltip>
          {:else}
            <img src={fileIcon(item.name)} alt="" />
          {/if}
        </div>

        {#if downloadLimit}
          <div class={styles["item-info"]}>
            <span class={styles["item-title"]}>{item.name}</span>
            <span class={styles["item-size"]}
              >({humanFileSizeFromMB(item.size)})</span
            >
          </div>
        {:else}
          <div>
            <Button
              style={"primary-text small"}
              click={() => handleDownloadFile(item.permalink, item.virus)}
            >
              <span class={styles["item-title"]}>{item.name}</span>
              <span class={styles["item-size"]}
                >({humanFileSizeFromMB(item.size)})</span
              >
            </Button>
          </div>
        {/if}

        {#if item.virus}
          <Tooltip
            message={$t("quickshare_virus_tooltips.report")}
            showArrow={true}
          >
            <Button
              style={"primary-tonal icon small"}
              click={() => window.open(item.virus_report.url, "_blank")}
            >
              <Icons
                icon="virusReport"
                fill="var(--blue-100)"
                width={18}
                height={18}
              />
            </Button>
          </Tooltip>
        {/if}
      </div>

      <DownloadFilesVirusModal
        isShowModal={virusModalFileId === item.permalink}
        onHide={() => (virusModalFileId = "")}
        isAllInfected={true}
        isSingleFile={true}
        onSubmit={() => submitDownloadFile(item.permalink)}
      />
    {/each}
  </div>
</Boundary>

<style lang="scss">
  .container {
    padding: 8px 0;
  }

  .item {
    box-sizing: border-box;
    display: grid;
    gap: 8px;
    align-items: center;
    grid-template-columns: max-content minmax(0, 1fr) max-content;
    width: 100%;
    padding: 0 20px;
    height: 48px;

    & + & {
      border-top: 1px solid var(--primary-010);
    }
  }

  .item-info {
    @include typography-body-regular;

    display: flex;
    gap: 5px;
    align-items: center;
    color: var(--primary-500);
  }

  .item-size {
    @include typography-footnote-regular;

    color: var(--primary-050);
    flex-shrink: 0;
  }

  .item-title {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .info {
    text-decoration: none;
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
  }

  .footer {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px 24px;
    border-top: 1px solid var(--primary-050);
  }
</style>
