<script>
  import { onMount } from "svelte";

  import { quickshareFrontendService } from "apps/quickshareFrontend/services/index.js";
  import { link } from "apps/quickshareFrontend/stores";

  import { Accordion, Boundary } from "components";
  import {
    QuickshareLandingFilesList,
    QuickshareLandingFilesTitle,
  } from "./components";

  $: isFilesLoading = true;

  $: isCollapsed =
    (!isShare && !isTwoWay) ||
    isFilesLoading ||
    (!files.length && !isFilesLoading);

  const { isShare, isTwoWay } = $link;

  $: files = $link.files;

  $: showCollapseButton = isTwoWay && files.length && !isFilesLoading;

  onMount(() => {
    quickshareFrontendService.getFiles({
      onSuccess: () => (isFilesLoading = false),
      onError: () => (isFilesLoading = false),
    });
  });
</script>

<Boundary>
  <div data-component="QuickshareLandingFiles">
    <Accordion {isCollapsed} {showCollapseButton}>
      <div slot="title">
        <QuickshareLandingFilesTitle {isFilesLoading} />
      </div>

      <div slot="content">
        <QuickshareLandingFilesList />
      </div>
    </Accordion>
  </div>
</Boundary>
