<script>
  import { onDestroy } from "svelte";
  import { quickshareFrontendService } from "apps/quickshareFrontend/services";
  import {
    isVerificationEmailSent,
    uploadingFiles,
  } from "apps/quickshareFrontend/stores";
  import { UploadingFilesVirusModal } from "components/modals";
  import { t } from "stores/i18n";

  import { validate } from "lib/validate.js";

  import { Boundary, Button, TextInput } from "components";

  import { QuickshareLandingStep } from "../QuickshareLandingStep";

  import styles from "styleguide/QuickshareLandingStepVerifyEmail.json";

  export let isActiveState = false;
  export let verificationEmail = "";
  export let setVerificationEmail = () => {};

  let startTime;
  $: elapsedTime = 0;
  $: isShowVirusModal = false;
  $: isEmailInvalid = false;

  let email = "";
  let interval;

  function sendVerification() {
    if (isEmailInvalid) {
      return;
    }

    const isVirus = $uploadingFiles.some((item) => item.stored && item.virus);

    if (isVirus) {
      isShowVirusModal = true;
      return;
    }

    onSubmit();
  }

  function onSubmit() {
    isShowVirusModal = false;
    isVerificationEmailSent.set(true);

    const params = { email };
    quickshareFrontendService.confirmFilesAttachment({
      params,
      onSuccess: () => {
        setVerificationEmail(email);
        startTimer();
      },
    });
  }

  function startTimer() {
    startTime = new Date().getTime();
    interval = setInterval(() => {
      const currentTime = new Date().getTime();
      elapsedTime = Math.floor((currentTime - startTime) / 60000); // Berechnen der vergangenen Minuten
    }, 1000); // Aktualisieren alle Sekunde
    return elapsedTime;
  }

  function onInputEmail() {
    setVerificationEmail("");
    isEmailInvalid = !!validate(email, ["email"])?.length;
  }

  function toggleModal() {
    isShowVirusModal = !isShowVirusModal;
  }

  onDestroy(() => {
    clearInterval(interval);
  });
</script>

<Boundary>
  <div data-component="QuickshareLandingStepVerifyEmail">
    <QuickshareLandingStep
      step={2}
      title={$t("quickshare_frontend_step_verify_email.verify")}
      {isActiveState}
      desc={$t("quickshare_frontend_step_verify_email.desc")}
    >
      <div slot="content">
        <div class={styles["email-action"]}>
          <TextInput
            style={"round-border medium"}
            name="title"
            bind:value={email}
            placeholder={$t(
              "quickshare_frontend_step_verify_email.placeholder",
            )}
            invalid={isEmailInvalid}
            onInput={onInputEmail}
          />
          <Button
            style={"primary"}
            click={sendVerification}
            disabled={!email.length}
          >
            {$t("quickshare_frontend_step_verify_email.send_verification")}
          </Button>
        </div>
        {#if verificationEmail || isEmailInvalid}
          <div class={styles["email-message"]}>
            {isEmailInvalid
              ? $t("quickshare_frontend_step_verify_email.add_valid_email")
              : $t("quickshare_frontend_step_verify_email.minutes_since", {
                  postProcess: "interval",
                  email,
                  count: elapsedTime,
                })}
          </div>
        {/if}
      </div>
    </QuickshareLandingStep>

    <UploadingFilesVirusModal
      isShowModal={isShowVirusModal}
      onToggle={toggleModal}
      {onSubmit}
    />
  </div>
</Boundary>

<style lang="scss">
  .email-action {
    display: flex;
    gap: 8px;
  }

  .email-message {
    @include typography-footnote-regular;

    color: var(--primary-100);
  }
</style>
