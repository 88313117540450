<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  {fill}
  viewBox="0 0 18 18"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9 0.75C4.44279 0.75 0.75 4.44279 0.75 9C0.75 13.5572 4.44279 17.25 9 17.25C13.5572 17.25 17.25 13.5572 17.25 9C17.25 4.44279 13.5572 0.75 9 0.75ZM9 2.25C12.7288 2.25 15.75 5.27121 15.75 9C15.75 12.7288 12.7288 15.75 9 15.75C5.27121 15.75 2.25 12.7288 2.25 9C2.25 5.27121 5.27121 2.25 9 2.25ZM9 5.066C9.3797 5.066 9.69349 5.34815 9.74315 5.71423L9.75 5.816V8.64456L12.7188 11.0707C13.0395 11.3328 13.0871 11.8052 12.8249 12.126C12.5867 12.4176 12.1745 12.4834 11.8606 12.296L11.7696 12.2321L8.52561 9.58115C8.43779 9.50938 8.37046 9.42184 8.32435 9.32596C8.29078 9.25652 8.26761 9.18112 8.25685 9.10177L8.25661 9.09827C8.25245 9.0666 8.2503 9.03468 8.25018 9.00271L8.25 9V5.816C8.25 5.40179 8.58579 5.066 9 5.066Z"
    fill="currentColor"
  />
</svg>
