<script>
  import { onMount, onDestroy } from "svelte";

  import { quickshareFrontendUploadService } from "apps/quickshareFrontend/services";

  import {
    isVerificationEmailSent,
    uploadingFiles,
    link,
  } from "apps/quickshareFrontend/stores";

  import { Boundary } from "components";
  import {
    QuickshareLandingStepCompleted,
    QuickshareLandingStepsTitle,
    QuickshareLandingStepUploadFiles,
    QuickshareLandingStepVerifyEmail,
    QuickshareLandingStepWaiting,
  } from "./components";

  import styles from "styleguide/QuickshareLandingSteps.json";

  const { emailVerified } = $link;

  let verificationEmail = "";

  $: isUploadFilesCompleted = $uploadingFiles.every(
    (file) => file.progress.uploadComplete,
  );

  $: isVirusCheckCompleted = $uploadingFiles.every((file) => file.stored);

  $: state1 = !emailVerified;
  $: state2 =
    !!$uploadingFiles.length && isUploadFilesCompleted && isVirusCheckCompleted;
  $: state3 = state2 && verificationEmail;
  $: state4 = emailVerified;

  $: !state2 && resetEmail() && resetDisabled();

  function resetEmail() {
    verificationEmail = "";
  }

  function resetDisabled() {
    isVerificationEmailSent.set(false);
  }

  function setVerificationEmail(value) {
    verificationEmail = value;
  }

  onMount(() => {
    quickshareFrontendUploadService.setupUppy();
  });

  onDestroy(() => {
    quickshareFrontendUploadService.closeUppy();
  });
</script>

<Boundary>
  <div data-component="QuickshareLandingSteps">
    <div class={styles.step}>
      <QuickshareLandingStepsTitle />
    </div>

    <div class={styles.step}>
      <QuickshareLandingStepUploadFiles isActiveState={state1} />
    </div>

    <div class={styles.step}>
      <QuickshareLandingStepVerifyEmail
        isActiveState={state2}
        {setVerificationEmail}
        {verificationEmail}
      />
    </div>

    <div class={styles.step}>
      <QuickshareLandingStepWaiting
        isActiveState={state3}
        {verificationEmail}
      />
    </div>

    <div class={styles.step}>
      <QuickshareLandingStepCompleted isActiveState={state4} />
    </div>
  </div>
</Boundary>

<style lang="scss">
  .step:not(:last-child) {
    border-bottom: 1px solid var(--primary-050);
  }
</style>
