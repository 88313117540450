<script>
  import { t } from "stores/i18n";

  import { Boundary, Link, Toast } from "components";

  import styles from "styleguide/QuickshareSidebarInfo.json";
</script>

<Boundary>
  <div data-component="QuickshareSidebarInfo">
    <Toast
      close={false}
      type={"info"}
      title={$t("quickshare_sidebar_info.title")}
    >
      <span class={styles.text}>
        <span>{@html $t("quickshare_sidebar_info.message1")}</span>
        <Link href="mailto:sales@auditi.com" label="sales@auditi.com" />
        <span>{@html $t("quickshare_sidebar_info.message2")}</span>
      </span>
    </Toast>
  </div>
</Boundary>

<style lang="scss">
  .text {
    @include typography-body-regular;
  }
</style>
