<script>
  import { quickshareService } from "apps/quickshare/services";

  import Icons from "components/icons/Icons.svelte";
  import SortIcon from "components/icons/SortArrowIcon.svelte";
  import { DownloadFilesVirusModal } from "components/modals";
  import { currentLanguage, t } from "stores/i18n";
  import { dateTimeFormat, fileIcon } from "lib/helpers.js";
  import { Button, Tag, Tooltip } from "components";

  import { sortOptions, sortIcons } from "./constants";
  import {
    getAscendingSortData,
    getDescendingSortData,
    getTableHeaders,
  } from "./helpers";

  import styles from "styleguide/QuickshareGroupFilesTable.json";

  export let linkPermalink;
  export let rows;

  $: sortStatus = [];
  $: sortDirection = sortOptions.ascending;

  const tableHeaders = getTableHeaders();

  $: tableHeaders.forEach((d) => {
    sortStatus[d.propName] = sortOptions.none;
  });

  $: sortBy = sortOptions.none;
  $: sortedRows = rows;

  $: virusModalFileId = "";

  $: if (sortBy !== sortOptions.none) {
    if (sortDirection === sortOptions.ascending)
      sortedRows = rows.sort((a, b) => {
        const { x, y } = getSortValues(a, b);
        return getAscendingSortData(x, y);
      });
    else
      sortedRows = rows.sort((a, b) => {
        const { x, y } = getSortValues(a, b);
        return getDescendingSortData(x, y);
      });
  }

  function getSortValues(a, b) {
    const isNaNProp = isNaN(a[sortBy]);

    const x = isNaNProp ? a[sortBy].toLowerCase() : a[sortBy];
    const y = isNaNProp ? b[sortBy].toLowerCase() : b[sortBy];

    return { x, y };
  }

  function updateSortStatus(column) {
    tableHeaders.forEach((d) => {
      sortStatus[d.propName] = "none";
    });

    sortDirection === sortOptions.ascending
      ? (sortDirection = sortOptions.descending)
      : (sortDirection = sortOptions.ascending);

    sortStatus[column] = sortDirection;
  }

  function handleSort(header) {
    sortBy = header.propName;
    updateSortStatus(header.propName);
  }

  function getBadgeColor(status) {
    return status ? "green" : "blue";
  }

  function handleDownloadFile(filePermalink, isVirus) {
    if (isVirus) {
      virusModalFileId = filePermalink;
    } else {
      submitDownloadFile(filePermalink);
    }
  }

  function submitDownloadFile(filePermalink) {
    virusModalFileId = "";
    const params = {
      linkPermalink,
      filePermalink,
    };

    quickshareService.downloadSingleFile({
      params,
      onSuccess: (file) => updateFileDownloadStatus(file),
    });
  }

  function updateFileDownloadStatus(file) {
    if (!file.internal) {
      sortedRows = sortedRows.map((item) =>
        item.permalink === file.permalink
          ? { ...item, downloaded: true }
          : item,
      );
    }
  }
</script>

<div data-component="QuickshareGroupFilesTable">
  <table>
    <thead>
      <tr class={styles.row}>
        {#each tableHeaders as header (header)}
          <th style={`width:${header.width}%`}>
            <button
              type="button"
              class={styles.sort}
              on:click={() => handleSort(header)}
            >
              <span>{header.label}</span>
              <span class={styles["sort-icon"]}>
                <SortIcon
                  width={18}
                  height={18}
                  direction={sortIcons[sortStatus[header.propName]]}
                />
              </span>
            </button>
          </th>
        {/each}
      </tr>
    </thead>
    <tbody>
      {#each sortedRows as row (row)}
        <tr class={styles.row}>
          <td>
            <div class={styles.file}>
              <div class={styles.icon}>
                {#if row.virus}
                  <Tooltip
                    message={$t("quickshare_virus_tooltips.detected")}
                    showArrow={true}
                  >
                    <Icons
                      icon="errorFilled"
                      width="22"
                      height="22"
                      fill="var(--red-100)"
                    />
                  </Tooltip>
                {:else}
                  <img src={fileIcon(row.name || "")} alt="" />
                {/if}
              </div>

              <div class={styles["file-info"]}>
                <Tooltip message={row.name} contentWidth={"100%"}>
                  <div
                    class={styles["file-name"]}
                    on:click={() =>
                      handleDownloadFile(row.permalink, row.virus)}
                  >
                    {row.name}
                  </div>
                </Tooltip>

                <div class={styles.size}>
                  ({row.size} MB)
                </div>

                {#if row.virus}
                  <Tooltip
                    message={$t("quickshare_virus_tooltips.report")}
                    showArrow={true}
                  >
                    <Button
                      style={"primary-tonal icon small"}
                      click={() => window.open(row.virus_report.url, "_blank")}
                    >
                      <Icons
                        icon="virusReport"
                        fill="var(--blue-100)"
                        width={18}
                        height={18}
                      />
                    </Button>
                  </Tooltip>
                {/if}

                <DownloadFilesVirusModal
                  isShowModal={virusModalFileId === row.permalink}
                  onHide={() => (virusModalFileId = "")}
                  isAllInfected={true}
                  isSingleFile={true}
                  onSubmit={() => submitDownloadFile(row.permalink)}
                />
              </div>
            </div>
          </td>

          <td>
            {dateTimeFormat(row.created_at, $currentLanguage)}
          </td>

          <td>
            {row.owner}
          </td>

          <td>
            <Tag
              label={row.downloaded
                ? $t("quickshare_group_table.downloaded")
                : $t("quickshare_group_table.not_downloaded")}
              color={getBadgeColor(row.downloaded)}
              size="small"
            />
          </td>
        </tr>
      {/each}
    </tbody>
  </table>
</div>

<style lang="scss">
  table {
    margin-bottom: 0;
    border-collapse: separate;
    border-spacing: 0;
    width: 100%;
  }

  .row {
    height: 54px;

    &:not(:first-of-type) {
      td {
        border-top: 1px solid var(--primary-050);
      }
    }
  }

  thead {
    position: sticky;
    top: 0;
    background: var(--white);
    z-index: 2;
  }

  th {
    border-bottom: 1px solid var(--primary-050);
  }

  td,
  th {
    text-align: left;
    padding: 10px 24px;
    vertical-align: middle;
  }

  td {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-top: 0;
  }

  .sort {
    @include typography-body-semibold;

    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0;
    border: none;
    height: auto;
    color: var(--primary-500);
  }

  .sort-icon {
    display: inline-flex;
    padding: 3px;
    color: var(--blue-100);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
  }

  .file {
    display: grid;
    grid-template-columns: max-content minmax(0, 1fr);
    align-items: center;
    gap: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .file-info {
    display: grid;
    grid-template-columns: minmax(0, min-content) max-content max-content;
    align-items: center;
    column-gap: 5px;
  }

  .file-name {
    @include typography-body-medium;

    color: var(--blue-200);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: pointer;
  }

  .size {
    @include typography-footnote-regular;

    color: var(--primary-200);
    flex-shrink: 0;
  }

  .downloaded {
    background: var(--green-010);
    color: var(--green-500);
  }

  .not_downloaded {
    background: var(--primary-025);
    color: var(--primary-400);
  }
</style>
