<script>
  import {
    linkInitValue,
    LinkType,
    SidebarLinkTypeOptions,
  } from "apps/quickshare/constants";

  import { quickshareService } from "apps/quickshare/services";

  import { sidebarActiveLinkType } from "apps/quickshare/stores";
  import { t } from "stores/i18n";
  import { get } from "svelte/store";

  import { Boundary, Button, Popover } from "components";
  import { QuickshareUniqueLinkCreate } from "./components";

  import styles from "styleguide/QuickshareUniqueLink.json";

  const initLink = {
    ...linkInitValue,
    title: get(t)("quickshare_unique_link.title"),
    link_type: LinkType.REQUEST,
  };

  $: link = { ...initLink };

  function createUniqueLink() {
    const params = { link };

    quickshareService.createLink({
      params,
      onSuccess: (res) => {
        link.permalink = res.item.permalink;
        // TODO add some loading while createUniqueLink is executing
        sidebarActiveLinkType.set(SidebarLinkTypeOptions.REQUEST);
      },
    });
  }

  $: if (!$sidebarActiveLinkType) {
    link = { ...initLink };
  }
</script>

<Boundary>
  <div data-component="QuickshareUniqueLink" class={styles.wrapper}>
    {#if $sidebarActiveLinkType === SidebarLinkTypeOptions.REQUEST}
      <QuickshareUniqueLinkCreate {link} />
    {:else}
      <div class={styles.header}>
        <div class={styles["title-wrapper"]}>
          <span class={styles.title}>{$t("quickshare_unique_link.title")}</span>

          <Popover position="bottom" showCloseButton={false}>
            <Button icon={"info"} style={"small"} />
            <div slot="content">
              {$t("quickshare_unique_link.popover_desc")}
            </div>
          </Popover>
        </div>

        <Button style={"primary-text small"} click={createUniqueLink}>
          {$t("quickshare_unique_link.create_button")}
        </Button>
      </div>
      <div class={styles.desc}>
        {$t("quickshare_unique_link.intro")}
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title {
    @include typography-body-medium;
  }

  .desc {
    @include typography-body-regular;

    color: var(--primary-500);
  }
</style>
