<script>
  import {
    QuickshareLandingWrapper,
    QuickshareLandingSidebar,
    QuickshareLandingContent,
  } from "apps/quickshareFrontend/components";
  import { LinkType } from "apps/quickshareFrontend/constants";
  import { getIsLinkExpired } from "apps/quickshareFrontend/helpers";
  import { link } from "apps/quickshareFrontend/stores";

  import { App } from "components/shared";

  import styles from "styleguide/QuickshareFrontend.json";

  export let appConfig; // required for App
  export let initialState; // props passed from svelte_app

  const { expiredDate } = $link || {};

  if (initialState) {
    link.set({
      // Genearl
      title: initialState.link.title,
      note: initialState.link.message,
      // Password
      permalink: initialState.link.permalink,
      hasPassword: initialState.link.password,
      // Sidebar
      owner: initialState.link.owner,
      expiredDate: initialState.link.expires_at,
      downloadLimit: initialState.link.download_limit,
      linkType: initialState.link.link_type,
      // Files
      files: initialState.files,
      isDownloaded: initialState.link.downloaded,
      // Type
      isRequest: initialState.link.link_type === LinkType.REQUEST,
      isShare: initialState.link.link_type === LinkType.SHARE,
      isTwoWay: initialState.link.link_type == LinkType.TWO_WAY,
      // Footer
      privacyLink: initialState.footer_links.privacy,
      imprintLink: initialState.footer_links.imprint,

      //Verification
      emailVerified: initialState.session?.verified_at,
      sessionId: initialState.session?.permalink,
    });

    //path custom logo and name data for account store
    appConfig = {
      ...appConfig,
      currentAccount: {
        ...appConfig.currentAccount,
        default_logo: initialState.account_logo,
        logo: initialState.account_logo,
        has_custom_logo: !!initialState?.account?.logo?.id,
        name: initialState.account.name,
        official_name: initialState.account.official_name,
      },
    };
  }
</script>

<App {...appConfig} container={true}>
  <QuickshareLandingWrapper>
    <div class={styles.main}>
      <QuickshareLandingContent />

      {#if !getIsLinkExpired(expiredDate)}
        <div class={styles.sidebar}>
          <QuickshareLandingSidebar />
        </div>
      {/if}
    </div>
  </QuickshareLandingWrapper>
</App>

<style lang="scss">
  .main {
    display: grid;
    grid-template-columns: minmax(0, 1fr) 335px;
    gap: 32px;
    padding: 40px var(--page-gutter);
    border-top: 1px solid var(--breadcrumb-border-color);
  }

  .sidebar {
    flex: 0 0 335px;
  }
</style>
