<script>
  import { QuickshareRequestFiles } from "apps/quickshare/components/QuickshareRequestFiles";
  import { QuickshareSendFiles } from "apps/quickshare/components/QuickshareSendFiles";
  import { LinkType } from "apps/quickshare/constants";

  import {
    sidebarActiveLinkType,
    isSidebarScrollable,
  } from "apps/quickshare/stores";
  import { t } from "stores/i18n";
  import { get } from "svelte/store";

  import { Box, Boundary, Tabs } from "components";

  import styles from "styleguide/QuickshareSidebarTabs.json";

  let selectedTabIdInitial = LinkType.SHARE;

  const tabs = [
    {
      id: LinkType.SHARE,
      component: QuickshareSendFiles,
      label: get(t)("quickshare_tabs.send"),
    },
    {
      id: LinkType.REQUEST,
      component: QuickshareRequestFiles,
      label: get(t)("quickshare_tabs.request"),
    },
  ];

  $: if (!$sidebarActiveLinkType) {
    isSidebarScrollable.set(false);
  }
</script>

<div data-component="QuickshareSidebarTabs" class={styles.container}>
  <Box>
    <Boundary>
      <div
        class={`${styles.wrapper} ${$isSidebarScrollable ? styles.scrollable : ""}`}
      >
        <Tabs
          {tabs}
          {selectedTabIdInitial}
          isControlsVisible={!$sidebarActiveLinkType}
        />
      </div>
    </Boundary>
  </Box>
</div>

<style lang="scss">
  .container {
    overflow: hidden;
  }

  .wrapper {
    padding: 20px;

    &.scrollable {
      max-height: calc(100dvh - 220px);
    }
  }
</style>
