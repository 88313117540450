export function getAscendingSortData(a, b) {
  return getSortData(a, b, "asc");
}

export function getDescendingSortData(a, b) {
  return getSortData(a, b, "desc");
}

export function getSortData(a, b, order = "asc") {
  if (a == null || b == null) return NaN;

  if (a === b) return 0; // Equal values

  const comparison = a < b ? -1 : 1;
  return order === "asc" ? comparison : -comparison;
}
