<script>
  import generator from "generate-password-browser";

  import { t } from "stores/i18n";

  import { Boundary, Button, PasswordInput } from "components";
  import styles from "styleguide/QuickshareLinkEditFormPassword.json";

  export let value;
  export let isInitPassword = false;
  export let onChange = () => {};
  export let onToggleReset = () => {};

  let isShowPassword = !isInitPassword;

  function handleGeneratePassword() {
    const value = generator.generate({ length: 16, numbers: true });
    onChange(value);
    isShowPassword = true;
  }

  function handleChange(e) {
    onToggleReset(!e.target.value);
    isShowPassword = true;
  }
</script>

<Boundary>
  <div data-component="QuickshareLinkEditFormPassword">
    <div class={styles.head}>
      <div class={styles.title}>
        {$t("quickshare_link_edit_form_password.password")}
        <span class={styles.optional}
          >({$t("quickshare_link_edit_form_password.optional")})</span
        >
      </div>

      {#if !isShowPassword && isInitPassword}
        <Button style={"primary-text small"} click={() => onToggleReset(true)}>
          {$t("quickshare_link_edit_form_password.reset_password")}
        </Button>
      {:else}
        <Button style={"primary-text small"} click={handleGeneratePassword}>
          {$t("quickshare_link_edit_form_password.generate_password")}
        </Button>
      {/if}
    </div>

    <PasswordInput
      name="password"
      bind:value
      optional={true}
      {isShowPassword}
      isToggleValueDisabled={true}
      isMockedPlaceholder={!isShowPassword && isInitPassword}
      onInput={handleChange}
    />
  </div>
</Boundary>

<style lang="scss">
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .title {
    @include typography-body-medium;

    color: var(--primary-500);
  }

  .optional {
    @include typography-body-regular;

    color: var(--primary-300);
  }
</style>
