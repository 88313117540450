<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg xmlns="http://www.w3.org/2000/svg" {width} {height} viewBox="0 0 18 18">
  <path
    {fill}
    fill-rule="evenodd"
    d="M8.9996,-0.0002 C13.9706,-0.0002 17.9996,4.0298 17.9996,8.9998 C17.9996,13.9708 13.9706,17.9998 8.9996,17.9998 C4.0296,17.9998 -0.0004,13.9708 -0.0004,8.9998 C-0.0004,4.0298 4.0296,-0.0002 8.9996,-0.0002 Z M9.0096,11.9268 L8.9996,11.9268 C8.3786,11.9268 7.8796,12.4308 7.8796,13.0518 C7.8796,13.6728 8.3886,14.1768 9.0096,14.1768 C9.6306,14.1768 10.1346,13.6728 10.1346,13.0518 C10.1346,12.4308 9.6306,11.9268 9.0096,11.9268 L9.0096,11.9268 Z M8.9996,3.9478 C8.4476,3.9478 7.9996,4.3958 7.9996,4.9478 L7.9996,4.9478 L7.9996,8.9998 C7.9996,9.5528 8.4476,9.9998 8.9996,9.9998 C9.5526,9.9998 9.9996,9.5528 9.9996,8.9998 L9.9996,8.9998 L9.9996,4.9478 C9.9996,4.3958 9.5526,3.9478 8.9996,3.9478 Z"
  />
</svg>
