<script>
  import { quickshareUploadService } from "apps/quickshare/services/index.js";
  import { t } from "stores/i18n";

  import { Boundary, Button } from "components";
  import { QuickshareFilesListItem } from "../QuickshareFilesListItem";

  import styles from "styleguide/QuickshareFilesList.json";

  export let files;
  export let permalink;

  function handleRemoveAll() {
    const params = { permalink };
    quickshareUploadService.removeAllFiles({ params });
  }
</script>

<Boundary>
  <div data-component="QuickshareFilesList">
    <div class={styles.head}>
      <div class={styles.title}>
        {$t("quickshare_file_list.uploaded_files", {
          postProcess: "interval",
          count: files?.length,
        })}
      </div>

      {#if files.length}
        <Button click={handleRemoveAll} style={"error-text small"}>
          {$t("quickshare_file_list.delete_all_files")}
        </Button>
      {/if}
    </div>

    <div class={styles.list}>
      {#each files as file (file.name)}
        <QuickshareFilesListItem {file} {permalink} />
      {/each}
    </div>
  </div>
</Boundary>

<style lang="scss">
  .list {
    border-top: 1px solid var(--primary-050);
    max-height: 210px;
    overflow-y: auto;
  }

  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;
  }

  .title {
    @include typography-body-medium;
  }
</style>
