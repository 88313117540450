<script>
  export let width = 22;
  export let height = 22;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 22 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.2524 10.3332C9.97518 10.6206 9.72271 10.8784 9.32293 10.8488C8.73998 10.8057 8.30192 10.1743 8.56262 9.61845C8.60623 9.52541 8.70089 9.40588 8.84659 9.25986C9.0283 9.07766 10.7621 7.37048 10.7583 7.32929C10.7575 7.32261 10.7544 7.31643 10.7494 7.31195C10.7443 7.30748 10.7378 7.30502 10.7311 7.30506C7.66889 7.30506 4.60714 7.30506 1.54588 7.30506C1.32814 7.30506 1.17178 7.28939 1.07681 7.25805C0.326673 7.01237 0.320858 5.82902 1.07535 5.58479C1.17421 5.55313 1.34155 5.53731 1.57738 5.53731C4.64026 5.53601 7.70007 5.53585 10.7568 5.53682C10.7595 5.53673 10.7621 5.53586 10.7644 5.53431C10.7666 5.53276 10.7683 5.5306 10.7693 5.52809C10.7704 5.52558 10.7706 5.52282 10.7701 5.52016C10.7696 5.5175 10.7684 5.51504 10.7665 5.51308C10.1763 4.92544 9.58993 4.33942 9.00747 3.75501C8.7487 3.49528 8.59331 3.30387 8.5413 3.18079C8.20306 2.37638 9.31663 1.58409 9.96064 2.22664C11.08 3.34441 12.1988 4.46283 13.3168 5.58189C13.5488 5.81416 13.69 5.99152 13.7404 6.11396C13.8857 6.46867 13.7704 6.80255 13.5039 7.07391C12.4296 8.1681 11.316 9.2303 10.2524 10.3332Z"
    {fill}
  />
  <path
    d="M11.5267 16.1957C12.1056 16.7781 12.686 17.359 13.2678 17.9382C13.5324 18.2015 13.6912 18.3944 13.7442 18.5168C13.9971 19.0993 13.4481 19.7976 12.7944 19.7016C12.5999 19.6732 12.4284 19.5847 12.2798 19.4361C11.1985 18.3541 10.1174 17.2721 9.03646 16.1898C8.76347 15.9165 8.60114 15.7182 8.54945 15.5948C8.37694 15.1848 8.55963 14.8631 8.86394 14.5587C9.97234 13.451 11.0806 12.3432 12.1887 11.2355C12.3592 11.0652 12.4998 10.9577 12.6102 10.9128C13.325 10.622 14.0456 11.4182 13.7161 12.1087C13.6747 12.1956 13.5891 12.3041 13.4592 12.4343C12.8125 13.0827 12.1643 13.7312 11.5146 14.3799C11.5125 14.3819 11.5111 14.3844 11.5105 14.3872C11.5099 14.39 11.5102 14.3929 11.5113 14.3956C11.5124 14.3982 11.5143 14.4004 11.5167 14.402C11.5191 14.4035 11.5219 14.4043 11.5248 14.4042C14.5896 14.4042 17.6547 14.4042 20.7202 14.4042C20.9673 14.4042 21.1403 14.4208 21.2392 14.4541C22.0838 14.7385 21.8871 16.1739 20.9552 16.1729C17.8155 16.1713 14.6757 16.1716 11.5359 16.1739C11.5159 16.1739 11.5128 16.1811 11.5267 16.1957Z"
    {fill}
  />
</svg>
