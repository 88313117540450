<script>
  import { sanitizeRelaxed } from "lib/sanitize.js";
  import styles from "styleguide/RadioGroup.json";

  export let style = "";
  export let toggle;
  export let items;

  function getStyles() {
    return style
      .split(" ")
      .map((x) => styles[x])
      .join(" ");
  }

  function onToggle(id) {
    if (toggle) {
      toggle(id);
    }
  }
</script>

{#each items as { value, name, label, checked } (value)}
  <div data-component="RadioGroup" class="{styles.radioGroup} {getStyles()}">
    <input
      on:change={() => onToggle(value)}
      type="radio"
      id={name + value}
      {name}
      {checked}
    />
    <label for={name + value}>{@html sanitizeRelaxed(label)}</label>
  </div>
{/each}

<style lang="scss">
  .radioGroup {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    color: var(--primary-500);
  }

  [type="radio"]:checked + label:before,
  [type="radio"]:not(:checked) + label:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid var(--primary-050);
    border-radius: 100%;
    background: #fff;
  }

  [type="radio"]:checked + label:after,
  [type="radio"]:not(:checked) + label:after {
    content: "";
    width: 12px;
    height: 12px;
    background: var(--blue);
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
  }

  [type="radio"]:not(:checked) + label:after {
    opacity: 0;
    transform: scale(0);
  }

  [type="radio"]:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }
</style>
