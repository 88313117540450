<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  {width}
  {height}
  viewBox="0 0 32 32"
  fill="none"
>
  <path
    d="M10.327 28.9512C6.4793 27.0023 4.03042 23.2727 3.63517 19.0178C3.60329 18.6741 3.58734 18.2062 3.58734 17.614C3.58688 14.1299 3.58641 10.6455 3.58594 7.16092C3.58594 6.74926 3.61759 6.43254 3.68088 6.21077C3.98049 5.16708 4.76326 4.6811 5.80695 4.56295C9.02195 4.19864 11.9036 3.03163 14.4519 1.06193C15.1763 0.501407 15.9654 0.286199 16.8325 0.660352C17.0046 0.734433 17.2998 0.92784 17.718 1.24057C20.1462 3.05742 22.872 4.1548 25.8952 4.5327C26.3594 4.59037 26.6843 4.65367 26.87 4.72259C27.5827 4.98656 28.0405 5.48262 28.2435 6.21077C28.3101 6.44942 28.3434 6.81349 28.3434 7.30299C28.3439 10.6929 28.3429 14.083 28.3406 17.4734C28.3401 18.1035 28.3282 18.5719 28.3047 18.8785C28.0016 22.8824 25.7975 26.4706 22.3569 28.5383C22.0934 28.6963 21.6801 28.9172 21.117 29.2008C19.7545 29.8868 18.3899 30.5685 17.0231 31.246C16.3175 31.5963 15.6118 31.5963 14.9062 31.246C13.3768 30.4879 11.8504 29.7229 10.327 28.9512ZM5.89345 19.0002C6.26972 22.4879 8.3142 25.4199 11.4629 27.0094C12.9492 27.76 14.4402 28.5067 15.9358 29.2494C15.9574 29.2601 15.979 29.2601 16.0005 29.2494C17.5379 28.4828 19.0716 27.7087 20.6015 26.9271C23.6855 25.3524 25.706 22.3627 26.0408 18.9299C26.0717 18.6153 26.0877 18.1469 26.0886 17.5247C26.0966 13.967 26.0966 10.4092 26.0886 6.85147C26.0886 6.8285 26.0771 6.81584 26.0542 6.81349C22.2953 6.42434 18.9382 5.06768 15.983 2.74352C15.9656 2.72992 15.948 2.72992 15.9302 2.74352C12.9796 5.07471 9.62468 6.43184 5.86532 6.8149C5.85779 6.8156 5.85079 6.81917 5.84571 6.8249C5.84063 6.83063 5.83784 6.8381 5.83789 6.84585C5.83602 10.4284 5.83625 14.0115 5.8386 17.595C5.83907 18.1966 5.85735 18.665 5.89345 19.0002Z"
    {fill}
  />
  <path
    d="M15.7538 19.4412C15.2446 20.0031 14.5153 20.0291 13.9935 19.5003C13.4833 18.9836 12.4071 17.8742 10.7646 16.1723C9.58099 14.9457 11.2246 13.4406 12.2781 14.5167C13.1268 15.3836 13.9738 16.2522 14.8191 17.1224C14.836 17.1393 14.8527 17.1393 14.8691 17.1224C16.3146 15.6497 17.7603 14.1779 19.2063 12.7071C19.5847 12.3226 19.8072 12.1091 19.8737 12.0664C20.3316 11.7745 21.0489 11.9011 21.3725 12.3688C21.7276 12.8822 21.6186 13.4505 21.1995 13.8809C19.4792 15.6453 17.7521 17.4028 16.0183 19.1535C15.9887 19.1831 15.9006 19.279 15.7538 19.4412Z"
    {fill}
  />
</svg>
