<script>
  import CheckmarkIcon from "components/icons/CheckmarkFilledIcon.svelte";
  import { t } from "stores/i18n";

  import { Boundary, Button, ProgressRing } from "components";

  import styles from "styleguide/QuickshareLinkCreateStatus.json";

  export let title = "";
  export let info = "";
  export let btnText = $t("quickshare_link_create_status.create_copy");
  export let loadingPercentage = 100;
  export let isActionDisabled = false;
  export let onAction = () => {};
</script>

<Boundary>
  <div data-component="QuickshareLinkCreateStatus" class={styles.container}>
    <div class={styles.content}>
      {#if loadingPercentage < 100}
        <ProgressRing
          size="26"
          stroke="4"
          completedPercent={loadingPercentage}
        />
      {:else}
        <CheckmarkIcon width={26} />
      {/if}

      <div class={styles.result}>
        <span class={styles.title}>
          {title}
        </span>
        <span>
          {info}
        </span>
      </div>
    </div>

    <Button style={"primary"} click={onAction} disabled={isActionDisabled}>
      {btnText}
    </Button>
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    position: sticky;
    bottom: 0;

    padding: 16px 20px;
    margin: 0 -20px -20px;

    border-top: 1px solid var(--primary-050);
    border-radius: 0 0 var(--border-radius) var(--border-radius);

    box-shadow:
      0 -2px 4px 0 rgba(24, 39, 75, 0.08),
      -1px -6px 8px 0 rgba(24, 39, 75, 0.07),
      0 -7px 10px 0 rgba(24, 39, 75, 0.04);
    background: var(--white);
    color: var(--primary-500);
  }

  .content {
    @include typography-body-regular;

    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: center;
    gap: 12px;
  }

  .title {
    @include typography-body-medium;
  }
</style>
