<script>
  import Icons from "components/icons/Icons.svelte";

  import { t } from "stores/i18n";

  import { Boundary, Button, PasswordInput } from "components";

  import { quickshareFrontendService } from "../../services";

  import styles from "styleguide/QuickshareLandingPasswordForm.json";

  export let onShowContent = () => {};

  $: password = "";
  let errors = [];

  $: password, (errors = []);

  function checkPassword() {
    errors = [];
    if (!password) {
      const errorMessage = $t(
        "quickshare_frontend_landing_password.password_mandatory",
      );
      errors = [errorMessage];
      return;
    }

    quickshareFrontendService.checkLink({
      payload: password,
      onSuccess: () => onShowContent(),
      onError: (err) => handleError(err),
    });
  }

  function handleError(err) {
    if (err?.code === "unauthorised") {
      const errorMessage = $t(
        "quickshare_frontend_landing_password.password_error",
      );
      errors = [errorMessage];
    }
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingPasswordForm" class={styles.wrapper}>
    <div class={styles.info}>
      <Icons icon="lock" fill="var(--blue-100)" width={56} height={56} />

      <div>
        <div class={styles.title}>
          {$t("quickshare_frontend_landing_password.header")}
        </div>

        <div class={styles.subtitle}>
          {$t("quickshare_frontend_landing_password.subtitle")}
        </div>
      </div>
    </div>

    <div class={styles.password}>
      <PasswordInput
        bind:value={password}
        placeholder={$t("quickshare_frontend_landing_password.password")}
        onEnter={checkPassword}
        {errors}
      />

      <div class={styles.action}>
        <Button style={"primary"} click={checkPassword}
          >{$t("quickshare_frontend_landing_password.submit_password")}</Button
        >
      </div>
    </div>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    padding: 110px 20px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .title {
    @include typography-caption-medium;

    margin-bottom: 8px;
  }

  .subtitle {
    @include typography-body-regular;
  }

  .password {
    display: flex;
    flex-direction: column;
    gap: 16px;
    justify-content: center;
    max-width: 100%;
    width: 320px;
  }

  .action {
    display: flex;
    justify-content: center;
  }
</style>
