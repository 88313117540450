<script>
  import { quickshareService } from "apps/quickshare/services";
  import { t } from "stores/i18n";

  import { Button } from "components";

  import styles from "styleguide/QuickshareGroupLinkSettingsActions.json";

  export let link;
  export let validationErrors;
  export let onSubmit = () => {};

  function handleSave() {
    onSubmit();

    if (Object.keys(validationErrors).length) return;

    const params = { link };
    quickshareService.updateLink({ params });
  }

  function handleDelete() {
    if (confirm($t("questions.sure"))) {
      const params = { link };

      quickshareService.deleteLink({
        params,
        onSuccess: () => quickshareService.getLinksList(),
      });
    }
  }
</script>

<div data-component="QuickshareGroupLinkSettingsActions" class={styles.wrapper}>
  <Button style={"primary"} click={handleSave}>
    {$t("quickshare_group_link_settings_actions.save")}
  </Button>

  {#if !link.personal_inbox}
    <Button style={"error-text"} click={handleDelete}>
      {$t("quickshare_group_link_settings_actions.delete")}
    </Button>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    gap: 8px;
  }
</style>
