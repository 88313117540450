<script>
  export const directions = ["none", "", "desc", "", "asc"];
  export let direction = "none";
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";

  $: rotation = directions.indexOf(direction) * 90;
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  {width}
  {height}
  viewBox="0 0 18 18"
  {fill}
  style={`transform: rotate(${rotation}deg)`}
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9.86618 12.3423L13.1342 6.6823C13.5182 6.0153 13.0382 5.1823 12.2672 5.1823L5.73218 5.1823C4.96218 5.1823 4.48118 6.0153 4.86618 6.6823L8.13418 12.3423C8.51818 13.0083 9.48118 13.0083 9.86618 12.3423Z"
    fill="currentColor"
  />
</svg>
