<script>
  import { LinkType } from "apps/quickshare/constants";

  import { quickshareService } from "apps/quickshare/services";

  import Icons from "components/icons/Icons.svelte";

  import { differenceInDays } from "date-fns";

  import { currentLanguage, t } from "stores/i18n";
  import { get } from "svelte/store";

  import { copyToClickboard, dateTimeFormat } from "lib/helpers.js";

  import { Button, Tag, PulseDot } from "components";

  import styles from "styleguide/QuickshareGroupTitle.json";

  export let link;

  $: title = link.title;
  $: expiresDays = link.expires_at;
  $: createdDate = link.created_at;
  $: isExpired = link.is_expired;
  $: isPersonal = link.personal_inbox;

  $: expiredDifference = differenceInDays(new Date(expiresDays), new Date());
  $: formattedCreatedDate = dateTimeFormat(createdDate, $currentLanguage);
  $: groupIcon = getIcon(link.link_type);

  $: expirationInfo = () => {
    if (isPersonal) {
      return get(t)("quickshare_group_title.does_not_expire");
    }

    if (!expiresDays) {
      return get(t)("quickshare_group_title.link_updated");
    }

    return get(t)("quickshare_group_title.link_expires", {
      postProcess: "interval",
      count: expiredDifference,
    });
  };

  function handleCopyLink() {
    copyToClickboard(window.location.origin + "/quickshare/" + link?.permalink);
  }

  function handleRestore() {
    const params = {
      link: {
        ...link,
        expires_in: 7,
      },
    };
    quickshareService.updateLink({ params });
  }

  function getIsActive(linkData) {
    let isActive = false;

    linkData.quickshare_sessions.forEach((session) => {
      const isNotDownloaded = session.item.some(
        (item) =>
          item.quickshare_files.some((file) => !file.downloaded) &&
          !item.internal,
      );

      if (isNotDownloaded) {
        isActive = true;
      }
    });

    return isActive;
  }

  function getIcon(type) {
    switch (type) {
      case LinkType.SHARE: {
        return "paperplane";
      }
      case LinkType.TWO_WAY: {
        return "twoWayShare";
      }
      default: {
        return "inbox";
      }
    }
  }
</script>

<div data-component="QuickshareGroupTitle" class={styles.wrapper}>
  <div class={styles["title-icon"]}>
    {#if getIsActive(link)}
      <div class={styles["dot-container"]}>
        <PulseDot isActive={true} />
      </div>
    {/if}

    <Icons icon={groupIcon} fill="var(--primary-500)" width={22} height={22} />
  </div>

  <div class={styles.info}>
    <div class={styles.title}>
      {title}
    </div>

    <div class={styles.desc}>
      {#if isExpired}
        <div class={styles["desc-item"]}>
          <Tag
            label={$t("quickshare_group_title.link_expired")}
            color="orange"
            size="small"
          />
        </div>
      {:else}
        <div class={styles["desc-item"]}>
          <Icons
            icon="clock"
            fill="var(--primary-500)"
            width={18}
            height={18}
          />
          <span>{expirationInfo()}</span>
        </div>
      {/if}

      <div class={styles["desc-item"]}>
        <Icons
          icon="calendar"
          fill="var(--primary-500)"
          width={18}
          height={18}
        />
        <span
          >{$t("quickshare_group_title.created_at")}
          {formattedCreatedDate}</span
        >
      </div>
    </div>
  </div>

  <div>
    {#if isExpired}
      <Button style={"primary-tonal small"} click={handleRestore}>
        {$t("quickshare_group_title.restore_link")}
      </Button>
    {:else}
      <Button style={"primary-tonal small"} click={handleCopyLink}>
        {$t("quickshare_group_title.copy_link")}
      </Button>
    {/if}
  </div>
</div>

<style lang="scss">
  .wrapper {
    display: grid;
    grid-template-columns: 45px minmax(0, 1fr) max-content;
    align-items: center;
    white-space: nowrap;
    grid-gap: 16px;
  }

  .title-icon {
    position: relative;
    border-radius: var(--border-radius);
    background: var(--primary-010);
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  .dot-container {
    position: absolute;
    top: 0;
    right: 0;
  }

  .info {
    width: 100%;
  }

  .title {
    @include typography-caption-semibold;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    margin-bottom: 5px;
  }

  .desc {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 5px 16px;
  }

  .desc-item {
    display: flex;
    align-items: center;
    gap: 8px;
  }
</style>
