<script>
  import { quickshareService } from "apps/quickshare/services";

  import { t } from "stores/i18n";

  import { Boundary, Button, CopyInput, Popover } from "components";

  import styles from "styleguide/QuickshareAccessLink.json";

  export let canRegenerateLink = false;
  export let canCopyInput = true;
  export let showLabel = true;
  export let link;

  $: href = window.location.origin + "/quickshare/" + link.permalink;
  $: isLoading = false;

  function onRegeneratePermalink() {
    isLoading = true;
    const params = { permalink: link.permalink };
    quickshareService.regeneratePermalink({
      params,
      onSuccess: (res) => {
        link.permalink = res.permalink;
        isLoading = false;
      },
      onError: () => (isLoading = false),
    });
  }
</script>

<Boundary>
  <div data-component="QuickshareAccessLink">
    <div class={styles.header}>
      {#if showLabel}
        <div class={styles.title}>{$t("quickshare_access_link.title")}</div>
      {/if}

      {#if canRegenerateLink}
        <div class={styles["regenerate-wrapper"]}>
          <Button style={"primary-text small"} click={onRegeneratePermalink}>
            {$t("quickshare_access_link.regenerate_link_button")}
          </Button>

          <Popover position="bottom" showCloseButton={false}>
            <Button icon={"info"} style={"small"} />
            <div slot="content">
              {$t("quickshare_access_link.popover_desc")}
            </div>
          </Popover>
        </div>
      {/if}
    </div>

    <CopyInput value={href} canCopy={canCopyInput} disabled={isLoading} />
  </div>
</Boundary>

<style lang="scss">
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }

  .title {
    @include typography-body-medium;

    color: var(--primary-500);
  }

  .regenerate-wrapper {
    display: flex;
    align-items: center;
  }
</style>
