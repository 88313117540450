<script>
  import {
    QuicksharePersonalInbox,
    QuickshareUniqueLink,
  } from "apps/quickshare/components";

  import { Boundary } from "components";
  import { SidebarLinkTypeOptions } from "../../constants";
  import { sidebarActiveLinkType } from "../../stores";

  import styles from "styleguide/QuickshareRequestFiles.json";

  export let onActiveActionsToggle;
</script>

<Boundary>
  <div data-component="QuickshareRequestFiles">
    {#if $sidebarActiveLinkType !== SidebarLinkTypeOptions.REQUEST}
      <QuicksharePersonalInbox {onActiveActionsToggle} />
    {/if}

    {#if !$sidebarActiveLinkType}
      <div class={styles["separator"]} />
    {/if}

    {#if $sidebarActiveLinkType !== SidebarLinkTypeOptions.PERSONAL}
      <QuickshareUniqueLink {onActiveActionsToggle} />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .separator {
    margin: 24px -20px;
    border-bottom: 1px solid var(--primary-050);
  }
</style>
