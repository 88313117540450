import { quickshareApi } from "apps/quickshare/apis";
import { listFiltersInitValue } from "apps/quickshare/constants";
import {
  isLinksListLoading,
  quickshareLinks,
  quickshareLinksFilters,
} from "apps/quickshare/stores";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

class QuickshareService {
  constructor() {}

  getPersonalInbox({ onSuccess, onError } = {}) {
    quickshareApi.getPersonalInbox({
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  getLinksList({ onSuccess, onError, params } = {}) {
    isLinksListLoading.set(true);
    const { page } = params || {};

    const filters = {
      ...get(quickshareLinksFilters),
    };

    quickshareApi.getLinksList({
      params: {
        ...(filters && {
          filters: {
            ...filters,
          },
        }),
        ...(page && { page }),
      },
      success: (res) => {
        quickshareLinks.set(res?.data);
        isLinksListLoading.set(false);

        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        isLinksListLoading.set(false);
        onError?.(err);
      },
    });
  }

  setListFilters(data) {
    quickshareLinksFilters.set(data);
  }

  resetListFilters() {
    quickshareLinksFilters.set({
      ...listFiltersInitValue,
    });
  }

  createLink({ params, onSuccess, onError } = {}) {
    const { link } = params || {};

    quickshareApi.createLink({
      params: link,
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  updateLink({ params, onSuccess, onError } = {}) {
    const { link } = params || {};

    quickshareApi.updateLink({
      params: link,
      success: (res) => {
        toasts.send({
          message: get(t)("quickshare_service.link_updated"),
          type: "success",
        });

        const targetIdx = get(quickshareLinks).items.findIndex(
          (item) => item.permalink === res.item.permalink,
        );

        const linksData = get(quickshareLinks).items.map((item, idx) => {
          if (idx === targetIdx) {
            return res.item;
          } else {
            return item;
          }
        });

        quickshareLinks.update((prevValue) => ({
          ...prevValue,
          items: linksData,
        }));

        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  updateLinkDraft({ params, onSuccess, onError } = {}) {
    const { link } = params || {};

    quickshareApi.updateLink({
      params: link,
      success: (res) => {
        onSuccess?.(res.item);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  deleteLink({ params, onSuccess, onError } = {}) {
    const { link } = params || {};
    isLinksListLoading.set(true);

    quickshareApi.deleteLink({
      params: link,
      success: (res) => {
        isLinksListLoading.set(false);
        toasts.send({
          message: get(t)("quickshare_service.link_deleted"),
          type: "success",
        });

        onSuccess?.(res);
      },
      error: (err) => {
        isLinksListLoading.set(false);
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  deleteSingleFile({ params, onSuccess, onError }) {
    quickshareApi.deleteSingleFile({
      params,
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  deleteFiles({ params, onSuccess, onError } = {}) {
    const { permalink } = params || {};

    quickshareApi.deleteFiles({
      params: {
        permalink,
      },
      success: (res) => {
        quickshareLinks.update((prevValue) => ({
          ...prevValue,
          items: prevValue.items.map((link) => {
            if (link.permalink === permalink) {
              return {
                ...link,
                quickshare_sessions: link.quickshare_sessions.map(
                  (session) => ({
                    ...session,
                    item: session.item.map((item) => ({
                      ...item,
                      quickshare_files: [],
                    })),
                  }),
                ),
              };
            } else {
              return link;
            }
          }),
        }));

        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  downloadFiles({ params, onSuccess, onError } = {}) {
    const { permalink, excludeSuspicious } = params || {};

    quickshareApi.downloadFiles({
      params: {
        permalink,
        excludeSuspicious,
      },
      success: (res) => {
        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  downloadSessionFiles({ params, onSuccess, onError } = {}) {
    const { sessionId, permalink, excludeSuspicious } = params || {};

    quickshareApi.downloadFilesForSession({
      params: {
        sessionId,
        permalink,
        excludeSuspicious,
      },
      success: (res) => {
        toasts.send({
          message: get(t)("quickshare_service.archive_downloaded"),
          type: "success",
        });

        onSuccess?.(res);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });

        onError?.(err);
      },
    });
  }

  downloadSingleFile({ params, onSuccess, onError } = {}) {
    const { linkPermalink, filePermalink } = params || {};

    quickshareApi.downloadSingleFile({
      params: {
        link_id: linkPermalink,
        id: filePermalink,
      },
      success: (res) => {
        if (res?.code === "success") {
          window.open(res.data.item.url, "_blank");
        }
        onSuccess?.(res.data.item);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  regeneratePermalink({ params, onSuccess, onError } = {}) {
    const { permalink } = params || {};

    quickshareApi.regeneratePermalink({
      params: {
        permalink,
      },
      success: (res) => {
        const updatedLink = res.data.item;

        toasts.send({
          message: get(t)("quickshare_service.link_updated"),
          type: "success",
        });

        const targetIdx = get(quickshareLinks).items.findIndex(
          (item) => item.permalink === permalink,
        );

        const linksData = get(quickshareLinks).items.map((item, idx) => {
          if (idx === targetIdx) {
            return { ...updatedLink };
          } else {
            return item;
          }
        });

        quickshareLinks.update((prevValue) => ({
          ...prevValue,
          items: linksData,
        }));

        onSuccess?.(updatedLink);
      },
      error: (err) => {
        toasts.send({
          message: get(t)("errors.unknown"),
          type: "error",
        });
        onError?.(err);
      },
    });
  }

  // Updating the download status for the files that are
  // located inside the sessions
  updateFilesDownloadStatus({
    permalink,
    date,
    sessionPermalink,
    filePermalink,
    isAllDownload,
    isAllSessionDownload,
  }) {
    quickshareLinks.update((data) => {
      return {
        ...data,
        items: [
          ...data.items.map((link) => {
            if (link.permalink === permalink) {
              return {
                ...link,
                quickshare_sessions: link.quickshare_sessions.map((session) => {
                  if (session.date === date || isAllDownload) {
                    return {
                      ...session,
                      item: session.item.map((item) => {
                        if (
                          (item.permalink === sessionPermalink ||
                            isAllDownload) &&
                          !item.internal
                        ) {
                          return {
                            ...item,
                            quickshare_files: item.quickshare_files.map(
                              (file) => {
                                if (
                                  file.permalink === filePermalink ||
                                  isAllDownload ||
                                  isAllSessionDownload
                                ) {
                                  return {
                                    ...file,
                                    downloaded: true,
                                  };
                                } else {
                                  return file;
                                }
                              },
                            ),
                          };
                        } else {
                          return item;
                        }
                      }),
                    };
                  } else {
                    return session;
                  }
                }),
              };
            } else {
              return link;
            }
          }),
        ],
      };
    });
  }

  static instance;

  static getInstance() {
    if (!QuickshareService.instance) {
      QuickshareService.instance = new QuickshareService();
    }
    return QuickshareService.instance;
  }
}

export const quickshareService = QuickshareService.getInstance();
