<script>
  import {
    refreshProject,
    projectsAccesses,
    couldSaveUsers,
  } from "stores/dashboard/project.js";
  import { t } from "stores/i18n.js";
  import { theme } from "stores/theme.js";
  import { toasts } from "stores/toasts.js";
  import { user as currentUser } from "stores/user.js";

  import Projects from "apis/dashboard/projects.js";
  import TeamUsersApi from "apis/dashboard/team_users.js";

  import {
    Boundary,
    Button,
    Dropdown,
    Search,
    TextInput,
    Tooltip,
    UserPicture,
  } from "components";
  import styles from "styleguide/DashboardProjectControl.json";

  export let type;
  export let name = "";
  export let access;
  export let team_users = [];
  export let permalink;
  export let wrapped;
  export let showModal;

  const allAccesses = ["public", "private", "confidential"];

  let dirty;
  let users = [];
  $: allowedAccesses = $projectsAccesses.allowed_accesses
    ? $projectsAccesses.allowed_accesses.split(",").map((item) => item.trim())
    : allAccesses;

  let visibility;
  let incorrectAccess = false;

  // Can be possible if project access was setup before default access restrictions
  $: incorrectAccess =
    type === "edit" &&
    access !== "undefined" &&
    !allowedAccesses.includes(access);

  $: assignCurrentUserToNewProject(access, visibility, users);
  $: users = combineUsers(team_users);
  $: visibility = initialVisibility(access);

  $: visibilityTypes = initVisibilityTypes(access);
  $: visibilityTypesFootnotes = initVisibilityTypesFootnotes(allowedAccesses);
  $: valid = checkValid(name, users, visibility);
  $: couldSaveUsers.set(valid);

  function initVisibilityTypes(access) {
    let allowedAccessTypes = {};

    // When project was created before restictions
    if (typeof access !== "undefined" && !allowedAccesses.includes(access)) {
      allowedAccesses.push(access);
    }

    for (let accessType of allowedAccesses) {
      if ($theme === "eyde" && accessType === "confidential") continue;

      allowedAccessTypes[accessType] = $t(
        `dashboard_new_project.${accessType}_project`,
      );
    }

    return allowedAccessTypes;
  }

  function checkValid(name, users, visibility) {
    return name && (!assigneeRequired(visibility) || usersPresent(users));
  }

  function initVisibilityTypesFootnotes(allowedAccesses) {
    let allowedAccessTypesFootnotes = [];
    for (let accessType of allowedAccesses) {
      if ($theme === "eyde" && accessType === "confidential") continue;

      allowedAccessTypesFootnotes.push(
        $t(`dashboard_new_project.${accessType}_project_footnote`),
      );
    }

    return allowedAccessTypesFootnotes;
  }

  function modifySearchResults(response) {
    return combineUsers(response.data.team_users);
  }

  function processUser(item) {
    const user = {
      avatar: item.avatar,
    };

    if (item.text) user.label = item.text;
    if (item.name) user.label = item.name;
    if (item.id) user.value = item.id;
    if (item.permalink) user.value = item.permalink;

    user.url = item.url ? item.url : `/team_users/${user.value}/edit`;
    return user;
  }

  function combineUsers(users) {
    return users.map(processUser);
  }

  function getModified(id, data) {
    function findUser(id, users) {
      return users.find((user) => user.value === id);
    }

    if (findUser(id, users)) {
      return;
    } else {
      users = [...users, data.find((item) => item.value === id)];
    }
  }

  function deleteUser(id) {
    users = users.filter((user) => user.value !== id);
  }

  function assigneeRequired(visibility) {
    return allAccesses.includes(visibility);
  }

  function usersPresent(users) {
    return users.length !== 0;
  }

  function validAccess(access) {
    return allAccesses.includes(access);
  }

  function initialVisibility(access) {
    return validAccess(access)
      ? access
      : $projectsAccesses.default_access || "public";
  }

  function creatingProject(access) {
    // Initially, when creating a new project, the `access` property is
    // `undefined` and that's not valid. We use this fact to recognize when
    // someone is using the "Create new project" modal.
    return !validAccess(access);
  }

  function assignCurrentUserToNewProject(access, visibility, users) {
    if (
      !creatingProject(access) ||
      !assigneeRequired(visibility) ||
      usersPresent(users)
    ) {
      return;
    }

    const projectCreator = processUser($currentUser);
    getModified(projectCreator.value, [projectCreator]);
  }

  export function onSubmit() {
    if (!valid) {
      dirty = true;
      return;
    }
    let formData = new FormData();

    formData.append("name", name);
    formData.append("access", visibility);

    for (let user of users) {
      formData.append("team_user_ids[]", user.value);
    }

    dirty = false;

    const params = {
      params: formData,
      success: (response) => {
        if (type === "create") {
          const path = response.data.path;
          if (path) {
            window.location = path;
          }
        } else {
          refreshProject();
          toasts.send({
            title: $t("dashboard_new_project.successfully_changed"),
            type: "success",
          });
        }

        showModal = false;
      },
      error: onProjectsCreateError,
    };

    if (type === "create") {
      Projects.create(params);
    } else {
      params.id = permalink;
      Projects.edit(params, permalink);
    }
  }

  function onProjectsCreateError() {
    dirty = true;
  }
</script>

<Boundary>
  <div
    data-component="DashboardProjectControl"
    class={`${styles.wrapper} ${wrapped ? styles.wrapped : ""}`}
  >
    <div class={styles.inner}>
      {#if type !== "manage_users"}
        <div class={styles.block}>
          <div class={styles.container}>
            <div class={styles.input}>
              <TextInput
                label={$t("dashboard_new_project.name")}
                footnotes={[$t("dashboard_new_project.name_footnote")]}
                bind:value={name}
                errors={dirty ? [$t("dashboard_new_project.name_error")] : []}
                style={"border medium round-border fullsize"}
              />
            </div>
            <div class={styles.input}>
              <Dropdown
                label={$t("dashboard_new_project.visibility")}
                items={visibilityTypes}
                lock={incorrectAccess}
                showSelected={true}
                footnotes={visibilityTypesFootnotes}
                bind:selected={visibility}
              />
            </div>
          </div>
        </div>
      {/if}

      <div class={styles.block}>
        <div class={styles.group}>
          <div class={styles.container}>
            <Search
              searchApi={TeamUsersApi}
              {modifySearchResults}
              {getModified}
              style={"border medium round-border fullsize"}
              label={$t("dashboard_new_project.employee")}
              footnotes={[
                $t("dashboard_new_project.confidential_project_footnote"),
                $t("dashboard_new_project.private_project_footnote"),
                $t("dashboard_new_project.public_project_footnote"),
              ]}
            />
          </div>
        </div>
        {#each users as { value, label, avatar } (value)}
          <div class={styles.user}>
            <div class={styles.container}>
              <div class={styles["user-inner"]}>
                <div class={styles["user-content"]}>
                  <UserPicture name={label} {avatar} />
                  <div class={styles.name}>{label}</div>
                </div>
                {#if users.length <= 1}
                  <Tooltip showArrow>
                    <div slot="content">
                      <Button
                        style={"primary-text small"}
                        disabled={true}
                        click={""}
                      >
                        {$t("dashboard_new_project.remove_from_project")}
                      </Button>
                    </div>
                    <div slot="tooltip">
                      {$t("dashboard_new_project.min_one_user")}
                    </div>
                  </Tooltip>
                {:else}
                  <Button
                    style={"error-text small"}
                    disabled={users.length <= 1}
                    click={deleteUser.bind(this, value)}
                  >
                    {$t("dashboard_new_project.remove_from_project")}
                  </Button>
                {/if}
              </div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
  {#if $$slots.bottom}
    <div class={styles.submit}>
      <div class={styles.container}>
        <slot name="bottom" />
      </div>
    </div>
  {/if}
</Boundary>

<style lang="scss">
  .block {
    .wrapped & {
      border: 1px solid var(--primary-050);
      border-radius: var(--border-radius);
      background: #fff;
      margin-bottom: 25px;
      padding: 16px 0;
    }
  }

  .container {
    padding: 0;

    .wrapped & {
      padding: 0 20px;
    }
  }

  .input {
    margin-bottom: 15px;
  }

  .submit {
    margin-top: 20px;
    display: flex;
    justify-content: right;

    .wrapped & .container {
      padding: 0;
    }
  }

  .group {
    padding: 0 0 16px;

    &:not(:first-child) {
      padding: 15px 0 16px;
      border-top: solid 1px var(--primary-050);
    }
  }

  .user {
    padding: 7px 0;
    border-top: solid 1px var(--primary-050);

    &:last-child {
      border-bottom: solid 1px var(--primary-050);

      .wrapped & {
        border-bottom: none;
      }
    }
  }

  .user-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0;
  }

  .user-content {
    display: flex;
    align-items: center;
  }

  .name {
    margin-left: 15px;
    color: var(--primary-500);
  }

  .button {
    display: flex;
    justify-content: right;
    padding-right: 25px;
  }
</style>
