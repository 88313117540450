<script>
  import { onMount } from "svelte";
  import { SAMPLE_CONTACT_FIELDS_GROUPS } from "apps/sample/constants";
  import { SampleService } from "apps/sample/services";
  import {
    sample,
    showSampleEditModal,
    initializeValues,
    getUpdatedValues,
  } from "apps/sample/stores";
  import { Modal } from "components/modals";
  import { t } from "stores/i18n.js";

  import { Button } from "components";
  import { Dropdown, TextInput } from "components";
  import styles from "styleguide/SampleEditModal.json";

  let currentValues = {
    request_value: "",
    contact: {},
  };

  const fieldGroups = { ...SAMPLE_CONTACT_FIELDS_GROUPS };

  $: languages = {
    "": $t("sample.language.auto"),
    en: $t("sample.language.en"),
    de: $t("sample.language.de"),
  };

  onMount(() => {
    currentValues = initializeValues(fieldGroups);
  });

  function handleResetMasterData() {
    SampleService.resetContacts({
      params: {
        id: $sample.permalink,
      },
      onSuccess: () => {
        SampleService.getSample({
          params: {
            id: $sample.permalink,
          },
        });
        onHide();
      },
    });
  }

  function onHide() {
    SampleService.resetSampleEditModal();
  }

  function onSubmit() {
    const updatedValues = getUpdatedValues($sample, currentValues, fieldGroups);

    if (!Object.keys(updatedValues).length) {
      return onHide();
    }

    SampleService.editSample({
      params: {
        id: $sample.permalink,
        contact_attributes: {
          ...updatedValues.contact_attributes,
        },
        ...(updatedValues.request_value && {
          request_value: updatedValues.request_value,
        }),
      },
      onSuccess: () => {
        onHide();
      },
    });
  }
</script>

<Modal
  shown={$showSampleEditModal}
  {onHide}
  title={$sample.contact.company || $sample.reference}
>
  <div class={styles.wrapper} data-component="SampleEditModal">
    <TextInput
      label={$t("sample_edit_modal.value")}
      bind:value={currentValues.request_value}
    />

    <div>
      <div class={styles["master-data"]}>
        <div>{$t("sample_edit_modal.master_data")}</div>
        <Button style="primary-text small" click={handleResetMasterData}
          >{$t("sample_edit_modal.reset_button")}</Button
        >
      </div>

      <div class={styles.contacts}>
        <div class={styles.company}>
          <TextInput
            bind:value={currentValues.contact.company}
            placeholder={$t("sample_edit_modal.company")}
          />
        </div>

        <div class={styles.street}>
          <TextInput
            bind:value={currentValues.contact.street}
            placeholder={$t("sample_edit_modal.address")}
          />
        </div>

        <div class={styles.zip}>
          <TextInput
            bind:value={currentValues.contact.zip}
            placeholder={$t("sample_edit_modal.zip")}
          />
        </div>

        <div class={styles.city}>
          <TextInput
            bind:value={currentValues.contact.city}
            placeholder={$t("sample_edit_modal.city")}
          />
        </div>

        <div class={styles.country}>
          <TextInput
            bind:value={currentValues.contact.country}
            placeholder={$t("sample_edit_modal.country")}
          />
        </div>

        <div class={styles.language}>
          <Dropdown
            items={languages}
            initial={$t("sample.language.auto")}
            bind:selected={currentValues.contact.language}
          />
        </div>
      </div>
    </div>

    <TextInput
      label={$t("sample_edit_modal.contact")}
      bind:value={currentValues.contact.contact_name}
    />

    <TextInput
      label={$t("sample_edit_modal.email_address")}
      bind:value={currentValues.contact.email}
    />

    <div class={styles.submit}>
      <Button style="primary" click={onSubmit}
        >{$t("sample_edit_modal.submit_button")}</Button
      >
    </div>
  </div>
</Modal>

<style lang="scss">
  .wrapper {
    display: grid;
    gap: 20px;

    .submit {
      display: flex;
      justify-content: end;
    }
  }

  .master-data {
    width: max-content;
    display: grid;
    grid-template-columns: auto 1fr;
    align-items: center;
    gap: 10px;
  }

  .contacts {
    display: grid;
    grid-template-columns: 1fr 3fr;
    grid-template-areas:
      "company company"
      "street street"
      "zip city"
      "country country"
      "language language";
    gap: 5px;
  }

  .company {
    grid-area: company;
  }

  .street {
    grid-area: street;
  }

  .zip {
    grid-area: zip;
  }

  .city {
    grid-area: city;
  }

  .country {
    grid-area: country;
  }

  .language {
    grid-area: language;
  }
</style>
