import Api from "apis/api";
import { buildFormData } from "lib/helpers.js";

class SampleApi {
  constructor() {
    this.endpoint = "/samples";
  }

  getSample(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}`, {
      success: (response) => {
        success(response);
      },
      error,
    });
  }

  editSample(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(`${this.endpoint}/${params.id}`, {
      body: formData,
      success: (response) => {
        success(response);
      },
      error,
    });
  }

  deleteSample(options) {
    const { params, success, error } = options;

    Api.delete(`${this.endpoint}/${params.id}`, {
      success: (response) => {
        success(response);
      },
      error,
    });
  }

  getOpenPositions(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/open_positions`, {
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  validateAddress(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(this.endpoint + `/${params.id}/validate_address`, {
      body: formData,
      success,
      error,
    });
  }

  createSampleMailing(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(this.endpoint + `/${params.sample_id}/mailings`, {
      body: formData,
      success,
      error,
    });
  }

  confirmSampleMailing(options) {
    const { params, success, error } = options;

    Api.patch(
      this.endpoint + `/${params.sample_id}/mailings/${params.id}/confirm`,
      {
        success,
        error,
      },
    );
  }

  checkSampleMailingsStatus(options) {
    const { params, success, error } = options;

    Api.get(
      this.endpoint + `/${params.sample_id}/mailings/${params.id}/check_status`,
      {
        success,
        error,
      },
    );
  }

  switchSampleMailingChannel(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(
      this.endpoint +
        `/${params.sample_id}/mailings/${params.id}/switch_channel`,
      {
        body: formData,
        success,
        error,
      },
    );
  }

  disableSecondRequestBox(options) {
    const { params, success, error } = options;

    Api.patch(
      this.endpoint +
        `/${params.sample_id}/mailings/${params.id}/disable_second_request_box`,
      {
        success,
        error,
      },
    );
  }

  markAsSent(options) {
    const { params, success, error } = options;

    Api.patch(
      this.endpoint + `/${params.sample_id}/mailings/${params.id}/mark_as_sent`,
      {
        success,
        error,
      },
    );
  }

  deleteSampleMailing(options) {
    const { params, success, error } = options;

    Api.delete(this.endpoint + `/${params.sample_id}/mailings/${params.id}`, {
      success,
      error,
    });
  }

  deleteSampleResponse(options) {
    const { params, success, error } = options;

    Api.delete(this.endpoint + `/${params.sample_id}/responses/${params.id}`, {
      success,
      error,
    });
  }

  getActivities(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/activities`, {
      params: {
        page: params.page,
      },
      success: (response) => {
        success(response.data);
      },
      error,
    });
  }

  navigation(options) {
    const { params, success, error } = options;

    Api.get(`${this.endpoint}/${params.id}/navigation`, {
      params: {
        ...params.filterParams,
      },
      success,
      error,
    });
  }

  resetContacts(options) {
    const { params, success, error } = options;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.patch(`${this.endpoint}/${params.id}/contact/reset`, {
      body: formData,
      success: (response) => {
        success(response);
      },
      error,
    });
  }
}

export default new SampleApi();
