import { t } from "stores/i18n";
import { get } from "svelte/store";

import {
  LinkDownloadStatusOptions,
  ListFilterNameOptions,
} from "../../../constants";

export const getFilterDownloadStatuses = (status) => [
  {
    value: LinkDownloadStatusOptions.DOWNLOADED,
    name: ListFilterNameOptions.DOWNLOAD_STATUS,
    label: get(t)("quickshare_filter_downloads.downloaded"),
    checked: status === LinkDownloadStatusOptions.DOWNLOADED,
  },
  {
    value: LinkDownloadStatusOptions.NOT_DOWNLOADED,
    name: ListFilterNameOptions.DOWNLOAD_STATUS,
    label: get(t)("quickshare_filter_downloads.not_downloaded"),
    checked: status === LinkDownloadStatusOptions.NOT_DOWNLOADED,
  },
  {
    value: null,
    name: ListFilterNameOptions.DOWNLOAD_STATUS,
    label: get(t)("quickshare_filter_downloads.all_download"),
    checked: !status,
  },
];
