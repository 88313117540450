<script>
  import { onMount } from "svelte";

  import { QuickshareLinkEditForm } from "apps/quickshare/components";

  import { quickshareService } from "apps/quickshare/services";
  import { t } from "stores/i18n";

  import { Boundary, Button, Spinner } from "components";

  import styles from "styleguide/QuicksharePersonalInboxForm.json";

  export let onCloseForm = () => {};

  $: link = null;

  function handleUpdateLink() {
    const { reset_password, password, message, permalink, email_notification } =
      link;

    const params = {
      link: {
        reset_password: !!reset_password,
        password: password || "",
        message,
        permalink,
        email_notification,
      },
    };

    quickshareService.updateLink({
      params,
      onSuccess: () => {
        quickshareService.resetListFilters();
        quickshareService.getLinksList();
        window.scrollTo(0, 0);
        onCloseForm();
      },
    });
  }

  onMount(() => {
    quickshareService.getPersonalInbox({
      onSuccess: (res) =>
        (link = {
          ...res.data.item,
          personal_inbox: true,
        }),
    });
  });
</script>

<Boundary>
  <div data-component="QuicksharePersonalInboxForm" class={styles.container}>
    <div>
      <Button style={"primary-text"} click={onCloseForm}>
        {$t("quickshare_personal_inbox_form.cancel")}
      </Button>
    </div>

    <div class={styles["title-wrapper"]}>
      <div class={styles.title}>
        {$t("quickshare_personal_inbox_form.title")}
      </div>
      <div class={styles.subtitle}>
        {$t("quickshare_personal_inbox_form.subtitle")}
      </div>
    </div>

    {#if link}
      <QuickshareLinkEditForm {link} />

      <div>
        <Button style={"primary"} click={handleUpdateLink}>
          {$t("quickshare_personal_inbox_form.save")}
        </Button>
      </div>
    {:else}
      <div class={styles["spinner-wrapper"]}>
        <Spinner scale="0.25" color="var(--primary-100)" />
      </div>
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    min-height: 433px;
  }

  .title {
    @include typography-caption-medium;

    color: var(--primary-500);
    margin-bottom: 5px;
  }

  .subtitle {
    @include typography-body-regular;

    color: var(--primary-300);
  }

  .spinner-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
  }
</style>
