<script>
  import clsx from "clsx";

  import CopyIcon from "components/icons/CopyIcon.svelte";
  import { t } from "stores/i18n.js";

  import { copyToClickboard } from "lib/helpers.js";

  import { Button, Tooltip } from "components";

  import styles from "styleguide/CopyInput.json";

  export let value;
  export let canCopy = true;
  export let disabled = false;

  function handleOnCopy() {
    const message = $t("copy_input.message");
    copyToClickboard(value, null, message);
  }
</script>

<div class={styles.wrapper} data-component="CopyInput">
  <input
    class={clsx(styles.input, { [styles["disabled"]]: disabled })}
    {value}
    disabled={true}
  />

  {#if canCopy}
    <Tooltip message={$t("copy_input.copy")} showArrow={true}>
      <Button click={handleOnCopy} style={"icon primary-text"} {disabled}>
        <CopyIcon height="18px" width="18px" />
      </Button>
    </Tooltip>
  {/if}
</div>

<style lang="scss">
  .wrapper {
    display: flex;
    align-items: stretch;
    flex-wrap: nowrap;
    gap: 8px;
  }

  .input {
    @include typography-footnote-semibold;

    flex: 1;
    color: var(--blue-200);
    padding: 0 10px;
    background: var(--white);
    height: 40px;
    border: 1px solid var(--primary-050);
    border-radius: 8px;

    &.disabled {
      pointer-events: none;
      color: var(--primary-300);
      background: var(--primary-010);
    }
  }
</style>
