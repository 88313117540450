import { userInfo } from "apps/quickshare/stores";

class QuickshareUserService {
  constructor() {}

  setUserInfo(data) {
    const { setting, current_user, personal_inbox } = data;

    userInfo.set({
      currentUser: current_user,
      personalInbox: personal_inbox,
      setting: {
        storage: {
          limitInMb: setting.storage.limit_in_mb,
          usedInMb: setting.storage.used_in_mb,
        },
        outlook: {
          token: setting.outlook.token,
          updatedAt: setting.outlook.updated_at,
        },
      },
    });
  }

  static instance;

  static getInstance() {
    if (!QuickshareUserService.instance) {
      QuickshareUserService.instance = new QuickshareUserService();
    }
    return QuickshareUserService.instance;
  }
}

export const quickshareUserService = QuickshareUserService.getInstance();
