<script>
  export let width = 22;
  export let height = 22;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 22 22"
  {fill}
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.613873 8.30291L0.483275 8.35947C-0.172189 8.70296 -0.16963 9.68842 0.544401 10.0058L8.47088 13.5284L11.9957 21.4571C12.3329 22.2157 13.4243 22.1712 13.6986 21.3876L20.1085 3.0734C20.1185 3.04685 20.1271 3.01993 20.1345 2.99274C20.3238 2.31109 19.6904 1.67769 19.0087 1.86695C18.9815 1.87434 18.9546 1.883 18.9281 1.89294L0.613873 8.30291ZM15.7621 4.94302L3.40111 9.26894L8.9637 11.7414L15.7621 4.94302ZM10.26 13.0378L12.7316 18.5994L17.0564 6.24141L10.26 13.0378Z"
  />
</svg>
