import Api from "apis/api.js";
import { buildFormData } from "lib/helpers.js";

class QuickshareFrontendApi {
  constructor() {
    this.endpoint = "/quickshare/external";
  }

  checkLink(options) {
    const { params, success, error } = options || {};
    const { link_id, password } = params || {};

    const payload = {
      ...(password && { password }),
    };

    Api.get(`${this.endpoint}/${link_id}/check`, {
      params: payload,
      success,
      error,
    });
  }

  getFiles(options) {
    const { params, success, error } = options || {};
    const { link_id, password, sessionId } = params || {};

    const payload = {
      ...(password && { password }),
      ...(sessionId && { session_id: sessionId }),
    };

    Api.get(`${this.endpoint}/${link_id}/files`, {
      params: payload,
      success,
      error,
    });
  }

  addLinkFile(options) {
    const { params, success, error } = options;
    const { id } = params;

    const formData = new FormData();
    buildFormData(formData, params);

    Api.post(`${this.endpoint}/${id}/files`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (err) => {
        if (error) {
          error(err);
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  getLinkFilesStatus(options) {
    const { params, success, error } = options || {};
    const { id, ids, password } = params;

    const formData = new FormData();

    if (password) {
      buildFormData(formData, { password });
    }

    for (let id of ids) {
      formData.append("ids[]", id);
    }

    Api.get(`${this.endpoint}/${id}/files/status`, {
      params: formData,
      success,
      error,
    });
  }

  deleteFiles(options) {
    const { params, success, error } = options || {};

    const formData = new FormData();
    formData.append("session_id", params.sessionId);

    for (let id of params.ids) {
      formData.append("ids[]", id);
    }

    Api.delete(`${this.endpoint}/${params.permalink}/files`, {
      body: formData,
      success,
      error,
    });
  }

  confirmFilesAttachment(options) {
    const { params, success, error } = options;
    const { link_id } = params || {};

    const formData = new FormData();
    buildFormData(formData, params, null, true);

    Api.patch(`${this.endpoint}/${link_id}/send_verification`, {
      body: formData,
      success: (response) => {
        success(response.data);
      },
      error: (err) => {
        if (error) {
          error();
        } else {
          console.error("Error", err);
        }
      },
    });
  }

  downloadSingleFile(options) {
    const { params, success, error } = options || {};
    const { linkId, fileId, password, sessionId } = params || {};

    const payload = {
      file_id: fileId,
      ...(password && { password }),
      ...(sessionId && { session_id: sessionId }),
    };

    Api.get(`${this.endpoint}/${linkId}/files/download`, {
      params: payload,
      success,
      error,
    });
  }

  downloadFiles(options) {
    const { params, success, error } = options || {};
    const { linkId, excludeSuspicious, password, sessionId } = params || {};

    const payload = {
      exclude_suspicious: !!excludeSuspicious,
      ...(password && { password }),
      ...(sessionId && { session_id: sessionId }),
    };

    Api.downloadBatch(`${this.endpoint}/${linkId}/download`, {
      params: payload,
      success,
      error,
    });
  }

  static instance;

  static getInstance() {
    if (!QuickshareFrontendApi.instance) {
      QuickshareFrontendApi.instance = new QuickshareFrontendApi();
    }
    return QuickshareFrontendApi.instance;
  }
}

export const quickshareFrontendApi = QuickshareFrontendApi.getInstance();
