<script>
  import { isAccountFeature } from "stores/account";

  import { Boundary } from "components";

  import {
    QuickshareSidebarStorageInfo,
    QuickshareSidebarTabs,
    QuickshareSidebarInfo,
    QuickshareSidebarOutlookInfo,
  } from "./components";

  import styles from "styleguide/QuickshareSidebar.json";
</script>

<Boundary>
  <div data-component="QuickshareSidebar" class={styles.container}>
    <QuickshareSidebarTabs />
    <div>
      <QuickshareSidebarInfo />
      {#if $isAccountFeature("quickshare_outlook_infobox")}
        <QuickshareSidebarOutlookInfo />
      {/if}
      <QuickshareSidebarStorageInfo />
    </div>
  </div>
</Boundary>

<style lang="scss">
  .container {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
</style>
