<script>
  import { QuickshareAccessLink } from "apps/quickshare/components/index.js";

  import { SidebarLinkTypeOptions } from "apps/quickshare/constants";
  import { sidebarActiveLinkType, userInfo } from "apps/quickshare/stores";
  import { t } from "stores/i18n";

  import { Boundary, Button, Popover } from "components";
  import { QuicksharePersonalInboxForm } from "./components";

  import styles from "styleguide/QuicksharePersonalInbox.json";

  let isShowForm = false;
  const personalPermalink = { permalink: $userInfo.personalInbox?.permalink };

  function toggleInboxEdit() {
    sidebarActiveLinkType.set(SidebarLinkTypeOptions.PERSONAL);
    isShowForm = !isShowForm;
  }

  function onCloseForm() {
    sidebarActiveLinkType.set(null);
    isShowForm = false;
  }
</script>

<Boundary>
  <div data-component="QuicksharePersonalInbox" class={styles.wrapper}>
    {#if !isShowForm}
      <div class={styles["header-wrapper"]}>
        <div class={styles.header}>
          <div class={styles["title-wrapper"]}>
            <span class={styles.title}
              >{$t("quickshare_personal_inbox.title")}</span
            >

            <Popover position="bottom" showCloseButton={false}>
              <Button icon={"info"} style={"small"} />
              <div slot="content">
                {$t("quickshare_personal_inbox.popover_desc")}
              </div>
            </Popover>
          </div>

          <Button style={"primary-text small"} click={toggleInboxEdit}>
            {$t("quickshare_personal_inbox.edit_settings")}
          </Button>
        </div>

        <div class={styles.desc}>
          {$t("quickshare_personal_inbox.desc")}
        </div>
      </div>

      <QuickshareAccessLink showLabel={false} link={personalPermalink} />
    {:else}
      <QuicksharePersonalInboxForm {onCloseForm} />
    {/if}
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .header-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
  }

  .title {
    @include typography-body-medium;
  }

  .desc {
    @include typography-body-regular;

    color: var(--primary-500);
  }

  .button-done {
    padding-top: 24px;
  }
</style>
