<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  {width}
  {height}
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g clip-path="url(#clip0_868_68038)">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.3571 11.7816C19.2721 10.0012 17.8008 8.58289 15.9994 8.58289C14.1435 8.58289 12.6379 10.0885 12.6379 11.9444V13.5832H12.1108C11.175 13.5832 10.416 14.3422 10.416 15.278V19.7222C10.416 20.658 11.1749 21.4162 12.1108 21.4162H19.8886C20.8244 21.4162 21.5826 20.6581 21.5826 19.7222V15.278C21.5826 14.3421 20.8244 13.5832 19.8886 13.5832H19.361V11.9444L19.3571 11.7816ZM18.1943 13.5832V11.9444C18.1943 10.7328 17.2111 9.74955 15.9994 9.74955C14.8327 9.74955 13.8777 10.6613 13.8086 11.8108L13.8046 11.9444V13.5832H18.1943ZM13.2212 14.7499L12.1108 14.7499C11.8193 14.7499 11.5827 14.9865 11.5827 15.278V19.7222C11.5827 20.0134 11.819 20.2496 12.1108 20.2496H19.8886C20.1801 20.2496 20.4159 20.0137 20.4159 19.7222V15.278C20.4159 14.9862 20.1798 14.7499 19.8886 14.7499L18.7777 14.7499H13.2212Z"
      {fill}
    />
    <path
      d="M15.7147 0.878116C15.8045 0.601722 16.1955 0.601722 16.2853 0.878115L16.6062 1.86565C16.6464 1.98926 16.7615 2.07295 16.8915 2.07295H17.9299C18.2205 2.07295 18.3413 2.44483 18.1062 2.61565L17.2661 3.22599C17.161 3.30238 17.117 3.43779 17.1572 3.5614L17.478 4.54893C17.5678 4.82533 17.2515 5.05517 17.0164 4.88435L16.1763 4.27401C16.0712 4.19762 15.9288 4.19762 15.8237 4.27401L14.9836 4.88435C14.7485 5.05517 14.4322 4.82533 14.522 4.54894L14.8428 3.5614C14.883 3.43779 14.839 3.30238 14.7339 3.22599L13.8938 2.61565C13.6587 2.44483 13.7795 2.07295 14.0701 2.07295H15.1085C15.2385 2.07295 15.3536 1.98926 15.3938 1.86565L15.7147 0.878116Z"
      {fill}
    />
    <path
      d="M21.7147 2.87812C21.8045 2.60172 22.1955 2.60172 22.2853 2.87812L22.6062 3.86565C22.6464 3.98926 22.7615 4.07295 22.8915 4.07295H23.9299C24.2205 4.07295 24.3413 4.44483 24.1062 4.61565L23.2661 5.22599C23.161 5.30238 23.117 5.43779 23.1572 5.5614L23.478 6.54893C23.5678 6.82533 23.2515 7.05517 23.0164 6.88435L22.1763 6.27401C22.0712 6.19762 21.9288 6.19762 21.8237 6.27401L20.9836 6.88435C20.7485 7.05517 20.4322 6.82533 20.522 6.54894L20.8428 5.5614C20.883 5.43779 20.839 5.30238 20.7339 5.22599L19.8938 4.61565C19.6587 4.44483 19.7795 4.07295 20.0701 4.07295H21.1085C21.2385 4.07295 21.3536 3.98926 21.3938 3.86565L21.7147 2.87812Z"
      {fill}
    />
    <path
      d="M26.7147 7.87812C26.8045 7.60172 27.1955 7.60172 27.2853 7.87812L27.6062 8.86565C27.6464 8.98926 27.7615 9.07295 27.8915 9.07295H28.9299C29.2205 9.07295 29.3413 9.44483 29.1062 9.61565L28.2661 10.226C28.161 10.3024 28.117 10.4378 28.1572 10.5614L28.478 11.5489C28.5678 11.8253 28.2515 12.0552 28.0164 11.8843L27.1763 11.274C27.0712 11.1976 26.9288 11.1976 26.8237 11.274L25.9836 11.8843C25.7485 12.0552 25.4322 11.8253 25.522 11.5489L25.8428 10.5614C25.883 10.4378 25.839 10.3024 25.7339 10.226L24.8938 9.61565C24.6587 9.44483 24.7795 9.07295 25.0701 9.07295H26.1085C26.2385 9.07295 26.3536 8.98926 26.3938 8.86565L26.7147 7.87812Z"
      {fill}
    />
    <path
      d="M28.7147 13.8781C28.8045 13.6017 29.1955 13.6017 29.2853 13.8781L29.6062 14.8657C29.6464 14.9893 29.7615 15.0729 29.8915 15.0729H30.9299C31.2205 15.0729 31.3413 15.4448 31.1062 15.6157L30.2661 16.226C30.161 16.3024 30.117 16.4378 30.1572 16.5614L30.478 17.5489C30.5678 17.8253 30.2515 18.0552 30.0164 17.8843L29.1763 17.274C29.0712 17.1976 28.9288 17.1976 28.8237 17.274L27.9836 17.8843C27.7485 18.0552 27.4322 17.8253 27.522 17.5489L27.8428 16.5614C27.883 16.4378 27.839 16.3024 27.7339 16.226L26.8938 15.6157C26.6587 15.4448 26.7795 15.0729 27.0701 15.0729H28.1085C28.2385 15.0729 28.3536 14.9893 28.3938 14.8657L28.7147 13.8781Z"
      {fill}
    />
    <path
      d="M26.7147 19.8781C26.8045 19.6017 27.1955 19.6017 27.2853 19.8781L27.6062 20.8657C27.6464 20.9893 27.7615 21.0729 27.8915 21.0729H28.9299C29.2205 21.0729 29.3413 21.4448 29.1062 21.6157L28.2661 22.226C28.161 22.3024 28.117 22.4378 28.1572 22.5614L28.478 23.5489C28.5678 23.8253 28.2515 24.0552 28.0164 23.8843L27.1763 23.274C27.0712 23.1976 26.9288 23.1976 26.8237 23.274L25.9836 23.8843C25.7485 24.0552 25.4322 23.8253 25.522 23.5489L25.8428 22.5614C25.883 22.4378 25.839 22.3024 25.7339 22.226L24.8938 21.6157C24.6587 21.4448 24.7795 21.0729 25.0701 21.0729H26.1085C26.2385 21.0729 26.3536 20.9893 26.3938 20.8657L26.7147 19.8781Z"
      {fill}
    />
    <path
      d="M21.7147 24.8781C21.8045 24.6017 22.1955 24.6017 22.2853 24.8781L22.6062 25.8657C22.6464 25.9893 22.7615 26.0729 22.8915 26.0729H23.9299C24.2205 26.0729 24.3413 26.4448 24.1062 26.6157L23.2661 27.226C23.161 27.3024 23.117 27.4378 23.1572 27.5614L23.478 28.5489C23.5678 28.8253 23.2515 29.0552 23.0164 28.8843L22.1763 28.274C22.0712 28.1976 21.9288 28.1976 21.8237 28.274L20.9836 28.8843C20.7485 29.0552 20.4322 28.8253 20.522 28.5489L20.8428 27.5614C20.883 27.4378 20.839 27.3024 20.7339 27.226L19.8938 26.6157C19.6587 26.4448 19.7795 26.0729 20.0701 26.0729H21.1085C21.2385 26.0729 21.3536 25.9893 21.3938 25.8657L21.7147 24.8781Z"
      {fill}
    />
    <path
      d="M15.7147 26.8781C15.8045 26.6017 16.1955 26.6017 16.2853 26.8781L16.6062 27.8657C16.6464 27.9893 16.7615 28.0729 16.8915 28.0729H17.9299C18.2205 28.0729 18.3413 28.4448 18.1062 28.6157L17.2661 29.226C17.161 29.3024 17.117 29.4378 17.1572 29.5614L17.478 30.5489C17.5678 30.8253 17.2515 31.0552 17.0164 30.8843L16.1763 30.274C16.0712 30.1976 15.9288 30.1976 15.8237 30.274L14.9836 30.8843C14.7485 31.0552 14.4322 30.8253 14.522 30.5489L14.8428 29.5614C14.883 29.4378 14.839 29.3024 14.7339 29.226L13.8938 28.6157C13.6587 28.4448 13.7795 28.0729 14.0701 28.0729H15.1085C15.2385 28.0729 15.3536 27.9893 15.3938 27.8657L15.7147 26.8781Z"
      {fill}
    />
    <path
      d="M9.71468 24.8781C9.80449 24.6017 10.1955 24.6017 10.2853 24.8781L10.6062 25.8657C10.6464 25.9893 10.7615 26.0729 10.8915 26.0729H11.9299C12.2205 26.0729 12.3413 26.4448 12.1062 26.6157L11.2661 27.226C11.161 27.3024 11.117 27.4378 11.1572 27.5614L11.478 28.5489C11.5678 28.8253 11.2515 29.0552 11.0164 28.8843L10.1763 28.274C10.0712 28.1976 9.92881 28.1976 9.82366 28.274L8.98361 28.8843C8.7485 29.0552 8.43216 28.8253 8.52196 28.5489L8.84283 27.5614C8.88299 27.4378 8.839 27.3024 8.73385 27.226L7.8938 26.6157C7.65869 26.4448 7.77952 26.0729 8.07014 26.0729H9.1085C9.23846 26.0729 9.35365 25.9893 9.39381 25.8657L9.71468 24.8781Z"
      {fill}
    />
    <path
      d="M4.71468 19.8781C4.80449 19.6017 5.19551 19.6017 5.28532 19.8781L5.60619 20.8657C5.64635 20.9893 5.76154 21.0729 5.8915 21.0729H6.92986C7.22048 21.0729 7.34131 21.4448 7.1062 21.6157L6.26615 22.226C6.161 22.3024 6.11701 22.4378 6.15717 22.5614L6.47804 23.5489C6.56784 23.8253 6.2515 24.0552 6.01639 23.8843L5.17634 23.274C5.07119 23.1976 4.92881 23.1976 4.82366 23.274L3.98361 23.8843C3.7485 24.0552 3.43216 23.8253 3.52196 23.5489L3.84283 22.5614C3.88299 22.4378 3.839 22.3024 3.73385 22.226L2.8938 21.6157C2.65869 21.4448 2.77952 21.0729 3.07014 21.0729H4.1085C4.23846 21.0729 4.35365 20.9893 4.39381 20.8657L4.71468 19.8781Z"
      {fill}
    />
    <path
      d="M2.71468 13.8781C2.80449 13.6017 3.19551 13.6017 3.28532 13.8781L3.60619 14.8657C3.64635 14.9893 3.76154 15.0729 3.8915 15.0729H4.92986C5.22048 15.0729 5.34131 15.4448 5.1062 15.6157L4.26615 16.226C4.161 16.3024 4.11701 16.4378 4.15717 16.5614L4.47804 17.5489C4.56784 17.8253 4.2515 18.0552 4.01639 17.8843L3.17634 17.274C3.07119 17.1976 2.92881 17.1976 2.82366 17.274L1.98361 17.8843C1.7485 18.0552 1.43216 17.8253 1.52196 17.5489L1.84283 16.5614C1.88299 16.4378 1.839 16.3024 1.73385 16.226L0.8938 15.6157C0.658686 15.4448 0.779519 15.0729 1.07014 15.0729H2.1085C2.23846 15.0729 2.35365 14.9893 2.39381 14.8657L2.71468 13.8781Z"
      {fill}
    />
    <path
      d="M4.71468 7.87812C4.80449 7.60172 5.19551 7.60172 5.28532 7.87812L5.60619 8.86565C5.64635 8.98926 5.76154 9.07295 5.8915 9.07295H6.92986C7.22048 9.07295 7.34131 9.44483 7.1062 9.61565L6.26615 10.226C6.161 10.3024 6.11701 10.4378 6.15717 10.5614L6.47804 11.5489C6.56784 11.8253 6.2515 12.0552 6.01639 11.8843L5.17634 11.274C5.07119 11.1976 4.92881 11.1976 4.82366 11.274L3.98361 11.8843C3.7485 12.0552 3.43216 11.8253 3.52196 11.5489L3.84283 10.5614C3.88299 10.4378 3.839 10.3024 3.73385 10.226L2.8938 9.61565C2.65869 9.44483 2.77952 9.07295 3.07014 9.07295H4.1085C4.23846 9.07295 4.35365 8.98926 4.39381 8.86565L4.71468 7.87812Z"
      {fill}
    />
    <path
      d="M9.71468 2.87812C9.80449 2.60172 10.1955 2.60172 10.2853 2.87812L10.6062 3.86565C10.6464 3.98926 10.7615 4.07295 10.8915 4.07295H11.9299C12.2205 4.07295 12.3413 4.44483 12.1062 4.61565L11.2661 5.22599C11.161 5.30238 11.117 5.43779 11.1572 5.5614L11.478 6.54893C11.5678 6.82533 11.2515 7.05517 11.0164 6.88435L10.1763 6.27401C10.0712 6.19762 9.92881 6.19762 9.82366 6.27401L8.98361 6.88435C8.7485 7.05517 8.43216 6.82533 8.52196 6.54894L8.84283 5.5614C8.88299 5.43779 8.839 5.30238 8.73385 5.22599L7.8938 4.61565C7.65869 4.44483 7.77952 4.07295 8.07014 4.07295H9.1085C9.23846 4.07295 9.35365 3.98926 9.39381 3.86565L9.71468 2.87812Z"
      {fill}
    />
  </g>
</svg>
