<script>
  import { t } from "stores/i18n";

  import { Boundary, Button } from "components";

  import { LinkType } from "../../constants";
  import { link } from "../../stores";

  import styles from "styleguide/QuickshareLandingWelcomeScreen.json";

  export let onAction = () => {};

  const { owner, linkType } = $link;
</script>

<Boundary>
  <div data-component="QuickshareLandingWelcomeScreen" class={styles.wrapper}>
    <div class={styles.title}>
      {$t(
        linkType === LinkType.REQUEST
          ? "quickshare_frontend_landing_welcome.header_request"
          : "quickshare_frontend_landing_welcome.header_share",
        {
          user: owner.name,
        },
      )}
    </div>

    <Button style={"primary"} click={onAction}>
      {$t(
        linkType === LinkType.REQUEST
          ? "quickshare_frontend_landing_welcome.show"
          : "quickshare_frontend_landing_welcome.show_files",
      )}
    </Button>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    padding: 110px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
    text-align: center;
  }

  .title {
    @include typography-caption-medium;

    margin-bottom: 8px;
  }
</style>
