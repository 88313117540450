<script>
  import styles from "styleguide/AuditRollForward.json";

  import { t } from "stores/i18n.js";
  import { toasts } from "stores/toasts.js";
  import {
    audit,
    activeRollForward,
    filteredControlGroups,
  } from "stores/pbc.js";

  import { imagePath } from "lib/helpers.js";
  import RollForwardsApi from "apis/pbc/roll_forwards.js";

  import { AuditRollForwardGroup } from "apps/pbc";

  import { Boundary, Button, Checkbox, TextInput, Toast } from "components";
  import { Modal } from "components/modals";

  let dirty;
  let polling;
  let loading;
  let newTitle = "";
  let copy_files = {};
  let transfer_control = {};
  let allPermalinks = [];
  $: list = makelist($filteredControlGroups);

  const onHide = function () {
    activeRollForward.set(false);
  };

  function updateList(param, permalink, value) {
    if (param === "copy_files" || !value) {
      let newObj = copy_files;
      newObj[permalink] = value;
      copy_files = newObj;
    }

    if (param === "transfer_control" || value) {
      let newObj = transfer_control;
      newObj[permalink] = value;
      transfer_control = newObj;
    }
  }

  function makelist(groups) {
    allPermalinks = [];

    return groups.map((item) => {
      let name = item.__proto__.name;
      let control_templates = item.__proto__.controls;
      allPermalinks = [
        ...allPermalinks,
        ...control_templates.map((item) => item.permalink),
      ];
      allPermalinks.forEach(
        (permalink) => (transfer_control[permalink] = true),
      );

      return {
        name,
        control_templates,
      };
    });
  }

  function checkStatus(id) {
    polling = setInterval(() => {
      RollForwardsApi.check({
        params: {
          id,
        },
        success: (response) => {
          if (response.data.status === "done") {
            loading = false;
            clearInterval(polling);
            toasts.send({
              title: $t("audit_roll_forward.success"),
              type: "success",
            });
            const path = response.data.path;
            if (path) {
              window.location = path;
            }
          }
        },
        error: () => {
          loading = false;
          clearInterval(polling);
          toasts.send({ title: $t("errors.check_entries"), type: "error" });
        },
      });
    }, 1000);
  }

  function submit() {
    dirty = true;

    if (newTitle) {
      loading = true;

      RollForwardsApi.create({
        params: {
          id: $audit.permalink,
          name: newTitle,
          client_id: $audit.client_id,
          control_ids: Object.keys(transfer_control).filter(
            (id) => transfer_control[id],
          ),
          file_control_ids: Object.keys(copy_files).filter(
            (id) => copy_files[id],
          ),
        },
        success: (response) => {
          checkStatus(response.data.id);
        },
        error: () => {
          toasts.send({ title: $t("errors.check_entries"), type: "error" });
        },
      });
    }
  }
</script>

<Boundary>
  {#if $activeRollForward}
    <Modal
      {onHide}
      shown={$activeRollForward}
      title={$t("audit_roll_forward.title")}
    >
      <div data-component="AuditRollForward">
        <div class={styles.header}>
          <div class={styles.input}>
            <TextInput
              label={$t("audit_roll_forward.client")}
              bind:value={$audit.client_name}
              disabled
            />
          </div>
          <div class={styles.input}>
            <TextInput
              label={$t("audit_roll_forward.initial_title")}
              bind:value={$audit.name}
              disabled
            />
          </div>
          <div class={styles.input}>
            <TextInput
              label={$t("audit_roll_forward.new_title")}
              bind:value={newTitle}
              errors={dirty && !newTitle ? [$t("validation.required")] : []}
            />
          </div>

          <Toast message={$t("audit_roll_forward.description")} type="info" />

          <div class={styles["client-info"]}>
            <div class={styles.client} />
            <div class="{styles.description} {styles.top}">
              {$t("audit_roll_forward.copy_files")}
            </div>
          </div>
          <div class={styles["client-info"]}>
            <div class={styles["client-description"]} />

            <div class={styles.checkboxes}>
              <div class={styles.description}>
                {$t("audit_roll_forward.transfer_control")}
              </div>

              <div class={styles.checkbox}>
                <Checkbox
                  toggle={(value) =>
                    allPermalinks.forEach((permalink) =>
                      updateList("transfer_control", permalink, value),
                    )}
                  toggled={allPermalinks.every(
                    (permalink) => transfer_control[permalink],
                  )}
                />
              </div>
              <div class={styles.checkbox}>
                <Checkbox
                  toggle={(value) =>
                    allPermalinks.forEach((permalink) =>
                      updateList("copy_files", permalink, value),
                    )}
                  toggled={allPermalinks.every(
                    (permalink) => copy_files[permalink],
                  )}
                />
              </div>
            </div>
          </div>
        </div>

        <div class={styles.list}>
          {#if list}
            {#each list as item}
              <AuditRollForwardGroup
                {...item}
                {updateList}
                {copy_files}
                {transfer_control}
              />
            {/each}
          {/if}
        </div>
      </div>

      <svelte:fragment slot="bottom">
        {#if loading}
          <div class={styles.loading}>
            <img
              class={styles.loading}
              src={imagePath("loading-spinner")}
              alt={""}
            />
            {$t("audit_roll_forward.loading")}
          </div>
        {:else}
          <Button style={"primary"} click={submit}
            >{$t("audit_roll_forward.submit")}</Button
          >
        {/if}
      </svelte:fragment>
    </Modal>
  {/if}
</Boundary>

<style lang="scss">
  .header {
    padding: 0 0 16px;
  }

  .input {
    margin-bottom: 16px;
  }

  .client {
    display: flex;
    margin-top: 15px;
  }

  .client-name {
    margin-left: 25px;
  }

  .client-info {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  .client-description {
    display: flex;
    align-items: center;
    margin-top: 4px;
  }

  .description {
    margin-left: 10px;
    color: var(--primary-300);

    &.top {
      display: flex;
      align-items: flex-end;

      &::after {
        display: block;
        content: "";
        width: 11px;
        height: 9px;
        margin: 0 9px;
        border: 2px solid var(--primary-100);
        border-left: none;
        border-bottom: none;
        border-radius: 2px;
        border-top-left-radius: unset;
        border-bottom-right-radius: unset;
      }
    }
  }

  .list {
    padding: 10px 0 0;
    border-top: 1px solid var(--primary-050);

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .checkboxes {
    display: flex;
    align-items: flex-end;

    .description {
      line-height: 18px;
      margin-right: -5px;
    }
  }

  .checkbox {
    margin-left: 15px;
  }

  .loading {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
</style>
