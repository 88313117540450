<script>
  import { QuickshareLinkEditForm } from "apps/quickshare/components";
  import { Boundary } from "components";

  import { QuickshareGroupLinkSettingsActions } from "./components";

  import styles from "styleguide/QuickshareGroupLinkSettings.json";

  export let link;

  const linkData = structuredClone(link);

  let isSudmitted = false;
  $: validationErrors = {};

  function onChangeValidators(errors) {
    validationErrors = { ...errors };
  }

  function onSubmit() {
    isSudmitted = true;
  }
</script>

<Boundary>
  <div class={styles.wrapper} data-component="QuickshareGroupLinkSettings">
    <QuickshareLinkEditForm
      link={linkData}
      isTwoColumns={true}
      isRegenerateLink={true}
      {onChangeValidators}
      isSubmittedExternal={isSudmitted}
    />
    <QuickshareGroupLinkSettingsActions
      link={linkData}
      {validationErrors}
      {onSubmit}
    />
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
</style>
