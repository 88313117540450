<script>
  import { quickshareFrontendUploadService } from "apps/quickshareFrontend/services";

  import {
    isVerificationEmailSent,
    uploadingFiles,
  } from "apps/quickshareFrontend/stores";
  import { t } from "stores/i18n";

  import { Boundary, DropzoneLight } from "components";

  import { QuickshareLandingUploadFiles } from "./components";
  import { QuickshareLandingStep } from "../QuickshareLandingStep";

  import styles from "styleguide/QuickshareLandingStepUploadFiles.json";

  export let isActiveState = false;

  function handleAddFiles(files) {
    quickshareFrontendUploadService.uploadFilesToCache([...files]);
  }
</script>

<Boundary>
  <div data-component="QuickshareLandingStepUploadFiles">
    <QuickshareLandingStep
      step={1}
      title={$t("quickshare_frontend_landing_step_upload.upload")}
      {isActiveState}
    >
      <div slot="content">
        <div class={styles.dropzone}>
          <DropzoneLight
            onAdd={handleAddFiles}
            disabled={$isVerificationEmailSent}
          />
        </div>
        {#if $uploadingFiles.length > 0}
          <QuickshareLandingUploadFiles
            files={$uploadingFiles}
            frontend={true}
          />
        {/if}
      </div>
    </QuickshareLandingStep>
  </div>
</Boundary>

<style lang="scss">
  .dropzone {
    padding-bottom: 24px;
  }
</style>
