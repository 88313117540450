<script>
  import { onMount, setContext } from "svelte";

  import { account } from "stores/account.js";
  import { breadcrumb } from "stores/breadcrumb.js";
  import { initializeI18N } from "stores/i18n.js";
  import { licenseKeys } from "stores/license_keys.js";
  import { permissions } from "stores/permission.js";
  import * as themeStore from "stores/theme.js";
  import { user } from "stores/user.js";

  import {
    Boundary,
    DocumentViewer,
    MainNavigation,
    NavigationBar,
    Theme,
  } from "components";
  import Devtools from "../../utilities/Devtools.svelte";

  export let currentUser;
  export let language;
  export let theme;
  export let container;
  export let initialBreadcrumb;
  export let currentAccount;
  export let settings;
  export let licenses;

  let i18nInitialized = false;

  setContext("config", {
    language: language,
  });

  licenseKeys.set(licenses);

  if (currentUser) {
    user.set(currentUser);
    permissions.set(currentUser.permissions);
  }
  account.set(currentAccount);

  breadcrumb.set(initialBreadcrumb);
  themeStore.theme.set(theme);

  onMount(async () => {
    i18nInitialized = false;
    initializeI18N(language).then(() => {
      i18nInitialized = true;
      document.querySelector("#container--body")?.classList?.remove("hidden");
    });
  });
</script>

<Boundary>
  <Theme name={theme} {container}>
    {#if i18nInitialized}
      {#if !settings.frontend_app}
        <MainNavigation />
        <NavigationBar />
      {/if}

      <slot />
      <DocumentViewer />
      <Devtools />
    {/if}
  </Theme>
</Boundary>
