<script>
  export let width = 18;
  export let height = 18;
  export let fill = "currentColor";
</script>

<svg
  xmlns="http://www.w3.org/2000/svg"
  {width}
  {height}
  viewBox="0 0 18 18"
  fill="none"
>
  <path
    d="M16.7197 0.21967C17.0126 -0.0732233 17.4874 -0.0732233 17.7803 0.21967C18.0466 0.485936 18.0708 0.9026 17.8529 1.19621L17.7803 1.28033L1.28033 17.7803C0.987437 18.0732 0.512563 18.0732 0.21967 17.7803C-0.0465966 17.5141 -0.0708027 17.0974 0.147052 16.8038L0.21967 16.7197L16.7197 0.21967Z"
    {fill}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M11.1114 7.95223C11.3035 7.75994 11.349 7.4585 11.1784 7.24688C10.6661 6.61126 9.88118 6.20459 9.00108 6.20459C7.45708 6.20459 6.20508 7.45617 6.20508 8.99959C6.20508 9.88093 6.61284 10.667 7.25 11.1795C7.46188 11.3499 7.76341 11.3041 7.95557 11.1117C8.30389 10.763 8.09681 10.0505 7.86152 9.6174C7.76175 9.43376 7.70508 9.22329 7.70508 8.99959C7.70508 8.28471 8.28539 7.70459 9.00108 7.70459C9.22389 7.70459 9.43353 7.76084 9.6166 7.8599C10.0501 8.09447 10.7631 8.30094 11.1114 7.95223ZM8.95785 11.6873C8.91763 11.7276 8.94417 11.7956 9.00108 11.7956C10.5452 11.7956 11.7961 10.5439 11.7961 8.99959C11.7961 8.94182 11.727 8.91495 11.6862 8.95582L8.95785 11.6873Z"
    {fill}
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M9 2.79559C6.7604 2.79559 4.78659 3.83869 3.09388 5.57064C2.49653 6.18182 1.97044 6.84411 1.51563 7.52052C1.26813 7.88863 1.07246 8.21384 0.929446 8.47486L0.832702 8.65723C0.723696 8.86969 0.722379 9.12136 0.829155 9.33495L0.932782 9.53149C1.03625 9.71978 1.18241 9.96733 1.37061 10.2588C1.80643 10.9337 2.32064 11.6082 2.91294 12.24C3.43422 12.7961 3.98345 13.2866 4.56022 13.7004C4.83748 13.8993 5.21521 13.8543 5.45635 13.6128C5.7993 13.2695 5.71908 12.6915 5.32896 12.4029C4.87182 12.0647 4.43105 11.6662 4.00731 11.2142C3.48104 10.6528 3.0205 10.0487 2.63073 9.44509L2.45102 9.15899L2.42478 9.11512C2.38153 9.04284 2.38092 8.95279 2.42318 8.87993C2.82529 8.20031 3.41238 7.39079 4.16662 6.61908C5.60155 5.15089 7.21999 4.29559 9 4.29559C10.2754 4.29559 11.467 4.73451 12.5656 5.52062C12.8847 5.74899 13.3264 5.73378 13.6037 5.45612C13.9133 5.14612 13.8965 4.63568 13.5439 4.37559C12.1827 3.37153 10.6663 2.79559 9 2.79559ZM15.6086 6.35051C15.3367 6.02392 14.8453 6.01707 14.545 6.31777C14.2594 6.60362 14.2529 7.06269 14.5093 7.37492C14.8256 7.76009 15.1127 8.15733 15.3692 8.55438L15.4639 8.70294C15.5742 8.88525 15.5744 9.11423 15.4638 9.29642C15.4339 9.34415 15.4023 9.39372 15.3692 9.44502C14.9793 10.0486 14.5187 10.6527 13.9923 11.2141C12.5233 12.7812 10.8498 13.7046 9 13.7046C8.61306 13.7046 8.23384 13.6642 7.8626 13.586C7.57628 13.5257 7.27339 13.5977 7.0666 13.8047C6.66724 14.2046 6.80422 14.8795 7.3538 15.011C7.88508 15.1381 8.43388 15.2046 9 15.2046C11.3257 15.2046 13.3595 14.0824 15.0867 12.24C15.679 11.6082 16.1933 10.9338 16.6292 10.2589L16.8349 9.93117C16.9894 9.67731 17.1016 9.47349 17.1708 9.33505C17.2764 9.12386 17.2764 8.87527 17.1708 8.66408L17.0671 8.46759C16.9636 8.27933 16.8174 8.03184 16.6292 7.74043C16.3267 7.27222 15.9865 6.80422 15.6086 6.35051Z"
    {fill}
  />
</svg>
