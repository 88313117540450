<script>
  import { Modal } from "components/modals";
  import { t } from "stores/i18n";

  import { Button, Switch } from "components";

  import styles from "styleguide/DownloadFilesVirusModal.json";

  export let isShowModal = false;
  export let isDownloadLimit = false;
  export let isAllInfected = false;
  export let isSingleFile = false;
  export let onHide = () => {};
  export let onSubmit = () => {};

  $: isIncludeInfected = true;

  function handleHide() {
    onHide();
    isIncludeInfected = true;
  }

  function handleSubmit() {
    onSubmit(isIncludeInfected);
    isIncludeInfected = true;
  }
</script>

<div data-component="DownloadFilesVirusModal">
  <Modal
    shown={isShowModal}
    onHide={handleHide}
    title={$t("quickshare_download_files_virus_modal.virus_detected")}
  >
    <div class={styles.content}>
      {#if isAllInfected}
        <span>
          {$t("quickshare_download_files_virus_modal.all_virus_modal_desc", {
            postProcess: "interval",
            count: isSingleFile ? 1 : 2,
          })}
        </span>
      {:else}
        <span>
          {$t("quickshare_download_files_virus_modal.virus_modal_desc")}
        </span>

        {#if isDownloadLimit}
          <span>
            <span class={styles.note}>
              {$t("quickshare_download_files_virus_modal.note_title")}
            </span>
            <span>{$t("quickshare_download_files_virus_modal.note_desc")}</span>
          </span>
        {/if}

        <div class={styles.toggle}>
          <Switch bind:toggled={isIncludeInfected} />
          <span
            >{$t("quickshare_download_files_virus_modal.include_virus")}</span
          >
        </div>
      {/if}
    </div>

    <svelte:fragment slot="bottom">
      <div class={styles.footer}>
        <Button click={handleHide} style={"primary-text"}>
          {$t("quickshare_download_files_virus_modal.cancel")}
        </Button>
        <Button click={handleSubmit} style={"primary"}>
          {$t("quickshare_download_files_virus_modal.download", {
            postProcess: "interval",
            count: isSingleFile ? 1 : 2,
          })}
        </Button>
      </div>
    </svelte:fragment>
  </Modal>
</div>

<style lang="scss">
  .footer {
    display: flex;
    gap: 16px;
  }

  .content {
    @include typography-body-regular;

    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .toggle {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .note {
    @include typography-body-semibold;
  }
</style>
