import { SampleApi } from "apps/sample/api";
import {
  sample,
  showOpenPositionsModal,
  openPositionsItems,
  showSampleEditModal,
  updateAddressValidated,
  updateMailings,
  updateSampleMailings,
  showSampleDeleteModal,
} from "apps/sample/stores";
import { t } from "stores/i18n.js";
import { toasts } from "stores/toasts.js";
import { get } from "svelte/store";

class SampleService {
  constructor() {}

  getSample({ onSuccess, onError, params = {} }) {
    SampleApi.getSample({
      params,
      success: (responce) => {
        sample.set(responce.data);
        onSuccess?.(responce.data);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }

  editSample({ onSuccess, onError, params = {} }) {
    SampleApi.editSample({
      params,
      success: (responce) => {
        sample.set(responce.data);
        toasts.send({
          title: get(t)("sample_details.card_actions.flash_update_success"),
          type: "success",
        });
        onSuccess?.(responce.data);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_details.card_actions.flash_update_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  deleteSample({ onSuccess, onError, params = {} }) {
    SampleApi.deleteSample({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_details.card_actions.delete_item_success"),
          type: "success",
        });
        onSuccess?.(responce);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_details.card_actions.delete_item_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  getOpenPositions({ onSuccess, onError, params = {} }) {
    SampleApi.getOpenPositions({
      params,
      success: (responce) => {
        openPositionsItems.set(responce);
        onSuccess?.(responce);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }

  validateAddress({ onSuccess, onError, params = {} }) {
    SampleApi.validateAddress({
      params,
      success: (responce) => {
        updateAddressValidated(responce.data.address_validated);
        onSuccess?.(responce.data);
      },
      error: (error) => {
        toasts.send({ title: get(t)("errors.check_entries"), type: "error" });
        onError?.(error);
      },
    });
  }

  createSampleMailing({ onSuccess, onError, params = {} }) {
    SampleApi.createSampleMailing({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_details.card_actions.new_request_success"),
          type: "success",
        });
        onSuccess?.(responce.data);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_details.card_actions.new_request_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  confirmSampleMailing({ onSuccess, onError, params = {} }) {
    SampleApi.confirmSampleMailing({
      params,
      success: (responce) => {
        updateMailings(responce.data.sample_mailing);
        toasts.send({
          title: get(t)("sample_mailing_actions.flash_create_success"),
          type: "success",
        });
        onSuccess?.(responce.data);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_mailing_actions.flash_create_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  checkSampleMailingsStatus({ onSuccess, onError, params = {} }) {
    SampleApi.checkSampleMailingsStatus({
      params,
      success: (responce) => {
        if (responce.data.stored) {
          updateMailings(responce.data.sample_mailing);
        }
        onSuccess?.(responce.data);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }

  switchSampleMailingChannel({ onSuccess, onError, params = {} }) {
    SampleApi.switchSampleMailingChannel({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_mailing_actions.send_via_success"),
          type: "success",
        });
        updateSampleMailings(responce.data.sample_mailings);
        onSuccess?.(responce.data);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }

  disableSecondRequestBox({ onSuccess, onError, params = {} }) {
    SampleApi.disableSecondRequestBox({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)(
            `sample_mailing_actions.second_request_box_${params.requestState}_success`,
          ),
          type: "success",
        });
        updateMailings(responce.data.sample_mailing);
        onSuccess?.(responce);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }

  markAsSent({ onSuccess, onError, params = {} }) {
    SampleApi.markAsSent({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_mailing_actions.mark_as_sent_success"),
          type: "success",
        });
        onSuccess?.(responce);
      },
      error: (error) => {
        onError?.(error);
      },
    });
  }

  deleteSampleMailing({ onSuccess, onError, params = {} }) {
    SampleApi.deleteSampleMailing({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_mailing_actions.remove_cover_letter_success"),
          type: "success",
        });
        onSuccess?.(responce);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_mailing_actions.remove_cover_letter_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  deleteSampleResponse({ onSuccess, onError, params = {} }) {
    SampleApi.deleteSampleResponse({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_response.delete_answer_success"),
          type: "success",
        });
        onSuccess?.(responce);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_response.delete_answer_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  resetContacts({ onSuccess, onError, params = {} }) {
    SampleApi.resetContacts({
      params,
      success: (responce) => {
        toasts.send({
          title: get(t)("sample_edit_modal.reset_data_success"),
          type: "success",
        });
        onSuccess?.(responce.data);
      },
      error: (error) => {
        toasts.send({
          title: get(t)("sample_edit_modal.reset_data_error"),
          type: "error",
        });
        onError?.(error);
      },
    });
  }

  resetOpenPositionsModal() {
    const isShown = get(showOpenPositionsModal);

    showOpenPositionsModal.set(!isShown);
  }

  resetSampleEditModal() {
    const isShown = get(showSampleEditModal);

    showSampleEditModal.set(!isShown);
  }

  resetSampleDeleteModal() {
    const isShown = get(showSampleDeleteModal);

    showSampleDeleteModal.set(!isShown);
  }
}

export default new SampleService();
