<script>
  import { userInfo } from "apps/quickshare/stores/index.js";
  import { t } from "stores/i18n";

  import { Boundary, Button, CopyInput, Link, Toast } from "components";

  import styles from "styleguide/QuickshareSidebarOutlookInfo.json";

  $: isActive = false;

  function handleToggle() {
    isActive = !isActive;
  }

  const href =
    "https://appsource.microsoft.com/en-sg/product/office/WA200007757";
</script>

<Boundary>
  <div data-component="QuickshareSidebarOutlookInfo">
    <Toast close={false} type={"info"}>
      <div class={styles.wrapper}>
        <div class={styles.header}>
          <div class={styles.title}>
            {$t("quickshare_outlook_info.title")}
          </div>

          <Button style={"primary-text fit-content"} click={handleToggle}>
            {isActive
              ? $t("quickshare_outlook_info.collapse")
              : $t("quickshare_outlook_info.expand")}
          </Button>
        </div>

        {#if isActive}
          <div class={styles.content}>
            <div class={styles.text}>
              {$t("quickshare_outlook_info.info")}
            </div>

            <div class={styles["content-container"]}>
              <div class={styles.title}>
                {$t("quickshare_outlook_info.work")}
              </div>

              <ul class={styles.list}>
                <li class={styles["list-item"]}>
                  <span class={styles.subtitle}>
                    {$t("quickshare_outlook_info.setup")}
                  </span>
                  <Link
                    {href}
                    label={$t("quickshare_outlook_info.link")}
                    target="_blank"
                  />
                  <span class={styles.text}>
                    {$t("quickshare_outlook_info.setup_info")}
                  </span>
                </li>

                <li class={styles["list-item"]}>
                  <span class={styles.subtitle}>
                    {$t("quickshare_outlook_info.token_access")}
                  </span>
                  <span class={styles.text}>
                    {$t("quickshare_outlook_info.token_access_info")}
                  </span>
                </li>
              </ul>
            </div>

            <div class={styles["content-container"]}>
              <div class={styles.title}>
                {$t("quickshare_outlook_info.token")}
              </div>
              <CopyInput value={$userInfo?.setting.outlook.token} />
            </div>
          </div>
        {/if}
      </div>
    </Toast>
  </div>
</Boundary>

<style lang="scss">
  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: var(--primary-500);
  }

  .header {
    display: grid;
    grid-template-columns: 1fr max-content;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
  }

  .title {
    @include typography-body-semibold;
  }

  .subtitle {
    @include typography-body-medium;
  }

  .text {
    @include typography-body-regular;
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .list {
    margin-left: 24px;
  }

  .list-item {
    list-style-type: disc;
  }
</style>
