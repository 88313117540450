<script>
  import { LinkType } from "apps/quickshare/constants";

  import { t } from "stores/i18n";

  import { Boundary, Button, Checkbox, Popover } from "components";

  export let linkType;
  export let handleTwoWayToggle;

  $: isTwoWay = linkType === LinkType.TWO_WAY;
</script>

<Boundary>
  <div data-component="QuickshareLinkTwoWayField">
    <Checkbox
      label={$t("quickshare_link_two_way.checkbox_label")}
      toggled={isTwoWay}
      toggle={handleTwoWayToggle}
    >
      <Popover position="top" showCloseButton={false}>
        <Button icon={"info"} style={"small"} />
        <div slot="content">
          {$t("quickshare_link_two_way.description")}
        </div>
      </Popover>
    </Checkbox>
  </div>
</Boundary>
